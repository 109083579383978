let backendHost;

const hostname = window && window.location && window.location.hostname;

if (hostname === "localhost") {
    //backendHost = "https://snrcm-web-api.azurewebsites.net";
    //backendHost = "https://webapi.snchart.com/";
    //backendHost = "https://localhost:44362/";
    backendHost = "https://snotes-web-api.azurewebsites.net/";
}
  else {
    backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:50011';
}

export const API_ROOT = `${backendHost}`;
