import * as React from 'react';
import Auth from '../services/auth';
import { Redirect, Route } from 'react-router-dom'


const PrivateExpiredRoute = ({ component: Component, ...rest }) => {

    // Add your own authentication on the below line.
    const auth = new Auth();
    const isLoggedIn = auth.isAuthenticated();
    const isValidLogin = auth.isValidLogin();
    const isPasswordExpired = auth.isPasswordExpired();

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn && isValidLogin ? 
                            <Component {...props} />
                           
                         :
                     <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                
            }
        />
    )
}

export default PrivateExpiredRoute