import axios from 'axios';
import Auth from '../services/auth';
import moment from 'moment';
import EnumLookup from '../services/enumLookup';
import { API_ROOT } from './apiConfig';

export default class ReportDataAccess {


    getDistributionAddresses(account) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Reports/GetDistributionAddresses?account=${account}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error)
                });
        });

    }

    codingExport(codingExportRequest) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/v1/Coding/Export`,
                codingExportRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(atob(response.data));
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    async bulkStitchReports(stitchRequest) {
        const auth = new Auth();
        return await axios.post(`${API_ROOT}/api/Transcriptions/Stitch/Batch`,
            stitchRequest,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.getToken()}`
                }
            })
    }

    signReports(signRequest) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/v1/Transcriptions/SignDictation`,
                signRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(true);
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    archiveReports(archiveRequest) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/v1/Reports/Archive/`,
                archiveRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(true);
                })
                .catch(error => {
                    console.log(error)
                });
        });

}

    requestStat(account, id) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Reports/RequestStat?account=${account}&id=${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    resolve(true);
                })
                .catch(error => {
                    console.log(error)
                    reject(false);
                });
        });

    }

    getAuditDetails(account, reportId) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Reports/GetAuditDetails?account=${account}&jobId=${reportId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    validatePatientData(patientData){
        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/Transcription/PatientInfo/Validate`,
                patientData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${auth.getToken()}`
                        }
                    }).then(function (response) {

                        resolve(response.data);
                    })
                    .catch(error => {
                        console.log(error)
                    });
            });
    }

    distributeReport(distributionRequest) {
        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/v1/Reports/Distribute`,
                distributionRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error)
                });
        });

    }

    getDistributionDetails(account, reportId) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Reports/GetDistributions?account=${account}&jobId=${reportId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    clearComments (account, id){

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Transcriptions/ClearComments?account=${account}&id=${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(true);
                })
                .catch(error => {
                    console.log(error)
                });
        });

    }

    unsignReport(account, id) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Transcriptions/UnsignDocument?account=${account}&id=${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(true);
                })
                .catch(error => {
                    console.log(error)
                });
        });

    }


    getReportById(account, reportId) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Transcriptions/GetDocument?account=${account}&reportId=${reportId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    //TODO: hack for malformed html
                    let reportHtml = decodeURIComponent(escape(atob(response.data)));
                  
                  
                    resolve(reportHtml);
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    getReportForViewById(account, reportId, format) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Transcriptions/GetDocumentView?account=${account}&reportId=${reportId}&format=${format}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    let reportData = atob(response.data);//.replace('<div id="header"', '<div id="header" class="mceNonEditable"').replace('<div id="signature"', '<div id="signature" class="mceNonEditable"').replace("</html","").replace("<html>","").replace("<body>","").replace("</body>","");
                    resolve(reportData);
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    saveReportInfo(report) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/v1/Reports/`,
                report,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    resolve(response);
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

  
    downloadReports(downloadRequest) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/v1/Transcriptions/DownloadDocuments/`,
                downloadRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(atob(response.data));
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    mergeDocuments(mergeRequest) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/v1/Transcriptions/MergeDocuments/`,
                mergeRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    //resolve(atob(response.data));
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    getReportDetailById(id, account) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/reports/Get?id=${id}&account=${account}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    const reportDetail = Object.assign({}, response.data,
                        {
                            Procedure: response.data.Procedure || '',
                            DateOfBirth: response.data.DateOfBirth ? moment(response.data.DateOfBirth).format('MM/DD/YYYY') : '',
                            AdmitDate: moment(response.data.AdmitDate).isValid() ? moment(response.data.AdmitDate).format('MM/DD/YYYY') : '',
                            ESigTime: moment(response.data.ESigTime).format('MM/DD/YYYY') || '',
                            ReferringPhysician: response.data.ReferringPhysician || '',
                            Read: response.data.Read || false,
                            Downloaded: response.data.Downloaded || false,
                            Archived: response.data.Archived || false,
                            Printed: response.data.Printed || false,
                            ESigned: response.data.ESigned || false,
                            CaseId: response.data.CaseId || ''

                        });

                    resolve(reportDetail);


                })
                .catch(error => {
                    reject(error)
                });
        });
    }

    saveReport(documentRequest) {

        const auth = new Auth();
        
        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/v1/Transcriptions/SaveDocument`,
                documentRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(true);
                })
                .catch(error => {
                    //alert(error)
                    console.log(error)
                });
        });
    }

    getSummary(statusFilters, dateType, searchTerm) {

        const auth = new Auth();
        
        return new Promise((resolve, reject) => {

            var searchRequest = {
                "account": "zuludatabase",
                "command": "GetSummary",
                "parameters": { account: auth.getActiveAccount(), FromDate: auth.getFromDate(), ToDate: auth.getToDate() }
            };

            if (searchTerm && searchTerm != null && searchTerm.length > 0) {
                searchRequest.searchTerm = searchTerm;
                searchRequest.FromDate = null;
                searchRequest.ToDate = null;
            }

            if (statusFilters != null && statusFilters.length > 0) {
                searchRequest.StatusFilters = statusFilters;
            }


            axios.post(`${API_ROOT}/api/v1/qtype/Query`,
                searchRequest, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.getToken()}`
                }
            }).then(function (response) {

                    resolve(response.data);


                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    getAll(statusFilters, dateType, searchTerm) {

        const auth = new Auth();
        const enumLookup = new EnumLookup();
        const getStatusFlag = (report) => {
            let codingStatus = report.CodingStatus === "CodingQueryPending" ? "P" : ""
            codingStatus += report.NumberOfChildJobs > 0 ? " A" : ""

            return codingStatus.trim();

        }

        const getCodedDate = (report) => {
            const reportObject = report;
            if (report.CodingEmployee === "CenterAssigned") {
                return "No Coding";
            }
            return moment(report.DateCoded).isValid() ? moment(report.DateCoded).format('MM/DD/YYYY') : ''
        }
        return new Promise((resolve, reject) => {

            var searchRequest = {
                "Account": auth.getActiveAccount(),
                "DictatorId": auth.getDictatorId(),
                "FromDate": auth.getFromDate(),
                "ToDate": auth.getToDate(),
                "ReportDateType": dateType
            };

            if (searchTerm && searchTerm != null && searchTerm.length > 0){
                searchRequest.searchTerm = searchTerm;
                searchRequest.FromDate = null;
                searchRequest.ToDate = null;
            }
            
            if (statusFilters != null && statusFilters.length > 0) {
                searchRequest.StatusFilters = statusFilters;
            }


            axios.post(`${API_ROOT}/api/v1/reports/Search`,
                searchRequest, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    const reports = response.data.map((report, i) => {
                        const result = Object.assign({}, report,
                            {
                                id: report.Id,
                                DateOfService: moment(report.DateOfService).isValid() ? moment(report.DateOfService).format('MM/DD/YYYY') : '',
                                Complete: moment(report.Complete).isValid() ? moment(report.Complete).format('MM/DD/YYYY h:mm:ss A') : '',
                                RowStyle: { borderLeft: `20px solid ${enumLookup.getStatusColor(report.OverallStatus)}` },
                                CheckBoxStyle: { paddingLeft: 1 },
                                Physician: `${report.Physician} - ${report.DictatorId}`,
                                StatusFlag: getStatusFlag(report), 
                                DateCoded: getCodedDate(report),
                            });

                        return result;
                    });

                    resolve(reports);


                })
                .catch(error => {
                    console.log(error)
                });
        });
    }
    
    createAddendum = async (account, parentReportId, patientInfo) => {
        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/Transcriptions/AddendumJob/${account}/${parentReportId}`, patientInfo,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(result => {
                    resolve(result);
                }).catch(error => {
                    reject(error);
                })
        });
    }

    getParentId = async (account, reportId) => {
        const auth = new Auth();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/Transcriptions/ParentJob/${account}/${reportId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(result => {
                    resolve(result);
                }).catch(error => {
                    reject(error);
                })
        });
    }

    getChildJobs = async (account, reportId) => {
        const auth = new Auth();
        return await axios.get(`${API_ROOT}/api/Transcriptions/ChildJobs/${account}/${reportId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.getToken()}`
                }
            });
    }

    sitchParentReport =  async (account, reportId, parentId, reportString) => {
        const auth = new Auth();
        return await axios.post(`${API_ROOT}/api/Transcriptions/Stitch`, JSON.stringify({
                account: account,
                reportid: reportId,
                parentId: parentId,
                reportBase64String: reportString
            }),
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                })

    }
}