import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    }),
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    incompleteCard: {
        //minWidth: 240,
        height: 145,
        borderLeft: '6px solid red'
    },
    openCard: {
        //minWidth: 240,
        height: 145,
        borderLeft: '6px solid yellow'
    },
    completeQuestionsCard: {
        //minWidth: 240,
        height: 145,
        borderLeft: '6px solid blue'
    }
    , completeCard: {
        //minWidth: 240,
        height: 145,
        borderLeft: '6px solid green'
    },
    tatCard: {
        height: 250
    },
    tatCardReason: {
        height: 75
    },
    dateSelection: {
        marginLeft: theme.spacing(1)
    }
});

class Alert extends React.Component {

    render() {

        return (<div>
            <Dialog
                open={this.props.showAlert}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{this.props.alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.alertText}
                    </DialogContentText>
                    {this.props.children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onAlertOk} color="primary">
                        {this.props.alertOkText}
                        </Button>
                    <Button onClick={this.props.onAlertCancel} color="primary">
                        {this.props.alertCancelText}
                        </Button>
                </DialogActions>
            </Dialog>

        </div>);
    }
}


Alert.propTypes = {
    classes: PropTypes.object.isRequired,
    showAlert: PropTypes.bool.isRequired,
    alertText: PropTypes.string.isRequired,
    alertTitle: PropTypes.string.isRequired,
    onAlertOk: PropTypes.func,
    onAlertCancel: PropTypes.func,
    alertOkText: PropTypes.string,
    alertCancelText: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(Alert);