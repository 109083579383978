import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PubSub from 'pubsub-js';
import * as React from 'react';
import Auth from '../services/auth';
import CatalogsDataAccess from '../services/catalogsDataAccess';
import FacilityDataAccess from '../services/facilityDataAccess';
import WorklistDataAccess from '../services/workListDataAccess';

const workListDataAccess = new WorklistDataAccess();
const catalogDataAccess = new CatalogsDataAccess();
const facilityDataAccess = new FacilityDataAccess();

const auth = new Auth();

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    paperReports: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        height: '100%',
        maxHeight: 324,
        overflow: 'auto'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    inputLabel: {
        paddingLeft: 2,
        marginLeft: 18,
        marginTop: -10,
        width: '100%',
    },
    formControl: {
        width: '100%',
        paddingLeft: 5,
    },
    button: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    }
    ,
    textFieldSm: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '50%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    summary: {
        backgroundColor: theme.palette.secondary.main,
        color: "white"
    },
    AccordionIconColor: {
        color: "white"
    },
    readOnlyTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
        backgroundColor: "#F0F0F0"
    },
    sharePoint: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 70
    }
});

class IvlistDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            reports: [],
            open: false,
            documentData: [],
            teamStatusCatalog: [],
            facility: {
                SharePointUrl: "",
            },
            appointment: {
                Id: "",
                Client: "",
                FacilityID: ""
                , FacilityName: ""
                , IVStaff: ""
                , DOS: ""
                , CaseId: ""
                , PatientName: ""
                , PhysName: ""
                , Procedure: ""
                , Payer: ""
                , PayerName: ""
                , Verified: ""
                , BalanceDue: ""
                , Authorized: ""
                , refNum: ""
                , ImportDate: ""
            },
            openSnack: false,
            snackMessage: "",
            btnActionedState: true,
            actionedLabel: "Mark as Actioned"
        };

        let self = this;
        workListDataAccess.getIvDetailsById(this.props.match.params.id).then(function (reportData) {
            self.setState({ appointment: reportData });
        });

        facilityDataAccess.getFacilityById(this.props.match.params.account).then(function (rs) {
            self.setState({ facility: rs });
        });
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
    };

    componentWillMount() {
        this.token = PubSub.subscribe('AccountChanged', this.accountChanged.bind(this));
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }
    accountChanged(msg, data) {
        this.props.history.push("/");
    }

    handlePatientChange = name => event => {

        var appointment = this.state.appointment;
        var newValue = event.target.value;

        if (appointment[name] != newValue) {

            appointment[name] = newValue;

            this.setState({
                appointment
            });

            if (name == "TeamStatusId") {
                let self = this;
                workListDataAccess.updateTeamStatusId(appointment["CaseId"], appointment[name])
                    .then(function (rs) {
                        self.setState({ openSnack: true, snackMessage: "Team status has been updated" });
                    });
            }
        }
    };

    onCancel = () => {

        this.props.history.goBack();
    }


    

   

  

    handleChange = (panel) => (event, isExpanded) => {
        //console.log('handleCHange');
        var self = this;
        self.setState({ expanded: isExpanded ? panel : false });
    };

 
  

    handleSnackClose = () => {
        this.setState({ openSnack: false, snackMessage: "" });
    };

    render() {

        const { classes } = this.props;
        const { openSnack, snackMessage, expanded } = this.state;

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/worklist">
                                <Typography variant="h6">{this.state.facility.FacilityID} - {this.state.facility.FacilityName}</Typography>
                            </Link>
                            <Typography variant="h6">{this.state.appointment.CaseId}</Typography>
                        </Breadcrumbs>
                    </Grid></Grid>

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />
                <Grid container spacing={2}>
                   
           
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <fieldset>
                                <legend>IV Details</legend>
                                <Grid container spacing={1}>
                                    <Grid item xs={9}>
                                        <TextField
                                            id="pName"
                                            label="Patient Name"
                                            className={classes.readOnlyTextField}
                                            margin="normal"
                                            value={this.state.appointment.PatientName}
                         
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="IV Staff"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.IVStaff}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="Payer"
                                            label="Payer"
                                            className={classes.readOnlyTextField}
                                            margin="normal"
                                            value={this.state.appointment.Payer}
                                           
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="PayerName"
                                            label="Payer Name"
                                            className={classes.readOnlyTextField}
                                            margin="normal"
                                            value={this.state.appointment.PayerName}
                                            
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    
                                    
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Case ID"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.CaseId}
                                           
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Date of Service"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.DOS}
                                            
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Physician"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.PhysName}
                                         
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>

                                   
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Procedure"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.Procedure}
                                           
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Verified"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.Verified}
                                          
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Balance Due"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.BalanceDue}
                                           
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Authorized"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.Authorized}
                                        
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Ref Num"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.refNum}
                                            
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Import Date"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.ImportDate}
                                           
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Paper>
                    </Grid>
                


                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(IvlistDetails);