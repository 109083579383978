import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsightKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY || '02d18c02-1943-4d66-a4fa-7f12c8dd2701';// Dev value - 02d18c02-1943-4d66-a4fa-7f12c8dd2701

const AppInsights = new ApplicationInsights({
    config: {
        instrumentationKey: `${appInsightKey}`, 
    },
});

AppInsights.loadAppInsights();

export default AppInsights;