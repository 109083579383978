import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import ProgressIndicator from './ProgressIndicator';
import Auth from '../services/auth';
import { API_ROOT } from '../services/apiConfig';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const auth = new Auth();

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    })
});





class Print extends React.Component {

    constructor(props) {
        super(props);



        this.state = {
            loading: true,
            isIe: !!window.MSInputMethodContext && !!document.documentMode,
            isMobile: (/Mobi|Android/i.test(navigator.userAgent))
        }

    }


    componentDidMount() {

        let self = this;

        let printKey = this.props.match.params.printKey;
        let selected = printKey ? JSON.parse(sessionStorage.getItem(printKey)) : null;

        if (selected && selected != null) {
            
            let form = document.getElementById('print');
            
            for (let i = 0; i < selected.length; i++) {

                let input = document.createElement('input');
                input.type = 'hidden';
                input.value = selected[i];
                input.name = 'reportIds';
                form.appendChild(input);
            }
            if (self.state.isMobile) {
                form.target = "_self";
            }
            form.submit();

            if (self.state.isIe) {
                    var timer = setInterval(function () {
                        var iframe = document.getElementById('pdfFrame');
        
                        var iframeDoc = (iframe.contentWindow || iframe.contentDocument);
                        if (iframeDoc.document) iframeDoc = iframeDoc.document;
        
                        if (iframeDoc.readyState === 'complete' || iframeDoc.readyState === 'interactive') {
                            self.printLoaded();
                            clearInterval(timer);
                            return;
                        }
                    }, 500);
            }

        }

        //document.getElementById('pdfFrame').onload = function () {
       //     self.setState({ loading: false });
       // };

    }

    printLoaded = () => {

        this.setState({ loading: false });
    }

    render() {

        const { loading } = this.state; 
       
        return (<div style={{height:"100%", minHeight:"100vh"}}>
            <form method="post" id="print" action="/api/print" target="pdfFrame">
                <input type="hidden" name="apiRoot" value={API_ROOT} />
                <input type="hidden" name="account" value={auth.getActiveAccount()} />
                <input type="hidden" name="token" value={auth.getToken()} />
            </form>
            <Grid container spacing={3} justify="flex-end">
               
                <Grid item>
                    {!this.state.isIe && <Button variant="contained" color="secondary" onClick={(e) => {

                      
                            window.frames["pdfFrame"].focus();
                            window.frames["pdfFrame"].print();
                        
                    }}>Print</Button>}<br/><br/>
                    </Grid>
            </Grid>

            <iframe title="Print PDF" id="pdfFrame" name="pdfFrame" frameBorder="0" width="100%" height="100%" onLoad={this.printLoaded}></iframe>
            <ProgressIndicator showProgressIndicator={loading} progressTitle={'Generating reports for printing...'} progressText={'Processing request'} />
        </div>);
    }
}



export default withStyles(styles, { withTheme: true })(Print);
