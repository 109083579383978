import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import EnhancedTable from './EnhancedTable';
import UserDataAccess from '../services/userDataAccess';
import Auth from '../services/auth';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PubSub from 'pubsub-js';
import Snackbar from '@material-ui/core/Snackbar';
import { Link } from 'react-router-dom';

const dataAccess = new UserDataAccess();
const auth = new Auth();


const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(1),
    })
});

class UserMenuActions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            open: false,
        };
    }

    onOpenMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    onCloseMenu = (event) => {
        this.setState({ anchorEl: null });
    }


    render() {

        const menuId = `extended-options-${this.props.item.Id}`;
        const { item } = this.props;


        return <span><IconButton aria-label="More" variant="contained" color="primary"
            aria-owns={this.state.anchorEl ? menuId : null}
            aria-haspopup="true"
            onClick={this.onOpenMenu}>

            <MoreHorizIcon />
        </IconButton>
            <Menu id={menuId}
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.onCloseMenu}>

                {(auth.isInRole('Admin')) &&

                    <MenuItem key="confirmCode" disabled={(!(item.IdentityMode !== 1 && !item.IsVerified && item.VerificationCode != null))} onClick={(e) => this.props.sendIdentityConfirmation(item)} >Re-Send Confirmation Email</MenuItem>
                }

                {((auth.isInRole('FacilityAdmin'))) &&

                    <MenuItem key="passwordReset" onClick={(e) => this.props.sendPasswordReset(item)}>Password Reset / Unlock Account </MenuItem>
                }

                {((auth.isInRole('Admin'))) &&

                    <MenuItem key="resetConfirmation" disabled={(!item.IdentityMode === 1)} onClick={(e) => this.props.resetConfirmation(item)}>Reset Confirmation / Update Email </MenuItem>
                }

            </Menu></span>

    }
}

class TeamStatus extends Component {
    displayName = TeamStatus.name

    constructor(props) {
        super(props);




        this.state = {
            data: [],
            statusFilters: [],
            isLoading: false,
            openSnack: false,
            snackMessage: "",


        }
    }

    loadUserData() {

        var self = this;
        self.setState({ isLoading: true });
        dataAccess.getAllTeamStatus().then(function (reportData) {
            self.setState({ data: reportData, isLoading: false });

        });
    }

    accountChanged(msg, data) {
        this.loadUserData();
    }

    componentWillMount() {

        this.loadUserData();

        // This is where we subscribe this class to the 'GET FILES' subscription.
        // once a publish event for 'GET FILES' has been fired, FileList.subscriber() will be triggered.
        this.token = PubSub.subscribe('AccountChanged', this.accountChanged.bind(this));
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    onFilterChanged = (value, filterName) => {

        if (filterName === "toDate") {
            auth.setToDate(value);
            this.loadUserData();
        }

        if (filterName === "fromDate") {
            auth.setFromDate(value);
            this.loadUserData();
        }

        if (filterName === "status") {


            this.setState({
                statusFilters: value
            }, () => {
                this.loadUserData();
            });


        }



    }



    onAddUser = () => {

        this.props.history.push(`/userDetails/${auth.getActiveAccount()}/0`);
    }

    renderUserId(item) {

        /*if (auth.isFacilityAdminFor(item.Account) || auth.isInRole("Admin")) {*/
        return (<div><Link to={`/StatusDetails/${item.FacilityId}/${item.AccVst}`}>{item.FacilityId}</Link>
        </div>);
        //}
        // else{
        //    return (<span>{item.UserName}</span>);
        //}
    }

    sendPasswordReset = (item) => {
        var self = this;
        dataAccess.requestPasswordReset(item.Email).then(function (r) {
            self.setState({ openSnack: true, snackMessage: "Password Reset Email Sent" });
        });
    }

    resetConfirmation = (item) => {
        var self = this;
        dataAccess.resetConfirmation(item.Account, item.Id).then(function (r) {
            self.setState({ openSnack: true, snackMessage: "Confirmation / Email Reset" });
        });
    }

    sendIdentityConfirmation = (item) => {

        var self = this;
        dataAccess.SendIdentityEmailConfirmation(item.Account, item.Id).then(function (r) {
            self.setState({ openSnack: true, snackMessage: "Confirmation Email Sent" });
        });
    }



    renderIdentityConfirmed(item) {


        if ((auth.isInRole('Admin')) && (item.IdentityMode !== 1 && !item.IsVerified && item.VerificationCode != null)) {
            return (<div key={item.Id}>
                <div style={{ fontWeight: 'bold' }}>Pending (Code: {item.VerificationCode})</div>

            </div>);
        }
        else {
            if (item.IdentityMode !== 1 && !item.IsVerified) {
                if (item.VerificationCode == null) {
                    return (<div key={item.Id}><span>Not Requested</span></div>);
                }
                else {
                    return (<div key={item.Id}><span>Pending</span></div>);
                }
            }
            else {
                if (item.IdentityMode !== null) {
                    return (<div key={item.Id}><span>Confirmed</span></div>);
                }
                else {
                    return (<div key={item.Id}><span>Not Requested</span></div>);
                }
            }
        }
    }

    getCustomAction = () => {
        if (auth.isInRole('FacilityAdmin')) {
            return (<Button variant="contained" color="primary" disabled={!auth.isInRole('FacilityAdmin') || !auth.isInRole('AddEditUser')} onClick={this.onAddUser} > Add User</Button >);
        }
        else {
            return (<span></span>);
        }

    }

    render() {

        const { statusFilters, openSnack, snackMessage } = this.state;

        const columnData = [
            { id: 'FacilityId', numeric: false, disablePadding: false, label: 'Facility Id', link: false, filter: "textSearch", render: this.renderUserId.bind(this) },
            { id: 'Status', numeric: false, disablePadding: true, label: 'Status', link: false, filter: "textSearch" },
            { id: 'AccVst', numeric: false, disablePadding: true, label: 'AccVst', link: false, filter: "textSearch" },
            { id: 'CreatedBy', numeric: false, disablePadding: true, label: 'Created By', link: false, filter: "textSearch" }
        ];


        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />
                <EnhancedTable columnData={columnData}
                    customActions={this.getCustomAction()}
                    rowData={this.state.data} tableTitle={'Team Status'}
                    defaultStartDate={auth.getFromDate()}
                    isLoading={this.state.isLoading}
                    defaultEndDate={auth.getToDate()}
                    onGlobalFilterChanged={this.onFilterChanged}
                    includeActions={false}
                    allowSelections={false}
                    showActions={false}

                    defaultFilters={statusFilters} />
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(TeamStatus);
