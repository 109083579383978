import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import ProgressIndicator from './ProgressIndicator';
import Auth from '../services/auth';
import UserDataAccess from '../services/userDataAccess';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import 'url-search-params-polyfill';

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    })
});

class FedAuth extends React.Component {

    constructor(props) {
        super(props);
        this.Auth = new Auth();
        this.UserDataAccess = new UserDataAccess();

        this.state = {
            loading: true,
            showErrorOccurredModal:false
        };
    }

    componentDidMount = async () => {

        const params = new URLSearchParams(this.props.location.search.substring(1));
        let self = this;
        let exchangeToken = params.get('token');
        let ssoIdentity = params.get('ssoidentity');
        self.Auth.setSSOIdentity(params.get('email'));
        self.Auth.setSchema("AAD");
        let history = this.props.history;
        const storageSSOIdentity = self.Auth.getSSOIdentity();
        if (storageSSOIdentity !== "")
        {
            await self.UserDataAccess.getAccountByUsername(storageSSOIdentity).then(async (response) => {
                const account = response;
                await this.UserDataAccess.saveSSOIdentity(account, storageSSOIdentity, ssoIdentity).then( async (response) => {

                    await this.Auth.exchangeTokenLogin(exchangeToken);
                 
                    if (response === true) {
                        //console.log('ssoIdentity sucessfully updated');
                        self.setState({ loading: false });
                        history.replace("/");
                    }
                    else {
                        self.setState({ loading: false });
                        this.setState({ showErrorOccurredModal: true });
                       // console.log('Error updating ssoIdentity Column ');
                    }
                }).catch(error => {
                    self.setState({ loading: false });
                    this.setState({ showErrorOccurredModal: true });
                    console.log('Error updating ssoIdentity Column ');
                });
            }).catch(error => {
                self.setState({ loading: false });
                this.setState({ showErrorOccurredModal: true });
                console.log('Error updating ssoIdentity Column ');
            });
        } else {
            self.setState({ loading: false });
            this.setState({ showErrorOccurredModal: true });
            console.log('Error updating ssoIdentity Column ');
        }
    }

    handleClose = () => {
        this.setState({ showErrorOccurredModal: false });
        this.Auth.signOut(this.Auth.isAuthenticated());
    };

    render() {

        return (<div>
            <ProgressIndicator showProgressIndicator={this.state.loading} progressTitle={'Please wait...'} progressText={'Logging in...'} />
            {this.state.showErrorOccurredModal && <Dialog
                open={true}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Single Sign On"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to link your Enterprise Login to your SurgicalNotes Login.  
                        Please continue to use your SurgicalNotes Login and contact support at <a href="tel:8004595616">800-459-5616</a>
                        to configure your Enterprise Login
                         </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        OK
                      </Button>
                </DialogActions>
            </Dialog>}
        </div>);
    }
}



export default withStyles(styles, { withTheme: true })(FedAuth);
