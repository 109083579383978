import * as React from 'react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import ScheduleDataAccess from '../services/scheduleDataAccess';
import EnumLookup from '../services/enumLookup';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import PubSub from 'pubsub-js';
import ScheduleFileUpload from './ScheduleFileUpload';
import Auth from '../services/auth';
const dataAccess = new ScheduleDataAccess();
const enumLookup = new EnumLookup();
const auth = new Auth();

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    paperReports: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        height: '100%',
        maxHeight: 324,
        overflow: 'auto'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    }
    ,
    textFieldSm: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '50%',
    }
});

class ScheduleDetails extends React.Component {



    constructor(props) {
        super(props);

        this.state = {
            reports: [],
            open: false,
            appointment: {
                Id: 0,
                Account: "",
                AppointmentId: "",
                DateOfBirth: "",
                DateOfService: "",
                DictatorId: "",
                OperatingRoom: "",
                PatientFirstName: "",
                PatientId: "",
                CaseId: "",
                PatientLastName: "",
                PrimaryPhysican: "",
                PrimaryPhysicanNpi: "",
                Procedure: "",
                ReferringPhysician: "",
                ReferringPhysicianNpi: "",
                Reports: []
            },
            openSnack: false,
            snackMessage: "",
            fileObject: {
                fileName: "",
                base64: "",
                fileExt: ""
            }
        };

        let self = this;
        dataAccess.getScheduleById(this.props.match.params.account, this.props.match.params.patientListId).then(
            function (reportData) {

                self.setState({ appointment: reportData });
            });
    }


    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
    };


    componentWillMount() {
        this.token = PubSub.subscribe('AccountChanged', this.accountChanged.bind(this));
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }
    accountChanged(msg, data) {
        this.props.history.push("/");
    }

    handlePatientChange = name => event => {

        var appointment = this.state.appointment;
        appointment[name] = event.target.value;

        this.setState({
            appointment
        });
    };


    onCancel = () => {

        this.props.history.goBack();
    }


    onSaveAppointmentInfo = () => {

        var patient = this.state.appointment;
        var self = this;

        dataAccess.saveAppointmentInfo(patient).then(function (rs) {
            self.setState({ openSnack: true, snackMessage: 'Appointment saved' });
        });

    }

    fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }

    onScheduleFileChange = event => {
        var self = this;

        if (event.target.value.length && self.validateFileType(event.target.files[0].type)) {

            var fileObj = {
                fileName: "",
                base64: "",
                fileExt: ""
            };


            fileObj.fileName = new String(event.target.files[0].name);
            let lastDot = fileObj.fileName.lastIndexOf('.');
            fileObj.fileExt = fileObj.fileName.substring(lastDot + 1);


            this.fileToBase64(event.target.files[0], (err, result) => {
                if (result) {
                    fileObj.base64 = new String(result);
                    self.setState({ fileObject: fileObj });
                }
            })
        }
        else {
            event.target.value = '';
        }
    }

    validateFileType(type) {
        var result = false;
        switch (type) {
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'image/tiff':
            case 'application/pdf':
            case 'application/rtf':
                result = true;
                break;
            default:
                result = false;
        }
        return result;
    }

    handleUpload = () => {
        var self = this;
        self.FileUpload().then(function (rp) {
            self.setState({ openSnack: true, snackMessage: 'Appointment saved' });
            //reload report data
            dataAccess.getScheduleById(self.props.match.params.account, self.props.match.params.patientListId).then(
                function (reportData) {
                    self.setState({ appointment: reportData });
                });
        })
            .catch(error => {
                console.log(error)
            });
    }

    FileUpload = () => {
        var self = this;

        let base64 = this.state.fileObject.base64.split(',', 2)[1];

        var ScheduleItem = this.state.appointment;
        let fileFormat = 3;

        switch (this.state.fileObject.fileExt.toLowerCase()) {
            case 'tiff':
            case 'tif':
                fileFormat = 1;
                break;
            case 'pdf':
                fileFormat = 0;
                break;
            case 'rtf':
                fileFormat = 4;
                break;
            default:
                fileFormat = 3;
        }

        var NormalRequest = {
            Name: this.state.fileObject.fileName,
            Account: auth.getActiveAccount(),
            DocumentAsBase64String: base64,
            FileFormat: fileFormat
        }

        var data = { NormalRequest, ScheduleItem }

        return new Promise((resolve, reject) => {
            dataAccess.saveDocumentFromSchedule(data).then(function (rs) {
                self.setState({
                    fileObject: {
                        fileName: "",
                        base64: "",
                        fileExt: ""
                    }
                });
                resolve(rs);
            });
        });
    }

    render() {

        const { classes } = this.props;
        const { openSnack, snackMessage } = this.state;
        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="fName"
                                                label="First"
                                                className={classes.textField}
                                                margin="normal"
                                                value={this.state.appointment.PatientFirstName}
                                                onChange={this.handlePatientChange('PatientFirstName')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="lName"
                                                label="Last"
                                                className={classes.textField}
                                                margin="normal"
                                                value={this.state.appointment.PatientLastName}
                                                onChange={this.handlePatientChange('PatientLastName')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Patient ID"
                                                className={classes.textField}
                                                value={this.state.appointment.PatientId}
                                                onChange={this.handlePatientChange('PatientId')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Case ID"
                                                className={classes.textField}
                                                value={this.state.appointment.CaseId}
                                                onChange={this.handlePatientChange('CaseId')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Date of Birth"
                                                className={classes.textField}
                                                value={this.state.appointment.DateOfBirth}
                                                disabled
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                type="datetime-local"
                                                label="Date of Service"
                                                className={classes.textField}
                                                value={this.state.appointment.DateOfService}
                                                onChange={this.handlePatientChange('DateOfService')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Procedure"
                                                className={classes.textField}
                                                value={this.state.appointment.Procedure}
                                                disabled
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Referring Physician"
                                                className={classes.textField}
                                                value={this.state.appointment.ReferringPhysician}
                                                disabled
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={3} justify='flex-end'>
                                        <Grid item>
                                            <Button variant="contained" onClick={this.onCancel} color="primary">Cancel</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" onClick={this.onSaveAppointmentInfo} color="primary">Save</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.paperReports}>
                            <Typography variant="subtitle1" gutterBottom>
                                Transcription Status
                            </Typography>
                            <List>
                                {(this.state.appointment.Reports && this.state.appointment.Reports.length > 0) && this.state.appointment.Reports.map(report => {
                                    return (<ListItem button onClick={(e) => this.props.history.push(`/reportDetails/${this.props.match.params.account}/${report.Id}`)}>
                                        <Avatar style={{ backgroundColor: enumLookup.getStatusColor(report.Status) }}>
                                            &nbsp;
                                        </Avatar>
                                        <ListItemText primary={`${report.Created} ${report.DictationPriority}`} secondary={`(${report.Status})`} />
                                    </ListItem>)
                                }
                                )
                                }

                                {(this.state.appointment.Reports && this.state.appointment.Reports.length === 0) &&
                                    <ListItem>

                                        <ListItemText primary="No Reports Found" />
                                    </ListItem>}


                            </List>
                            <ScheduleFileUpload
                                onChangeFile={this.onScheduleFileChange}
                                selectedFileName={this.state.fileObject.fileName.toString()}
                                handleSubmit={this.handleUpload}
                            />
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(ScheduleDetails);