import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Auth from '../services/auth';
import { AgGridReact } from 'ag-grid-react';
import ProgressIndicator from './ProgressIndicator';
import Snackbar from '@material-ui/core/Snackbar';
import EOMSystemDataAccess from '../services/eomSystemDataAccess';
import WorkFlowManagementDataAccess from '../services/workFlowManagementDataAccess';
import RPARenderButton from './renders/RPARenderButton';
import PubSub from 'pubsub-js';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const eomSystemDataAccess = new EOMSystemDataAccess();
const workFlowManagementDataAccess = new WorkFlowManagementDataAccess();

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(1),
    }),
    inputLabel: {
        paddingLeft: 2,
        marginLeft: 18,
        marginTop: -10,
        width: '100%',
    },
    formControl: {
        width: 150,
        paddingLeft: 5,
    },
});

class workFlowManagement extends Component {

    displayName = workFlowManagement.name

    constructor(props) {
        super(props);

        this.state = {
            systemNames: [],
            systemName: "",
            data: [],
            isLoading: false,
            snackMessage: "",
            openSnack: false,
        };

        let self = this;

        self.getFlowManagementData();

        eomSystemDataAccess.getEOMSystems().then(function (rs) {
            if (rs) {            
                const systemNames = rs.map(system => ({ System: system.SystemName }));
                self.setState({ systemNames: systemNames, systemName: "All" });
            }
        });

        self.gridRef = React.createRef();
    }

    componentWillMount() {
        this.token = PubSub.subscribe('workFlowUpdated', this.getFlowManagementDataRefresh.bind(this));
    }

    getFlowManagementData(){
        let self = this;

        workFlowManagementDataAccess.getAllWorkFlows().then(function (rs) {
            self.setState({ data: rs});
        });
    }

    startStatusProcess(facilityId, workflowId) {
        workFlowManagementDataAccess.startStatusProcess(facilityId, workflowId);
    }

    getFlowManagementDataRefresh(msg, data){
        let self = this;

        workFlowManagementDataAccess.getAllWorkFlows().then(function (rs) {
            self.setState({ data: rs});
        });

        if(data[0].status == 200)
        {
            self.setState({ openSnack: true, snackMessage: 'success - scheduleWorkFlow initiated' });
            this.startStatusProcess(data[1], data[2]);
        }
        else
        {
            self.setState({ openSnack: true, snackMessage: 'There was an error when calling the API' });
        }
        
    }

    updateWorkFlow(facilityId) {
        let self = this;

        workFlowManagementDataAccess.updateWorkFlow(facilityId).then(function (rs) {
            self.getFlowManagementData();
            self.setState({ openSnack: true, snackMessage: "Operation completed" });
        });
    }

    handleSnackClose = () => {
        this.setState({ openSnack: false, snackMessage: "" });
    };

    loadFacilityPerSystem() {
        var self = this;
        self.setState({ isLoading: true });
        workFlowManagementDataAccess.getWorkFlow(this.state.systemName).then(function (reportData) {
            //console.log('workflow data:');
            //console.log(reportData);
            self.setState({ data: reportData, isLoading: false });
        });
    }

    onSystemChange(e){
        this.setState({
            systemName: e.target.innerText
        }, () => {
            sessionStorage.setItem('systemNameBtn', this.state.systemName);
                this.loadFacilityPerSystem();
        });
        //console.log(e.target);
    }

    render() {

        const { classes } = this.props;

        const { openSnack, snackMessage } = this.state;

        const columnDefs = [
            { field: 'FacilityName', headerName: 'Facility Name', sortable: true, filter: false, resizable: true},
            {
                field: 'RunDate', headerName: 'Last Run Date', sortable: true, filter: false, resizable: true,
                valueFormatter: (params) => {
                    if (params.value && params.value.trim() !== '') {
                        const utcDate = new Date(params.value);
                        return utcDate.toLocaleString();
                    } else {
                        return '';
                    }
                }
            },
            { field: 'WorkflowStatus', headerName: 'Status', sortable: true, filter: false, resizable: true },
            { field: 'ExecutedBy', headerName: 'Executed By', sortable: true, filter: false, resizable: true},
            { field: 'Actions', headerName: 'Actions', sortable: true, filter: false, resizable: true, cellRenderer: RPARenderButton},
        ];
        

        const defaultColDef = { resizable: true};

        return (
            <div>
                {/*Progress indicator component*/}

                <ProgressIndicator showProgressIndicator={this.state.isLoading} progressTitle={'Please wait...'} progressText={'Loading data...'} />

                {/*Grid component container*/}

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />

                <div className="ag-theme-alpine" style={{ height: '75vh', width: '100%' }}>
                    <h2>WorkFlow Management</h2>
                    <div style={{ height: 10 }} />

                    <p style={{ fontStyle: 'italic', }}>*RPA can only be used on the 1st day of the month forward.  If your center closes before then (ex. 6/28), please upload the PAS reports to the EOM Wizard on or after the 1st of the month (7/1).</p>
                    <p style={{ fontStyle: 'italic', }}>*"Last Run Date" shows the date and time in your local time zone, of when the EOM process was last completed.</p>
                    {/*The grid*/}
                    <div className="ag-theme-alpine" style={{ height: '75vh', width: '100%' }}>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button variant="contained" color="primary" onClick={e => this.onSystemChange(e, "all")}>All</Button >
                            {this.state.systemNames.map((option) => (
                                <Button variant="contained" color="primary" onClick={e => this.onSystemChange(e, option.System)}>{option.System}</Button >
                            ))}
                        </ButtonGroup>
                        <div style={{ textAlign: 'center' }}><h2 style={{ alignItems: 'center' }}>{this.state.systemName}</h2></div>
                        <AgGridReact
                            ref={this.gridRef}
                            rowData={this.state.data}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={false}
                            containerStyle={{ height: '90%' }}
                            onGridReady={
                                this.loadWorkFlowData
                            }                            
                        />
                    </div>
                </div>
                <div style={{ height: 80 }} />
            </div>
            );
    }
}

export default withStyles(styles, { withTheme: true })(workFlowManagement);