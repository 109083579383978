import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import WorklistDataAccess from '../services/workListDataAccess';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import PubSub from 'pubsub-js';
import Auth from '../services/auth';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TranscriptionDetails from './TranscriptionDetails';
import CodingDetails from './CodingDetails';
import DocumentList from './DocumentList';
import { InputLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import CatalogsDataAccess from '../services/catalogsDataAccess';
import FacilityDataAccess from '../services/facilityDataAccess';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const workListDataAccess = new WorklistDataAccess();
const catalogDataAccess = new CatalogsDataAccess();
const facilityDataAccess = new FacilityDataAccess();

const auth = new Auth();

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    paperReports: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        height: '100%',
        maxHeight: 324,
        overflow: 'auto'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    inputLabel: {
        paddingLeft: 2,
        marginLeft: 18,
        marginTop: -10,
        width: '100%',
    },
    formControl: {
        width: '100%',
        paddingLeft: 5,
    },
    button: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    }
    ,
    textFieldSm: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '50%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    summary: {
        backgroundColor: theme.palette.secondary.main,
        color: "white"
    },
    AccordionIconColor: {
        color: "white"
    },
    readOnlyTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
        backgroundColor: "#F0F0F0"
    },
    sharePoint: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 70
    }
});

class WorklistDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            reports: [],
            open: false,
            documentData: [],
            teamStatusCatalog: [],
            facility: {
                SharePointUrl: "",
            },
            appointment: {
                Actioned: false,
                Age: "",
                Balance: "",
                CaseId: "",
                Charges: "",
                Collector: "",
                ContractFee: "",
                CreateBy: "",
                DOS: "",
                DateBilled: "",
                DateOfBirth: "",
                FinClass: "",
                Followup: "",
                GroupNumber: "",
                LstPmtAmount: "",
                LstPmtDate: "",
                LstUpdate: "",
                NoteDate: "",
                Notes: "",
                NxtWkDate: "",
                PatientName: "",
                Payer: "",
                Payments: "",
                PolicyNumber: "",
                SPStatus: "",
                StatusCode: "",
                StatusDesc: "",
                StmtsSent: "",
                Systm: "",
                TargetDate: "",
                Tickler: "",
                TeamStatusId: "",
                Reports: [],
                Classification: ""
            },
            openSnack: false,
            snackMessage: "",
            btnActionedState: true,
            actionedLabel: "Mark as Actioned"
        };

        let self = this;
        workListDataAccess.getDetailsById(this.props.match.params.id).then(function (reportData) {
            self.setState({ appointment: reportData });
        });

        catalogDataAccess.getTeamStatusCatalog().then(function (rs) {
            self.setState({ teamStatusCatalog: rs });
        });

        facilityDataAccess.getFacilityById(this.props.match.params.account).then(function (rs) {
            self.setState({ facility: rs });
        });
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
    };

    componentWillMount() {
        this.token = PubSub.subscribe('AccountChanged', this.accountChanged.bind(this));
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }
    accountChanged(msg, data) {
        this.props.history.push("/");
    }

    handlePatientChange = name => event => {

        var appointment = this.state.appointment;
        var newValue = event.target.value;

        if (appointment[name] != newValue) {

            appointment[name] = newValue;

            this.setState({
                appointment
            });

            if (name == "TeamStatusId") {
                let self = this;
                workListDataAccess.updateTeamStatusId(appointment["CaseId"], appointment[name])
                    .then(function (rs) {
                        self.setState({ openSnack: true, snackMessage: "Team status has been updated" });
                    });
            }
        }
    };

    onCancel = () => {

        this.props.history.goBack();
    }


    onSaveAppointmentInfo = () => {

        var patient = this.state.appointment;
        var self = this;

        workListDataAccess.saveAppointmentInfo(patient).then(function (rs) {
            self.setState({ openSnack: true, snackMessage: 'Appointment saved' });
        });

    }

    fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }

    onScheduleFileChange = event => {
        var self = this;

        if (event.target.value.length && self.validateFileType(event.target.files[0].type)) {

            var fileObj = {
                fileName: "",
                base64: "",
                fileExt: ""
            };


            fileObj.fileName = new String(event.target.files[0].name);
            let lastDot = fileObj.fileName.lastIndexOf('.');
            fileObj.fileExt = fileObj.fileName.substring(lastDot + 1);


            this.fileToBase64(event.target.files[0], (err, result) => {
                if (result) {
                    fileObj.base64 = new String(result);
                    self.setState({ fileObject: fileObj });
                }
            })
        }
        else {
            event.target.value = '';
        }
    }

    validateFileType(type) {
        var result = false;
        switch (type) {
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'image/tiff':
            case 'application/pdf':
            case 'application/rtf':
                result = true;
                break;
            default:
                result = false;
        }
        return result;
    }

    handleChange = (panel) => (event, isExpanded) => {
        //console.log('handleCHange');
        var self = this;
        self.setState({ expanded: isExpanded ? panel : false });
    };

    handleUpload = () => {
        var self = this;
        self.FileUpload().then(function (rp) {
            self.setState({ openSnack: true, snackMessage: 'Appointment saved' });
            //reload report data
            workListDataAccess.getScheduleById(self.props.match.params.account, self.props.match.params.patientListId).then(
                function (reportData) {
                    self.setState({ appointment: reportData });
                });
        })
            .catch(error => {
                console.log(error)
            });
    }

    FileUpload = () => {
        var self = this;

        let base64 = this.state.fileObject.base64.split(',', 2)[1];

        var ScheduleItem = this.state.appointment;
        let fileFormat = 3;

        switch (this.state.fileObject.fileExt.toLowerCase()) {
            case 'tiff':
            case 'tif':
                fileFormat = 1;
                break;
            case 'pdf':
                fileFormat = 0;
                break;
            case 'rtf':
                fileFormat = 4;
                break;
            default:
                fileFormat = 3;
        }

        var NormalRequest = {
            Name: this.state.fileObject.fileName,
            Account: auth.getActiveAccount(),
            DocumentAsBase64String: base64,
            FileFormat: fileFormat
        }

        var data = { NormalRequest, ScheduleItem }

        return new Promise((resolve, reject) => {
            workListDataAccess.saveDocumentFromSchedule(data).then(function (rs) {
                self.setState({
                    fileObject: {
                        fileName: "",
                        base64: "",
                        fileExt: ""
                    }
                });
                resolve(rs);
            });
        });
    }

    handleSnackClose = () => {
        this.setState({ openSnack: false, snackMessage: "" });
    };

    markAsActioned = () => {
        let self = this;
        var appointment = self.state.appointment;

        workListDataAccess.markAsActioned(`${self.state.appointment.CaseId}`)
            .then(function (rs) {
                appointment["Actioned"] = true;

                self.setState({ openSnack: true, snackMessage: "Case marked as actioned" });
                self.setState({ appointment });
            });
    };

    markAsNotActioned = () => {
        let self = this;
        var appointment = self.state.appointment;

        workListDataAccess.markAsNotActioned(`${self.state.appointment.CaseId}`)
            .then(function (rs) {
                appointment["Actioned"] = false;

                self.setState({ openSnack: true, snackMessage: "Case marked as not actioned" });
                self.setState({ appointment });
            });
    };

    OnSaveMarkAsActioned = () => {

        let self = this;
        var appointment = self.state.appointment;

        if (appointment.Actioned) {
            workListDataAccess.markAsNotActioned(`${self.state.appointment.CaseId}`)
                .then(function (rs) {
                    appointment["Actioned"] = false;

                    self.setState({ openSnack: true, snackMessage: "Case marked as not actioned" });
                    self.setState({ appointment });
                    self.setState({ actionedLabel: "Mark as Actioned" })
                });
        } else {
            workListDataAccess.markAsActioned(`${self.state.appointment.CaseId}`)
                .then(function (rs) {
                    appointment["Actioned"] = true;

                    self.setState({ openSnack: true, snackMessage: "Case marked as actioned" });
                    self.setState({ appointment });
                    self.setState({ actionedLabel: "Mark as not Actioned" })
                });
        }


    }

    render() {

        const { classes } = this.props;
        const { openSnack, snackMessage, expanded } = this.state;

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/worklist">
                                <Typography variant="h6">{this.state.facility.FacilityID} - {this.state.facility.FacilityName}</Typography>
                            </Link>
                            <Typography variant="h6">{this.state.appointment.CaseId}</Typography>
                        </Breadcrumbs>
                    </Grid></Grid>

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                    <TextField
                                        id="Collector"
                                        label="Collector"
                                        className={classes.readOnlyTextField}
                                        margin="normal"
                                        value={this.state.appointment.Collector}
                                        onChange={this.handlePatientChange('Collector')}
                                        variant="outlined"
                                        margin="dense"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        id="Manager"
                                        label="Manager"
                                        className={classes.readOnlyTextField}
                                        margin="normal"
                                        value={this.state.appointment.Manager}
                                        onChange={this.handlePatientChange('Manager')}
                                        variant="outlined"
                                        margin="dense"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        id="Status"
                                        label="Status"
                                        className={classes.readOnlyTextField}
                                        margin="normal"
                                        value={this.state.appointment.StatusDesc}
                                        onChange={this.handlePatientChange('StatusDesc')}
                                        variant="outlined"
                                        margin="dense"
                                        inputProps={
                                            { readOnly: true }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        id="Classification"
                                        label="Classification"
                                        className={classes.readOnlyTextField}
                                        margin="normal"
                                        value={this.state.appointment.Classification}
                                        onChange={this.handlePatientChange('Classification')}
                                        variant="outlined"
                                        margin="dense"
                                        inputProps={
                                            { readOnly: true }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2} style={{ display: 'none' }}>
                                    <FormControl className={classes.formControl} margin="dense">
                                        <InputLabel
                                            id="TeamStatusId"
                                            className={classes.inputLabel}>Team Status</InputLabel >
                                        <Select
                                            value={this.state.appointment.TeamStatusId ? this.state.appointment.TeamStatusId : ""}
                                            id="TeamStatusId"
                                            labelId="TeamStatusId"
                                            variant="outlined"
                                            margin="dense"
                                            label="Team Status"
                                            onChange={this.handlePatientChange('TeamStatusId')}
                                            fullWidth
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                        >
                                            {this.state.teamStatusCatalog.map((option) => (
                                                <MenuItem key={option.TeamStatusId} value={option.TeamStatusId}>{option.TeamStatus}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={2}>
                                    <div className="ag-theme-alpine">
                                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                                            <Button variant="contained" color="primary" onClick={this.OnSaveMarkAsActioned}>{this.state.actionedLabel}</Button >
                                            {/* <Button variant="contained" color="secondary" onClick={this.markAsNotActioned}>Mark as not Actioned</Button >*/}
                                        </ButtonGroup>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <fieldset>
                                <legend>Shortcuts</legend>
                                <Link href={this.state.facility.SharePointUrl} target="_blank" >
                                    <Grid container spacing={1}>
                                        <Grid item xs={10}>
                                            <Grid container spacing={1}>
                                                <Grid className={classes.sharePoint}>
                                                    SharePoint
                                                </Grid>
                                                <Grid>
                                                    <OpenInNewIcon />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Link>
                            </fieldset>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <fieldset>
                                <legend>Patient Demographics</legend>
                                <Grid container spacing={1}>
                                    <Grid item xs={9}>
                                        <TextField
                                            id="pName"
                                            label="Patient Name"
                                            className={classes.readOnlyTextField}
                                            margin="normal"
                                            value={this.state.appointment.PatientName}
                                            onChange={this.handlePatientChange('PatientName')}
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Date of Birth"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.DateOfBirth}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            id="Payer"
                                            label="Payer"
                                            className={classes.readOnlyTextField}
                                            margin="normal"
                                            value={this.state.appointment.Payer}
                                            onChange={this.handlePatientChange('Payer')}
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="FinClass"
                                            label="Financial Class"
                                            className={classes.readOnlyTextField}
                                            margin="normal"
                                            value={this.state.appointment.FinClass}
                                            onChange={this.handlePatientChange('FinClass')}
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Policy Number"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.PolicyNumber}
                                            onChange={this.handlePatientChange('PolicyNumber')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Group Number"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.GroupNumber ? this.state.appointment.GroupNumber : ""}
                                            onChange={this.handlePatientChange('GroupNumber')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Case ID"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.CaseId}
                                            onChange={this.handlePatientChange('CaseId')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Date of Service"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.DOS}
                                            onChange={this.handlePatientChange('DateOfService')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Tickler"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.Tickler}
                                            onChange={this.handlePatientChange('Tickler')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="System"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.Systm}
                                            onChange={this.handlePatientChange('Systm')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <fieldset>
                                <legend>Notes Details</legend>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Created By"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.CreateBy}
                                            onChange={this.handlePatientChange('CreateBy')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Next Working Date"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.NxtWkDate}
                                            onChange={this.handlePatientChange('NxtWkDate')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Last Update"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.LstUpdate}
                                            onChange={this.handlePatientChange('LstUpdate')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Target Date"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.TargetDate}
                                            onChange={this.handlePatientChange('TargetDate')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Age"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.Age}
                                            onChange={this.handlePatientChange('Age')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="SP Status"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.SPStatus}
                                            onChange={this.handlePatientChange('SPStatus')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Note Date"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.NoteDate}
                                            onChange={this.handlePatientChange('NoteDate')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            label="Notes"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.Notes}
                                            onChange={this.handlePatientChange('Notes')}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <fieldset>
                                <legend>Transaction Information</legend>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <CurrencyTextField
                                            label="Charges"
                                            variant="standard"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.Charges}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            currencySymbol="$"
                                            outputFormat="string"
                                            decimalCharacter="."
                                            digitGroupSeparator=","
                                            textAlign="left"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CurrencyTextField
                                            label="Balance"
                                            variant="standard"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.Balance}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            currencySymbol="$"
                                            outputFormat="string"
                                            decimalCharacter="."
                                            digitGroupSeparator=","
                                            textAlign="left"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CurrencyTextField
                                            label="Payments"
                                            variant="standard"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.Payments}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            currencySymbol="$"
                                            outputFormat="string"
                                            decimalCharacter="."
                                            digitGroupSeparator=","
                                            textAlign="left"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CurrencyTextField
                                            label="Contract Fee"
                                            variant="standard"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.ContractFee}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            currencySymbol="$"
                                            outputFormat="string"
                                            decimalCharacter="."
                                            digitGroupSeparator=","
                                            textAlign="left"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="DateBilled"
                                            label="Date Billed"
                                            className={classes.readOnlyTextField}
                                            margin="normal"
                                            value={this.state.appointment.DateBilled}
                                            onChange={this.handlePatientChange('DateBilled')}
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CurrencyTextField
                                            label="Last Payment Amount"
                                            variant="standard"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.LstPmtAmount}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            currencySymbol="$"
                                            outputFormat="string"
                                            decimalCharacter="."
                                            digitGroupSeparator=","
                                            textAlign="left"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Last Payment Date"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.LstPmtDate}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            multiline
                                            label="Stmts Sent"
                                            className={classes.readOnlyTextField}
                                            value={this.state.appointment.StmtsSent ? this.state.appointment.StmtsSent : ""}
                                            margin="normal"
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={
                                                { readOnly: true }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Accordion expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className={classes.AccordionIconColor} />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className={classes.summary}
                                        >
                                            <Typography className={classes.heading}>Documents</Typography>
                                            <Typography className={classes.secondaryHeading}></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <DocumentList
                                                caseId={this.props.match.params.caseid}
                                                account={this.props.match.params.account}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className={classes.AccordionIconColor} />}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                            className={classes.summary}
                                        >
                                            <Typography className={classes.heading}>Transcription</Typography>
                                            <Typography className={classes.secondaryHeading}></Typography>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TranscriptionDetails account={this.props.match.params.account}
                                                caseId={this.props.match.params.caseid} />
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel3'} onChange={this.handleChange('panel3')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className={classes.AccordionIconColor} />}
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                            className={classes.summary}
                                        >
                                            <Typography className={classes.heading}>Coding</Typography>
                                            <Typography className={classes.secondaryHeading}></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <CodingDetails account={this.props.match.params.account}
                                                caseId={this.props.match.params.caseid} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(WorklistDetails);