import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import UserDataAccess from '../services/userDataAccess';
import Auth from '../services/auth';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';



const dataAccess = new UserDataAccess();
const auth = new Auth();

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

const financialClasses = [
    { title: 'SELF-PAY', year: 1994 },
    { title: 'COMMERCIAL', year: 1972 },
    { title: 'OUT OF NETWORK', year: 1974 },
    { title: 'WORK COMP', year: 2008 },
    { title: 'PI/AUTO', year: 1957 },
    { title: "GOVERNMENT", year: 1993 },
    { title: 'PI FUNDING', year: 1994 },
    
];

export default function UserDetails(props) {
    const classes = useStyles();
    //const auth = new Auth();
    const [details, setDetails] = React.useState({ FirstName: "", LastName: "", Email: "", Organization: ""});
    //const userHasUpdatePermission = auth.isInRole('SNChartGlobalAdmin') || auth.isInRole('SNChartSuperUser');


    useEffect(() => {
        async function fetchData() {
            dataAccess.getUserDetails(props.match.params.userId).then(function (detailsData) {
                setDetails(detailsData[0]);
            });
        }
        fetchData();
    }, []);



    return (
        <Grid container className={classes.root} spacing={2}>

            <Grid item xs={12}>
                <Paper className={classes.root}>
                    
                            <Typography className={classes.title} color="textPrimary" gutterBottom>
                                User Information
                            </Typography>

                            <Typography className={classes.title} color="textSecondary">
                                Fill in the First and Last name, Email, and select the employee type.

                            </Typography>
                      
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="jobId"
                                label="First Name"
                                className={classes.textField}
                                margin="normal"
                                name="confidenceScore"
                                variant="outlined"
                                value={details.FirstName}
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="procedureDescription"
                                label="LastName"
                                className={classes.textField}
                                margin="normal"
                                name="confidenceScore"
                                variant="outlined"
                                value={details.LastName}
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="workType"
                                label="Email"
                                className={classes.textField}
                                margin="normal"
                                name="confidenceScore"
                                variant="outlined"
                                value={details.UserId}
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="confidenceThreshold"
                                label="Organization"
                                className={classes.textField}
                                margin="normal"
                                name="confidenceScore"
                                variant="outlined"
                                value={details.Organization}
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title} color="textPrimary" gutterBottom>
                                Financial Classes
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={financialClasses}
                                getOptionLabel={(option) => option.title}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Financial Class"
                                        placeholder="Add a Financial Class"
                                    />
                                )}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <Grid container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        className={classes.textField}
                                        variant="contained" color="primary"
                                        onClick={() => { props.history.push('/users'); }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Button
                                        className={classes.textField}
                                        variant="contained"
                                        color="primary"
                                        disabled={true}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                               
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

        </Grid>
    );
}