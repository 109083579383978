import axios from 'axios';
import Auth from './auth';
import { API_ROOT } from './apiConfig';

export default class WorkFlowManagementDataAccess {

    getWorkFlow(systemName) {

        const auth = new Auth();

        const date = new Date();

        return new Promise((resolve, reject) => {

            var offset = date.getTimezoneOffset() / 60;

            let reqBody = {
                account: "zuludatabase", command: "GetWorkFlowFiltered", parameters: { system: systemName, utcoffset: offset }
            };         

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getAllWorkFlows() {

        const auth = new Auth();

        const date = new Date();

        return new Promise((resolve, reject) => {

            var offset = date.getTimezoneOffset() / 60;

            let reqBody = {
                account: "zuludatabase", command: "GetWorkFlow", parameters: { utcoffset: offset }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    updateWorkFlow(facilityId) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "UpdateWorkflow", parameters: { facilityId: facilityId, executedBy: auth.getUserId(), status: "STARTING RPA" }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    startEOMWorkflowAPI(json) {

        return new Promise((resolve, reject) => {
            axios.post('https://jobapi.rcmbrain.com/schedule_workflow_external/', json,
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    startStatusProcess(facilityId, workflowId) {

        return new Promise((resolve, reject) => {

            axios.post("api/StatusBackground?facilityId=" + facilityId + "&workflowId=" + workflowId).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getGetWorkflowByFacility(facilityId) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetWorkflowByFacility", parameters: { facilityId: facilityId }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response.data[0]);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    EOMWizard_Log(facilityId, messageLog) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "SP_EOMWizard_Log", parameters: { facilityId: facilityId, executedBy: auth.getUserId(), message: messageLog }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    addInitialWorkflowFacility(facilityId) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "CreateWorkflowFacility", parameters: { facilityId: facilityId }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }
}