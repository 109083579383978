import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import PublishIcon from '@material-ui/icons/Publish';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: "none",
    },
    upFile: {
        color: theme.palette.primary.light,
    }
});

class FileUploadComp extends React.Component {
    constructor(props) {
        super(props);
    }

    isValidFileName(fileName) {
        if (!fileName) return false;

        if (fileName.trim().length > 0)
            return true;
        else
            return false;
    }

    render() {
        const { classes, onChangeFile, handleSubmit, selectedFileName } = this.props;
        return (
            <div>
                <input
                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/tiff,application/pdf,application/rtf"
                    className={classes.input}
                    id="upFile"
                    type="file"
                    onChange={(e) => onChangeFile(e)}
                />
                <Tooltip title="Select file">
                    <label htmlFor="upFile">
                        <IconButton
                            className={classes.upFile}
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                        >
                            <PublishIcon fontSize="large" />
                        </IconButton>
                    </label>
                </Tooltip>
                <label>{this.isValidFileName(selectedFileName) ? selectedFileName : "Select file"}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button onClick={() => handleSubmit()} color="primary" variant="contained" disabled={!this.isValidFileName(selectedFileName)}>
                    Upload
                </Button>
            </div>
        )
    }
}

FileUploadComp.propTypes = {
    classes: PropTypes.object.isRequired,
    onChangeFile: PropTypes.func,
    handleSubmit: PropTypes.func,
    selectedFileName: PropTypes.string
}

export default withStyles(styles, { withTheme: true })(FileUploadComp);