import * as React from 'react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import UserDataAccess from '../services/userDataAccess';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import PubSub from 'pubsub-js';
import FormHelperText from '@material-ui/core/FormHelperText';

const dataAccess = new UserDataAccess();

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    paperReports: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        height: '100%',
        maxHeight: 324,
        overflow: 'auto'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    }
    ,
    textFieldSm: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '50%',
    }
});




class ChangePassword extends React.Component {



    constructor(props) {
        super(props);

        this.state = {
                open: false,
            passwordErrors: [],
            user: {
currentPassword: "",
newPassword: "",
confirmNewPassword: ""
            },
            openSnack: false,
            snackMessage: ""
        };


    }


    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
    };

    componentWillMount() {
        this.token = PubSub.subscribe('AccountChanged', this.accountChanged.bind(this));

    }
    
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    accountChanged(msg, data) {
        this.props.history.push("/");
    }

    handlePatientChange = name => event => {

        var user = this.state.user;

        user[name] = event.target.value;

        this.setState({
user
        });
    };





    onCancel = () => {

        this.props.history.push('/');
    }

    onChangePassword = () => {

        var user = this.state.user;
        user.UserId = user.EMail;
        var self = this;

        self.setState({ openSnack: false, snackMessage: '', passwordErrors: [] });
        var request = {
            CurrentPassword: this.state.user.currentPassword,
            NewPassword: this.state.user.newPassword,
            ConfirmNewPassword: this.state.user.confirmNewPassword
        };
   
        dataAccess.changePassword(request).then(function (rs) {
            if (rs) {
                self.setState({ openSnack: true, snackMessage: 'Password updated' }, self.onCancel);
            }
            else {
                self.setState({ openSnack: true, snackMessage: 'Unexpected Error Changing Password' });
            }
                    
        }).catch(error => {

            if (error.response.status === 409) {
                if (error.response.data && error.response.data.Result) {
                    self.setState({ passwordErrors: error.response.data.Result.Errors });
                }
                else if (error.response.data && error.response.data.Errors) {
                    self.setState({ openSnack: true, snackMessage: `Error changing password: ${error.response.data.Errors.join()}` });
                }
                else {
                    self.setState({ openSnack: true, snackMessage: 'Unexpected Error Changing Password' });
                }
               // console.log(error.response.data.Result);
            } else {
                self.setState({ openSnack: true, snackMessage: 'Unexpected Error Changing Password' });
            }
           // console.log(error.response);
         
        });
        

    }





    render() {

        const { classes } = this.props;
        const { openSnack, snackMessage } = this.state;
        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Paper className={classes.paper}>
                            <Typography variant="h5">
                            Change Password
                        </Typography>    
                        <Typography>
To comply with HIPAA (45 CFR §164), Surgical Notes requires passwords to be changed every 180 days. Before continuing, please select a new password. Your password must be at least 8 characters and cannot be your UserID.
                        </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="currentPassword"
                                                label="Current Password"
                                                type="Password"
                                                className={classes.textField}
                                                margin="normal"
                                                value={this.state.user.currentPassword}
                                                onChange={this.handlePatientChange('currentPassword')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="newPassword"
                                                label="New Password"
                                                type="Password"
                                                className={classes.textField}
                                                margin="normal"
                                                value={this.state.user.newPassword}
                                                onChange={this.handlePatientChange('newPassword')}
                                            />
                                        </Grid>
                                 
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Confirm New Password"
                                                type="Password"
                                                className={classes.textField}
                                                value={this.state.user.confirmNewPassword}
                                                onChange={this.handlePatientChange('confirmNewPassword')}
                                                margin="normal"
                                                disabled={this.props.match.params.userId > 0}
                                                error={this.state.passwordErrors.length > 0}
                                            />
                                             {this.state.passwordErrors.length > 0 && <FormHelperText id="name-error-text" error>{this.state.passwordErrors.map((e,i) => {

                                                return (
                                                    <span key={i}>{e}<br/></span>
                                                );
                                            }, this)}</FormHelperText>}
                                        </Grid>


                                        <Grid item xs={12}>
                                    <Grid container spacing={3} justify='flex-end'>
                                        <Grid item>
                                            <Button variant="contained" onClick={this.onCancel} color="primary">Cancel</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" onClick={this.onChangePassword} color="primary">Change Password</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>




                                    </Grid>
                             
                                </Grid>


                            </Grid>
                        </Paper>
                    </Grid>


                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(ChangePassword);