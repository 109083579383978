import axios from 'axios';
import Auth from '../services/auth';
import moment from 'moment';
import EnumLookup from '../services/enumLookup';
import { API_ROOT } from './apiConfig';

export default class ScheduleDataAccess {


    getScheduleById(account, id) {

        const auth = new Auth();
        const enumLookup = new EnumLookup();
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Schedule/Get?account=${account}&id=${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {


                    const appointmentDetail = //Object.assign({}, response.data,
                    {
                        Id: response.data.AppointmentInfo ? response.data.AppointmentInfo.Id : 0,
                        Account: response.data.AppointmentInfo ? response.data.AppointmentInfo.Account || '' : "",
                        AppointmentId: response.data.AppointmentInfo ? response.data.AppointmentInfo.AppointmentId || 0 : 0,
                        DateOfBirth: response.data.AppointmentInfo ? (response.data.AppointmentInfo.DateOfBirth ? moment(response.data.AppointmentInfo.DateOfBirth).format('MM/DD/YYYY') : '') : '',
                        DateOfService: response.data.AppointmentInfo ? response.data.AppointmentInfo.DateOfService || '' : '',
                        //DictatorId:"",
                        Physician: response.data.AppointmentInfo ? `${response.data.AppointmentInfo.DictatorLastName}, ${response.data.AppointmentInfo.DictatorFirstName} - ${response.data.AppointmentInfo.DictatorId}` : '',
                        OperatingRoom: response.data.AppointmentInfo ? response.data.AppointmentInfo.OperatingRoom || '' : '',
                        PatientFirstName: response.data.AppointmentInfo ? response.data.AppointmentInfo.PatientFirstName || '' : '',
                        PatientId: response.data.AppointmentInfo ? response.data.AppointmentInfo.PatientId || '' : '',
                        CaseId: response.data.AppointmentInfo ? response.data.AppointmentInfo.AppointmentId || '' : '',
                        PatientLastName: response.data.AppointmentInfo ? response.data.AppointmentInfo.PatientLastName || '' : '',
                        PrimaryPhysican: response.data.AppointmentInfo ? response.data.AppointmentInfo.PrimaryPhysican || '' : '',
                        PrimaryPhysicanNpi: response.data.AppointmentInfo ? response.data.AppointmentInfo.PrimaryPhysicanNpi || '' : '',
                        Procedure: response.data.AppointmentInfo ? response.data.AppointmentInfo.Procedure || '' : '',
                        ReferringPhysician: response.data.AppointmentInfo ? response.data.AppointmentInfo.ReferringPhysician || '' : '',
                        ReferringPhysicianNpi: response.data.AppointmentInfo ? response.data.AppointmentInfo.ReferringPhysicianNpi || '' : '',
                        Reports: response.data.Dictations ? response.data.Dictations.map((dictation, i) => {
                            const result = Object.assign({}, dictation,
                                {

                                    Id: dictation.Report ? dictation.Report.ReportId : 0,
                                    Created: dictation.Created ? moment(dictation.Created).format('MM/DD/YYYY,  h:mm a') : '',
                                    Status: dictation.Status ? enumLookup.getStatus(dictation.Status) : '',
                                    DictationPriority: enumLookup.getDictationPriority(dictation.DictationPriorityId) || ''
                                });
                            return result;
                        }) : []


                    };//);

                    resolve(appointmentDetail);

                })
                .catch(error => {
                    console.log(error)
                });
        });
    }



    saveAppointmentInfo(appointment) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/v1/Schedule/`,
                appointment,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }
    getSummary(includeArchive) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            var searchRequest = {
                "account": "zuludatabase",
                "command": "GetSummary",
                "parameters": { account: auth.getActiveAccount(), FromDate: auth.getFromDate(), ToDate: auth.getToDate() }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`,
                searchRequest, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.getToken()}`
                }
            }).then(function (response) {


                resolve(response.data);
            })
                .catch(error => {
                    reject(error);
                });
        });
    }
    getAll(statusFilters, includeFilteredProcedures) {

        const auth = new Auth();
        var searchRequest = {
            "FromDate": auth.getFromDate(),
            "ToDate": auth.getToDate(),
            "Account": auth.getActiveAccount(),
            "DictatorId": auth.getDictatorId(),
            "IncludeFilteredProcedures": includeFilteredProcedures
        };


        if (statusFilters && statusFilters.length > 0) {
            searchRequest.StatusFilters = statusFilters;
        }

        return this.search(searchRequest);
    }

    saveProcedureFilter(filterRequest) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            axios.post(`${API_ROOT}/api/v1/Schedule/SaveProcedureFilter`,
                filterRequest, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.getToken()}`
                }
            }).then(function (response) {


                resolve(response);
            })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    saveDocumentFromSchedule(formData) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {
            axios.post(`${API_ROOT}/api/v1/Schedule/SaveDocumentFromSchedule/`,
                formData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    search(searchRequest) {

        const auth = new Auth();
        const enumLookup = new EnumLookup();

        return new Promise((resolve, reject) => {

            axios.post(`${API_ROOT}/api/v1/Schedule/Search`,
                searchRequest, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.getToken()}`
                }
            }).then(function (response) {

                const patients = response.data.map((patient, i) => {
                    const result = Object.assign({}, patient,
                        {
                            id: i + 1,
                            Account: patient.AppointmentInfo ? patient.AppointmentInfo.Account : "",
                            CaseId: patient.AppointmentInfo ? patient.AppointmentInfo.AppointmentId : 0,
                            PatientId: patient.AppointmentInfo ? patient.AppointmentInfo.PatientId : 0,
                            Id: patient.AppointmentInfo ? patient.AppointmentInfo.Id : 0,
                            DateOfService: patient.AppointmentInfo ? moment(patient.AppointmentInfo.DateOfService).isValid() ? moment(patient.AppointmentInfo.DateOfService).format('MM/DD/YYYY h:mm:ss A') : '' : '',
                            DateOfBirth: patient.AppointmentInfo ? moment(patient.AppointmentInfo.DateOfBirth).isValid() ? moment(patient.AppointmentInfo.DateOfBirth).format('MM/DD/YYYY') : '' : '',
                            Procedure: patient.AppointmentInfo ? patient.AppointmentInfo.Procedure ? patient.AppointmentInfo.Procedure : "" : "",
                            PatientFirstName: patient.AppointmentInfo ? patient.AppointmentInfo.PatientFirstName : "",
                            Physician: patient.AppointmentInfo ? `${patient.AppointmentInfo.DictatorLastName}, ${patient.AppointmentInfo.DictatorFirstName} - ${patient.AppointmentInfo.DictatorId}` : '',
                            PatientLastName: patient.AppointmentInfo ? patient.AppointmentInfo.PatientLastName : "",
                            PrimaryPhysician: patient.AppointmentInfo ? patient.AppointmentInfo.PrimaryPhysician || "" : "",
                            ReferringPhysician: patient.AppointmentInfo ? patient.AppointmentInfo.ReferringPhysician || "" : "",
                            RowStyle: { borderLeft: `20px solid ${enumLookup.getStatusColor(patient.Status)}`, fontStyle: (patient.AppointmentInfo ? patient.AppointmentInfo.IsFiltered : false) ? 'italic' : 'normal', backgroundColor: (patient.AppointmentInfo ? patient.AppointmentInfo.IsFiltered : false) ? 'rgba(224, 136, 39,.5)' : 'transparent' },
                            CheckBoxStyle: { paddingLeft: 1 },
                            IsFiltered: patient.AppointmentInfo ? patient.AppointmentInfo.IsFiltered : "",
                            ProcedureId: patient.AppointmentInfo ? patient.AppointmentInfo.ProcedureId : "",
                            Status: patient.Status
                        });
                    return result;
                });

                resolve(patients);
            })
                .catch(error => {
                    console.log(error);
                });
        });
    }
}