import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    }),
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    incompleteCard: {
        //minWidth: 240,
        height: 145,
        borderLeft: '6px solid red'
    },
    openCard: {
        //minWidth: 240,
        height: 145,
        borderLeft: '6px solid yellow'
    },
    completeQuestionsCard: {
        //minWidth: 240,
        height: 145,
        borderLeft: '6px solid blue'
    }
    , completeCard: {
        //minWidth: 240,
        height: 145,
        borderLeft: '6px solid green'
    },
    tatCard: {
        height: 250
    },
    tatCardReason: {
        height: 75
    },
    dateSelection: {
        marginLeft: theme.spacing(1)
    }
});

class ProgressIndicator extends React.Component {

    render() {

        return (<div>
            <Dialog
          open={this.props.showProgressIndicator}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{this.props.progressTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.progressText}
            </DialogContentText>
            <LinearProgress color="secondary" />
          </DialogContent>
        </Dialog>
      
    </div>);
       }
}


ProgressIndicator.propTypes = {
    classes: PropTypes.object.isRequired,
    showProgressIndicator: PropTypes.bool.isRequired,
    progressText: PropTypes.string.isRequired,
    progressTitle:PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(ProgressIndicator);