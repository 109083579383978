import axios from 'axios';
import Auth from '../services/auth';
import { API_ROOT } from './apiConfig';


export default class EOMSystemDataAccess {

    getEOMSystems() {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetEOMSystems"
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    sessionStorage.setItem('eomSystemList', JSON.stringify(response.data));
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getReportsBySystemId(systemId) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetReportsBySystemId", parameters: { systemId: systemId }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    sessionStorage.setItem('eomReportList', JSON.stringify(response.data));
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getEOMSettings() {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetEOMSettings"
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    sessionStorage.setItem('eomSettings', JSON.stringify(response.data[0]));
                    resolve(response.data[0]);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }
}
