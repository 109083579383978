import axios from 'axios';
import Auth from '../services/auth';
import moment from 'moment';
import { API_ROOT } from './apiConfig';

export default class ProcedureDataAccess {

    getAccountProcedures() {
        const auth = new Auth();
        return new Promise((resolve, reject) => {
            var searchRequest = {
                "Account": auth.getActiveAccount(),
                "SettingName": "CostPerCase",
            };
            axios.post(`${API_ROOT}/api/v1/Schedule/GetAccountProcedures`,
                searchRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                    });
                })
                .catch(error => {
                    console.log(error);
                });
    }
    getAccountProcedureById(procedureId) {
        const auth = new Auth();
        return new Promise((resolve, reject) => {
            var searchRequest = {
                "Account": auth.getActiveAccount(),
                "ProcedureId": procedureId,
            };
            axios.post(`${API_ROOT}/api/v1/Schedule/GetAccountProcedure`,
                searchRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                    console.log(response);
                });
        })
            .catch(error => {
                console.log(error);
            });
    }
    setProcedureFilter(procedure) {
        const auth = new Auth();
        return new Promise((resolve, reject) => {
            var searchRequest = {
                "Account": auth.getActiveAccount(),
                "ProcedureId": procedure.ProcedureId,
                "IsFiltered": procedure.IsFiltered
            };
            axios.post(`${API_ROOT}/api/v1/Schedule/SetAccountProcedureFilter`,
                searchRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                });
        })
            .catch(error => {
                console.log(error);
            });
    }
}