import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@material-ui/core';
import ProgressIndicator from '../ProgressIndicator';
import WorkFlowManagementDataAccess from '../../services/workFlowManagementDataAccess';
import PubSub from 'pubsub-js';

const workFlowManagementDataAccess = new WorkFlowManagementDataAccess();

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(1),
    }),
});

class RPARenderButton extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            dialogOpen: false,
            snackMessage: "",
            isLoading: false,
            openSnack: false,
        };
    }

    handleClickOpen = () => {
        if (!this.isButtonEnabled()) {
            return;
        }
        this.setState({dialogOpen: true});
    };

    handleCancel = () => {
        this.setState({dialogOpen: false});
    };

    handleConfirm = () => {
        let self = this;
        self.setState({isLoading: true});

        workFlowManagementDataAccess.startEOMWorkflowAPI(self.state.data.WorkflowJson).then(function (rs) {
            if(rs.status == 200)
            {
                workFlowManagementDataAccess.updateWorkFlow(self.state.data.FacilityID).then(function (rs2) {
                    PubSub.publish('workFlowUpdated', [rs2, self.state.data.FacilityID, self.state.data.WorkflowId]);
                    self.setState({ dialogOpen: false, isLoading: false});
                });
            }            
        });
    };

    handleSnackClose = () => {
        this.setState({ openSnack: false, snackMessage: "" });
    };

    navigateToEonWizard = () => {
        const params = {
            systemId: this.state.data.SystemId,
            facilityId: this.state.data.FacilityID,
        };

        this.props.history.push({
            pathname: '/eomwizard',
            state: params,
        });
    };

    isButtonEnabled = () => {
        
        var Status = this.state.data;
        return (
            Status === undefined ||
            (Status.WorkflowStatus === 'EOM Complete' ||
                Status.WorkflowStatus === 'Failed Calculating KPIs' ||
                Status.WorkflowStatus === 'Failed to Process Reports' ||
                Status.WorkflowStatus === 'Failed to Receive Reports' ||
                Status.WorkflowStatus === '7-EXECUTION_STARTED_NOT_COMPLETED' ||
                Status.WorkflowStatus === '5-EXECUTION_FAILED' ||
                Status.WorkflowStatus === 'EOM Failed' ||
                Status.WorkflowStatus === '' ||
                Status.WorkflowStatus === null)
        );
    }

    isButtonHidden = () => {

        var workflow = this.state.data;
        return (
            workflow === undefined ||
            (workflow.WorkflowJson === '{}' ||
                workflow.WorkflowJson === '' ||
                workflow.WorkflowJson === null)
        );
    }

    render() 
    {
        return (
            <div>
            <div>
                <ProgressIndicator showProgressIndicator={this.state.isLoading} progressTitle={'Please wait...'} progressText={'executing process...'} />

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={this.state.openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.snackMessage}</span>}
                />
            </div>
            <div>
                
                    <Button onClick={this.handleClickOpen} color="primary" disabled={!this.isButtonEnabled()} hidden={this.isButtonHidden()}>Start EOM RPA</Button>
                    <Button onClick={this.navigateToEonWizard} color="primary" hidden={!this.isButtonHidden()}>EOM Wizard</Button>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Start EOM RPA"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to Start EOM processing for {this.state.data.FacilityName}?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleConfirm} color="primary" autoFocus>
                        Start
                    </Button>
                    <Button onClick={this.handleCancel} color="primary" >
                        Cancel
                    </Button>
                    </DialogActions>
                </Dialog>
                
                
            </div>
            </div>
              
          );
    }
  }
export default withRouter(withStyles(styles, { withTheme: true })(RPARenderButton));