import axios from 'axios';
import Auth from '../services/auth';
import moment from 'moment';
import EnumLookup from '../services/enumLookup';
import { API_ROOT } from './apiConfig';

export default class SettingsDataAccess {

    getAccountSettings() {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            var searchRequest = {
                "Account": auth.getActiveAccount(),
                "SettingName": "CostPerCase",
            };
            axios.post(`${API_ROOT}/api/v1/Schedule/GetAccountSettings`,
                searchRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }
                    ).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    getAccountSettingsBySettingsName(settingsName) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            var searchRequest = {
                "Account": auth.getActiveAccount(),
                "SettingName": settingsName,
            };
            axios.post(`${API_ROOT}/api/v1/Schedule/GetAccountSettings`,
                searchRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }
            ).then(function (response) {
                resolve(response);
            })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    getAccountSettingsBySettingsNameRcm(settingsName) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            var searchRequest = {
                "Account": '900',
                "SettingName": settingsName,
            };
            axios.post(`${API_ROOT}/api/v1/Schedule/GetAccountSettings`,
                searchRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }
            ).then(function (response) {
                resolve(response);
            })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    setAccountSettings(accountSettings) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            var searchRequest = {
                "Id": accountSettings.Id,
                "Account": auth.getActiveAccount(),
                //"SettingName": accountSettings.SettingName,
                "SettingName": "CostPerCase",
                "SettingValue": accountSettings.SettingValue,
                "IsDeleted": false,
                "CreateDate": new Date(),
                "CreatedBy": auth.getUserId(),
                "LastUpdateDate": new Date(),
                "LastUpdatedBy": auth.getUserId(),
            };
            axios.post(`${API_ROOT}/api/v1/Schedule/SaveAccountSettings`,
                searchRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }
            ).then(function (response) {
                console.log("Resolved!");
                resolve(response);
            })
                .catch(error => {
                    console.log(error)
                });
        });
    }
    
}