import axios from 'axios';
import Auth from '../services/auth';
import { API_ROOT } from './apiConfig';


export default class FacilityDataAccess {

    getAccounts() {

        const auth = new Auth();

        return auth.getUserAccounts();
    }

    getFacilityById(facilityId) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetFacilityById", parameters: { facilityId: facilityId}
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    /*console.log(response);*/

                    const facilities = response.data.map((facility, i) => {
                        const result = Object.assign({}, facility,
                            {
                                FacilityId: facility.FacilityId,
                                SharePointUrl: facility.SharePointUrl,                                
                            });
                        return result;
                    });

                    resolve(facilities[0]);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getFacilityBySystemId(systemId) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetFacilityBySystemId", parameters: { systemId: systemId }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    // console.log('GetClientsByGroupIds response:');
                    // console.log(response.data);
                    sessionStorage.setItem('facilityList', JSON.stringify(response.data));
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getClientsByGroupIds() {
        const auth = new Auth();
        let self = this;
        let params;
        params = { userGroups: auth.getAddGroupIds() };
        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetClientsByGroupIds", parameters: params
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                   // console.log('GetClientsByGroupIds response:');
                   // console.log(response.data);
                    sessionStorage.setItem('clientList', JSON.stringify(response.data));
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getClientList() {
        return JSON.parse(sessionStorage.getItem('clientList'));
    }

}
