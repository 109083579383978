import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    })
});

class NotFound extends React.Component {

    render() {

        return (

            <div><div style={{ paddingTop: '20%' }}>
                <Typography align="center" variant="h4">Page not found</Typography>
                <Typography align="center">We're sorry, we couldn't find the page you requested.</Typography>
                <Typography align="center">Please update your bookmarks or favorites.</Typography>
            <Typography align="center">Return to <a href='/'>home</a></Typography>
            
        </div></div>);
    }
}



export default withStyles(styles, { withTheme: true })(NotFound);
