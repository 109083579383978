import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import ReportDataAccess from '../services/reportDataAccess';
import PubSub from 'pubsub-js';
import EnhancedTable from './EnhancedTable';
import Auth from '../services/auth';
import Snackbar from '@material-ui/core/Snackbar';
import 'core-js/fn/array/find';
import ProgressIndicator from './ProgressIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import qs from 'qs';
import esignDoc from '../docs/E-SignatureForms.pdf';
import TableActionMenu from './TableActionMenu';

const dataAccess = new ReportDataAccess();
const auth = new Auth();

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    })
});

class Reports extends React.Component {
    displayName = Reports.name

 
    constructor(props) {
        super(props);

     

        this.state = {
            reports: [{}],
            statusFilters: [],
            openSnack: false,
            snackMessage: "",
            showProgressIndicator: false,
            progressTitle: "Processing...",
            progressText: "",
            isLoading: false,
            showWarning: false,
            warningMessage: '',
            showBulkSign: false,
            docsToSign: [],
            confirmStatRequest: false,
            activeRequestStat: { Id: 0, PatientID: '', Name: '', JobId: '' },
            dateType: "DateOfService",
            selected: [],
            pendingPrint: false

        }

    }


    loadReportData() {

        var self = this;

        self.setState({ isLoading: true });
        dataAccess.getAll(this.state.statusFilters, this.state.dateType, this.props.match.params.searchTerm).then(function (reportData) {

            self.setState({ reports: reportData , isLoading: false});

        });

    }

    accountChanged(msg, data) {
        this.loadReportData();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.match.params.searchTerm !== this.props.match.params.searchTerm) {
            this.loadReportData();
        }
    }

    componentWillMount() {


        var initialStatus = [];
        var dateType = 'DateOfService';

        if (this.props.location.search) {

            var query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

            if (query.status && query.status.length > 0) {
                initialStatus = query.status;
            }

            if (query.dateType) {
                dateType = query.dateType;
            }
        }
        
        this.setState({
            statusFilters: initialStatus, dateType: dateType
        }, () => {
            this.loadReportData();
        });

        // This is where we subscribe this class to the 'GET FILES' subscription.
        // once a publish event for 'GET FILES' has been fired, FileList.subscriber() will be triggered.
        this.token = PubSub.subscribe('AccountChanged', this.accountChanged.bind(this));
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    onSelectAction = (e, selected, action) => {

        var self = this;

        if (action === 'download') {
            self.setState({ showProgressIndicator: true, progressText: "Downloading reports..." });
            var docs = [];
            console.log(selected);
            for (var i = 0; i < selected.length; i++) {

                console.log(this.state.reports);

                var found = this.state.reports.find(function (element) {
                    return element.Id === selected[i];
                });
                console.log(found);
                docs.push({ "ReportId": selected[i], "FileNamePrefix": `${found.Name}-${found.JobId}`, "FileNameSuffix": "SNMDP229", "Format": e });
            }

            dataAccess.downloadReports({ "Account": auth.getActiveAccount(), "DocumentRequests": docs })
                .then(function (reportData) {


                    var byteNumbers = new Array(reportData.length);
                    for (var i = 0; i < reportData.length; i++) {
                        byteNumbers[i] = reportData.charCodeAt(i);
                    }

                    var byteArray = new Uint8Array(byteNumbers);


                    let fileName = `${Math.random().toString(36).substr(2, 9)}_SNMDP229.zip`;
                    const blob = new Blob([byteArray], { type: "application/zip" });
                    if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(blob, fileName);
                    } else {
                        var link = document.createElement("a");
                        if (link.download !== undefined) {
                            var url = URL.createObjectURL(blob);
                            link.setAttribute('href', url);
                            link.setAttribute('download', fileName);
                            link.style.visibility = 'hidden';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }

                    self.setState({ openSnack: true, snackMessage: "Reports downloaded", showProgressIndicator: false, progressText: "" });
                });
        }

        if (action === "print") {
            //self.setState({ showProgressIndicator: true, progressText: "Generating reports for printing...", selected: selected, pendingPrint: true });

            let printKey = Math.random().toString(36).substr(2, 9);

            sessionStorage.setItem(printKey, JSON.stringify(selected));

            window.open(`/print/${printKey}`);
        }

        if (action === 'archive') {

            dataAccess.archiveReports({ "Account": auth.getActiveAccount(), "ReportIds": selected }).then(function () {
                self.setState({ openSnack: true, snackMessage: "Reports archived" });
                self.loadReportData();
            });
        }

        if (action === 'esign') {

            let docsToSign = this.state.reports
            .filter((element) => {
                return selected.some(x => x === element.Id) && (element.OverallStatus === "Completed" && element.HasValidSignature && element.WorkType !== 'PH')
            })
            .map((doc) => {
                return {
                    ReportId: doc.Id, DictatorId: doc.DictatorId, JobId: doc.JobId, Name: doc.Name, DateOfService: doc.DateOfService, WorkType: doc.WorkType, ParentId : doc.ParentId
                }
            });

            if (docsToSign.length === 0) {
                self.setState({ showWarning: true, warningMessage: (<div>No reports available to sign.  Ensure the report is ready to sign, issues addressed, and a valid signature is on file.  Download the mobile app or <a href={esignDoc} target='_blank'>E-Signature forms</a> to submit a signature.</div>) });
            } else {

                self.setState({ docsToSign: docsToSign, showBulkSign: true });
            }
        }

        if (action === 'codingExport') {

            var requestItems = [];
            for (var i = 0; i < selected.length; i++) {
                var found = this.state.reports.find(function (element) {

                    return (element.Id === selected[i] && (element.CodingStatus !== "NotCodedAll" && element.CodedQA));

                });

                if (found) {
                    requestItems.push(found.JobId);
                }
            }

            if (requestItems.length === 0) {
                self.setState({ showWarning: true, warningMessage: (<div>Coding data not available for selected reports.</div>) });
            } else {
                self.setState({ showProgressIndicator: true, progressText: "Generating coding spreadsheet..." });

                dataAccess.codingExport({ "Account": auth.getActiveAccount(), "Format": e, "JobIds": requestItems }).then(function (reportData) {


                    var byteNumbers = new Array(reportData.length);
                    for (var i = 0; i < reportData.length; i++) {
                        byteNumbers[i] = reportData.charCodeAt(i);
                    }

                    var byteArray = new Uint8Array(byteNumbers);


                    let fileName = `CodingExport${Math.random().toString(36).substr(2, 9)}.xlsx`;
                    const blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(blob, fileName);
                    } else {
                        var link = document.createElement("a");
                        if (link.download !== undefined) {
                            var url = URL.createObjectURL(blob);
                            link.setAttribute('href', url);
                            link.setAttribute('download', fileName);
                            link.style.visibility = 'hidden';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }

                    self.setState({ openSnack: true, snackMessage: "Reports downloaded for coding", showProgressIndicator: false, progressText: "" });
                });
            }
        }
    };

    onFilterChanged = (value, filterName) => {

        if (filterName === "toDate") {
            auth.setToDate(value);
        }

        if (filterName === "fromDate") {
            auth.setFromDate(value);
        }

        this.props.history.replace(`/reports/?${qs.stringify({status: this.state.statusFilters})}`);
        this.loadReportData();


    }

    handleSnackClose = () => {
        this.setState({ openSnack: false, snackMessage: "" });
    };

    canPerformAction = (action) => {

        let canPerform = false;

        switch (action) {
            case 'Archive':
            case 'Sign':
                canPerform = auth.isInRole(action);
                break;
            case 'Coding':
                canPerform = this.state.reports.find(function (element) {
                    return (element.CodingStatus === 'CodedAll' || element.CodingStatus === 'CodingQueryPending' || element.CodingStatus === 'CodedCasesReadyForDownload');
                });;
                break;
            default:
                canPerform = false;
        }


        return canPerform;
    };

    onCanSelect = (item) => {
        return (item.OverallStatus !== 'InProgress');
    } 

    onCancelWarning = () => {

        this.setState({ showWarning: false });
    }

    onCancelRequestStat = () => {
        this.setState({confirmStatRequest: false, activeRequestStat: {Id:0,PatientID:'',Name:'',JobId:''}});
    }

    onRequestStat = () => {
        var self = this;
        dataAccess.requestStat(auth.getActiveAccount(), this.state.activeRequestStat.Id)
            .then(function(){
                    self.setState({confirmStatRequest: false, 
                                    activeRequestStat: {Id:0,PatientID:'',Name:'',JobId:''}, 
                                    openSnack: true, 
                                    snackMessage: "STAT Requested"});
                    self.loadReportData();
            });
    }

    onBulkSign = async () => {
        var self = this;
        self.setState({ showBulkSign: false, showProgressIndicator: true, progressTitle: "Signing reports..." });

        var addendumReportIds = self.state.docsToSign
            .filter(doc => doc.WorkType === "AD")
            .map(doc => doc.ReportId);

        let docsToSign = self.state.docsToSign.map(doc => {
            return { "ReportId": doc.ReportId, "DictatorId": doc.DictatorId };
        });

        try {
            var result = await dataAccess.bulkStitchReports({
                Account: auth.getActiveAccount(),
                ReportIds: addendumReportIds
            });
            if (!result.data.Success) {
                docsToSign = !result.data.FailedReportIds
                    ? docsToSign.filter(x => !addendumReportIds.includes(x.ReportId))
                    : docsToSign.filter(x => !result.data.FailedReportIds.includes(x.ReportId));

                self.setState({ openSnack: true, snackMessage: "Some addendums failed to stitch, try again", showProgressIndicator: false, progressTitle: "" });
            }
        }
        catch (error) {
            docsToSign = docsToSign.filter(x => !addendumReportIds.Contains(x.ReportId));
        }
        

        dataAccess.signReports({
            "Account": auth.getActiveAccount(), "SignatureRequestItems": docsToSign
        })
        .then(function ()
        {
            self.setState({ openSnack: true, snackMessage: "Reports signed", showProgressIndicator: false, progressTitle: "" });
            self.loadReportData();
        });

    }

    onCancelBulkSign = () => {
        this.setState({ docsToSign: [], showBulkSign: false });
    }

    openStatRequest = (item) => {
        this.setState({confirmStatRequest: true, activeRequestStat: item});
    }


    renderComments(item){
        if (item.OverallStatus === "InProgress" && (item.Comments === null || item.Comments === "")){
            return (<Button variant="contained" size="small" color="primary" onClick={(e) => this.openStatRequest(item)}>
                <Typography noWrap variant="body2">Request Stat</Typography>

                </Button>);
        }
        else {
            return(<span>{item.Comments}</span>);
        }
    }

    jobIdClick(e, selectedItem, selected) {
        //console.log(item);
        e.preventDefault();
        var self = this;


        var docsToReview = [];

        if (selectedItem.OverallStatus === "Completed" || selectedItem.OverallStatus === "CompletedWithQuestions") {

            for (var i = 0; i < selected.length; i++) {

                var found = this.state.reports.find(function (element) {
                    return (element.Id === selected[i] && (element.OverallStatus === "Completed" || element.OverallStatus === "CompletedWithQuestions"));
                });
                if (found) {
                    docsToReview.push(selected[i]);
                }
            }
        }

        self.props.history.push({
            pathname: `/reportDetails/${auth.getActiveAccount()}/${selectedItem.Id}`,
            state: { selectedJobs: docsToReview }
        });
    }

    onDateTypeChanged = (dateType) => {

        this.setState({ "dateType": dateType }, this.loadReportData);
    }

    onActionMapped(action, item) {

        if (action.name === 'stat') {


            return item.OverallStatus === "InProgress" && (item.Comments === null || item.Comments === "");

        }

        return true;
    }

    onActionSelected(e, action, item) {


        switch (action) {
            case 'details':
                this.props.history.push(`/reportDetails/${item.Account}/${item.Id}`);
                break;
            case 'stat':
                this.openStatRequest(item);
                break;
            default:
                console.warn(
                    'Action::Unknown action name. Valid types are \'details\' and \'hide\'.',
                    action);

        }
    }

    renderActions(item) {



        return (<TableActionMenu actions={[{ type: 'option', description: 'Request stat', name: 'stat' },
        { type: 'divider' },
        { type: 'option', description: 'Details', name: 'details' }


        ]}
            item={item}
            onActionMapped={this.onActionMapped}
            onActionSelected={(e, action, item) => this.onActionSelected(e, action, item)}
        />);
    }

    render() {

        const { statusFilters, openSnack, snackMessage, showProgressIndicator, progressTitle, progressText } = this.state;

        const columnData = [
            { id: 'JobId', numeric: false, disablePadding: false, label: 'Job ID', linkCallBack: this.jobIdClick.bind(this), linkPath: 'reportDetails', linkParams: ['Account', 'Id'], filter: "textSearch", renderActions: this.renderActions.bind(this) },
            { id: 'WorkType', numeric: false, disablePadding: false, label: 'Work Type', link: false, filter: "choice" },
            { id: 'DateOfService', numeric: false, disablePadding: false, label: 'DOS', link: false, filter: "dateSearch" },
            { id: 'PatientID', numeric: false, disablePadding: false, label: 'Patient ID', link: false, filter: "textSearch" },
            { id: 'Name', numeric: false, disablePadding: false, label: 'Patient', link: false, filter: "textSearch" },
            { id: 'Physician', numeric: false, disablePadding: false, label: 'Physician', link: false, filter: "choice" },
            { id: 'Complete', numeric: false, disablePadding: false, label: 'Job Completed', link: false, filter: "dateSearch" },
            { id: 'DateCoded', numeric: false, disablePadding: false, label: 'Coded Date', link: false, filter: "dateSearch" },
            { id: 'Comments', numeric: false, disablePadding: false, label: 'Comments', link: false, filter: "textSearch" },
            
        ];


        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />
                <Dialog onClose={this.onCancelRequestStat} open={this.state.confirmStatRequest} aria-labelledby="simple-dialog-title">
                    <DialogTitle id="simple-dialog-title">Request Stat</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Your facility has a contracted turnaround time. STAT requests should only be submitted when medically necessary. Please do not use the STAT feature for general, non-clinical or administrative purposes. <strong>Additional charges may apply.</strong>  
                            <br/>
                            <br/>Patient ID:  {this.state.activeRequestStat.PatientID}
                            <br/>Job ID:  {this.state.activeRequestStat.JobId}   
                            <br/>Patient Name:    {this.state.activeRequestStat.Name}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onRequestStat} variant="contained" color="secondary">
                            Request Stat
                        </Button>
                        <Button onClick={this.onCancelRequestStat} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog onClose={this.onCancelWarning} open={this.state.showWarning} aria-labelledby="simple-dialog-title">
                    <DialogTitle id="simple-dialog-title">Warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.warningMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onCancelWarning} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog  onClose={this.onCancelBulkSign} open={this.state.showBulkSign} aria-labelledby="simple-dialog-title">
                    <DialogTitle id="simple-dialog-title">Multi-Document Medicare / Insurance E-Signature Certification</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="body1">
                            For medical review purposes, Medicare requires that services provided/ordered be authenticated by the author. The method used shall be a hand written or an electronic signature.<br /><br />
                            The purpose of a rendering/treating/ordering practitioner's signature in patients' medical records, operative reports, orders, test findings, etc., is to demonstrate the Part B / medical services have been accurately and fully documented, reviewed and authenticated. Furthermore, it confirms the provider has certified the medical necessity and reasonableness for the services submitted to the Medicare program / insurance provider for payment consideration.<br /><br />
                            If you have not previously reviewed the below transcription documents, please review the documents before signing. You may review and sign transcriptions concurrently and in succession by selecting multiple transcriptions and clicking on the Job ID link from the previous page instead of using the E-Sign button.<br /><br />
                            By clicking "Sign Transcriptions" below you certify that you have, to the extent required by law and in accordance with the clinical documentation review policies set forth by your facility, reviewed the following transcription documents and intend to authenticate the contents thereof by means of an electronic signature. <br /><br />
                        
                           
                        </DialogContentText>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>JobId</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>DOS</TableCell>
                                    

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {(this.state.docsToSign && this.state.docsToSign.length > 0) && this.state.docsToSign.map(doc => {
                                    return (<TableRow key={doc.JobId}>
                                        <TableCell component="th" scope="row">
                                            {doc.JobId}
                                        </TableCell>
                                        <TableCell>{doc.Name}</TableCell>
                                        <TableCell>{doc.DateOfService}</TableCell>
                                      
                                    </TableRow>)
                                })}

                               
                            </TableBody>
                        </Table>
                        
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onCancelBulkSign} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.onBulkSign} variant="contained" color="primary">
                            Sign Transcriptions
                        </Button>
                    </DialogActions>
                </Dialog>
                <ProgressIndicator showProgressIndicator={showProgressIndicator} progressTitle={progressTitle} progressText={progressText} />
                <EnhancedTable columnData={columnData}
                    rowData={this.state.reports}
                    isLoading={this.state.isLoading}
                    tableTitle={'Reports'}
                    onSelectAction={this.onSelectAction}
                    defaultStartDate={auth.getFromDate()}
                    defaultEndDate={auth.getToDate()}
                    onGlobalFilterChanged={this.onFilterChanged}
                    includeActions={true}
                    allowSelections={true}
                    canSelect={this.onCanSelect}
                    onDateTypeChanged={this.onDateTypeChanged}
                    dateType={this.state.dateType}
                    orderBy={'Complete'}
                    orderDirection={'desc'}
                    showDateOptions={true}
                    showActions={(!(this.props.match.params.searchTerm && this.props.match.params.searchTerm.length > 0))}
                    globalSelectFilters={[

                        { label: 'In Progress', value: 'InProgress', style: { borderLeft: '10px solid #F5F545'}},
                        { label: 'Completed With Questions', value: 'CompletedWithQuestions', style: { borderLeft: '10px solid #31a7ff' } },
                        { label: 'Completed', value: 'Completed', style: { borderLeft: '10px solid #17c92c' }},
                        { label: 'Signed', value: 'Signed', style: { borderLeft: '10px solid #333333' } },
                        { Divider: true },
                        { label: 'Archived', value: 'Archived', style: { borderLeft: '10px solid #fff' } },
                        { label: 'Not Archived', value: 'NotArchived', style: { borderLeft: '10px solid #fff' } },
                        { label: 'Printed', value: 'Printed', style: { borderLeft: '10px solid #fff' } },
                        { label: 'Not Printed', value: 'NotPrinted', style: { borderLeft: '10px solid #fff' } },
                        { label: 'Downloaded', value: 'Downloaded', style: { borderLeft: '10px solid #fff' } },
                        { label: 'Not Downloaded', value: 'NotDownloaded', style: { borderLeft: '10px solid #fff' }},
                        { label: 'Read', value: 'Read', style: { borderLeft: '10px solid #fff' } },
                        { label: 'Not Read', value: 'NotRead', style: { borderLeft: '10px solid #fff' }},
                        { label: 'Coded Cases - Ready For Download', value: 'CodedCasesReadyForDownload', style: { borderLeft: '10px solid #fff' } },
                        { label: 'Coded Cases - Downloaded', value: 'CodedCasesDownloaded', style: { borderLeft: '10px solid #fff' } },
                        { label: 'Coded - All', value: 'CodedAll', style: { borderLeft: '10px solid #fff' } },
                        { label: 'Coding Query Pending', value: 'CodingQueryPending', style: { borderLeft: '10px solid #fff' } },
                        { label: 'Not Coded - All', value: 'NotCodedAll', style: { borderLeft: '10px solid #fff' } }
                    ]}
                    defaultFilters={statusFilters}
                    canPerformAction={this.canPerformAction} />
            </div>
        );
    }
}

Reports.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Reports);