import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import EnhancedTable from './EnhancedTable';
import TableActionMenu from './TableActionMenu';
import ScheduleDataAccess from '../services/scheduleDataAccess';
import Auth from '../services/auth';
import Snackbar from '@material-ui/core/Snackbar';
import PubSub from 'pubsub-js';
import qs from 'qs';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import ScheduleAdd from './ScheduleAdd';

const dataAccess = new ScheduleDataAccess();
const auth = new Auth();


const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    })
});



class Schedule extends Component {
    displayName = Schedule.name

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            statusFilters: [],
            isLoading: false,
            openSnack: false,
            snackMessage: "",
            openProcedureDialog: false,
            activeProcedureFilter: { Account: '', Id: 0, Description: '' },
            includeFilteredItems: false,
            showAddDialog: false
        };
    }

    componentWillMount() {

        var initialStatus = ["New", "NeedsDictation", "InProgress", "CompletedWithQuestions", "Completed"];

        if (this.props.location.search) {

            var query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

            if (query.status && query.status.length > 0) {
                initialStatus = query.status;
            }
        }

        this.setState({
            statusFilters: initialStatus
        }, () => {
            this.loadScheduleData();
        });
        // This is where we subscribe this class to the 'GET FILES' subscription.
        // once a publish event for 'GET FILES' has been fired, FileList.subscriber() will be triggered.
        this.token = PubSub.subscribe('AccountChanged', this.accountChanged.bind(this));
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadScheduleData() {
        var self = this;
        self.setState({ isLoading: true });
        dataAccess.getAll(this.state.statusFilters, this.state.includeFilteredItems).then(function (reportData) {
            self.setState({ data: reportData, isLoading: false });
        });
    }

    handleSnackClose = () => {
        this.setState({ openSnack: false, snackMessage: "" });
    };

    accountChanged(msg, data) {
        this.loadScheduleData();
    }

    onAddScheduleClick = () => {

        let showDialog = !this.state.showAddDialog;
        this.setState({ showAddDialog: showDialog });
    }


    onFilterChanged = (value, filterName) => {

        if (filterName === "toDate") {
            auth.setToDate(value);
            this.loadScheduleData();
        }

        if (filterName === "fromDate") {
            auth.setFromDate(value);
            this.loadScheduleData();
        }

        if (filterName === "status") {


            this.setState({
                statusFilters: value
            }, () => {

                this.loadScheduleData();
                this.props.history.replace(`/schedule/?${qs.stringify({ status: this.state.statusFilters })}`);
            });


        }




    }
    onActionMapped(action, item) {

        if (action.name === 'hide') {
            return (auth.isInRole('FacilityAdmin') || auth.isInRole("Admin")) && item.Status <= 1 && item.ProcedureId > 0;

        }

        return true;
    }

    onActionSelected(e, action, item) {


        switch (action) {
            case 'details':
                this.props.history.push(`/scheduleDetails/${item.Account}/${item.Id}`);
                break;
            case 'hide':
                this.setState({ openProcedureDialog: true, activeProcedureFilter: { Account: item.Account, Id: item.ProcedureId, Description: item.Procedure }, });
                break;
            default:
                console.warn(
                    'Action::Unknown action name. Valid types are \'details\' and \'hide\'.',
                    action);

        }
    }
    onCancelProcedureDialog = () => {
        this.setState({ openProcedureDialog: false, activeProcedureFilter: { Account: '', Id: 0, Description: '' } });
    }

    onCancelScheduleAdd = () => {
        this.setState({ showAddDialog: false });
    }

    onSaveScheduleComplete = () => {
        this.setState({ openSnack: true, snackMessage: 'Appointment added', showAddDialog: false });

        this.loadScheduleData();
    }

    onSaveProcedureFilter = () => {

        var self = this;

        dataAccess.saveProcedureFilter({ Account: this.state.activeProcedureFilter.Account, ProcedureId: this.state.activeProcedureFilter.Id, IsFiltered: true }).then((data) => {

            self.setState({ openSnack: true, snackMessage: 'Saved procedure filter', openProcedureDialog: false, activeProcedureFilter: { Account: '', Id: 0, Description: '' } });
            self.loadScheduleData();
        });

    }

    renderActions(item) {



        return (<TableActionMenu actions={[{ type: 'option', description: 'Hide items like this', name: 'hide' },
        { type: 'divider' },
        { type: 'option', description: 'Details', name: 'details' }


        ]}
            item={item}
            onActionMapped={this.onActionMapped}
            onActionSelected={(e, action, item) => this.onActionSelected(e, action, item)}
        />);
    }

    toggleFilteredItems = () => {

        var self = this;
        var selectedState = !this.state.includeFilteredItems;
        this.setState({ includeFilteredItems: selectedState }, () => {
            self.loadScheduleData();
        });
    }

    rendertableFilterToggle = () => {

        return (<FormControlLabel
            control={<Switch size="small" checked={this.state.includeFilteredItems} onChange={this.toggleFilteredItems} />}
            label={<Typography variant="subtitle2">Include filtered visits</Typography>}
        />);
    }

    render() {


        const { statusFilters, openSnack, snackMessage } = this.state;
        const columnData = [
            { id: 'PatientId', numeric: false, disablePadding: false, label: 'Patient ID', link: true, linkPath: 'scheduleDetails', linkParams: ['Account', 'Id'], filter: "textSearch", renderActions: this.renderActions.bind(this), maxWidth: 75 },
            { id: 'DateOfService', numeric: false, disablePadding: false, label: 'Date Of Service', link: false, filter: "dateSearch" },
            { id: 'PatientFirstName', numeric: false, disablePadding: false, label: 'Patient First Name', link: false, filter: "textSearch" },
            { id: 'PatientLastName', numeric: false, disablePadding: false, label: 'Patient Last Name', link: false, filter: "textSearch" },
            { id: 'Physician', numeric: false, disablePadding: false, label: 'Physician', link: false, filter: "choice", maxWidth: 160 },
            { id: 'Procedure', numeric: false, disablePadding: false, label: 'Procedure', link: false, filter: "textSearch", maxWidth: 200 },
            { id: 'DateOfBirth', numeric: false, disablePadding: false, label: 'Date Of Birth', link: false, filter: "dateSearch" },
        ];


        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />
                <ScheduleAdd open={this.state.showAddDialog}
                    onCancel={this.onCancelScheduleAdd}
                    onSaveComplete={this.onSaveScheduleComplete}
                ></ScheduleAdd>
                <Dialog onClose={this.onCancelProcedureDialog} open={this.state.openProcedureDialog} aria-labelledby="simple-dialog-title">
                    <DialogTitle id="simple-dialog-title">Create Procedure Filter</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Always hide visits with this procedure <strong>'{this.state.activeProcedureFilter.Description}'</strong>?
                            <br />
                            <Typography variant="caption">Note: Visits will also be hidden from the Surgical Notes mobile application</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onSaveProcedureFilter} variant="contained" color="secondary">
                            OK
                        </Button>
                        <Button onClick={this.onCancelProcedureDialog} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <EnhancedTable columnData={columnData}
                    rowData={this.state.data} tableTitle={'Schedule'}
                    defaultStartDate={auth.getFromDate()}
                    isLoading={this.state.isLoading}
                    defaultEndDate={auth.getToDate()}
                    onGlobalFilterChanged={this.onFilterChanged}
                    includeActions={false}
                    showAddButton={auth.isInRole('Save')}
                    onAddClick={this.onAddScheduleClick}
                    allowSelections={false}
                    orderBy={'DateOfService'}
                    orderDirection={'desc'}
                    showActions
                    tableFilterToggle={this.rendertableFilterToggle}
                    rowActions={[
                        "Hide items like this...",
                        "Details"
                    ]}
                    globalSelectFilters={[
                        { label: 'New', value: 'New', style: { borderLeft: '10px solid #FFFFFF' } },
                        { label: 'Needs Dictation', value: 'NeedsDictation', style: { borderLeft: '10px solid #F40013' } },
                        { label: 'In Progress', value: 'InProgress', style: { borderLeft: '10px solid #F5F545' } },
                        { label: 'Completed With Questions', value: 'CompletedWithQuestions', style: { borderLeft: '10px solid #31a7ff' } },
                        { label: 'Completed', value: 'Completed', style: { borderLeft: '10px solid #17c92c' } },
                        { label: 'Signed', value: 'Signed', style: { borderLeft: '10px solid #333333' } }
                    ]}
                    defaultFilters={statusFilters} />
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(Schedule);
