import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
//import PubSub from 'pubsub-js';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

//const onUpdate = () => {
//    PubSub.publish('AppUpdated');
//}

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

unregister();
//registerServiceWorker(onUpdate);