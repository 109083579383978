
export default class EnumLookup {

    getStatus(status) {

        let result = '';

        switch (status) {
            case 3:
                result = "In Progress";
                break;
            case 4:
                result = "Completed with Questions";
                break;
            case 5:
                result = "Complete, Ready to Sign";
                break;
            case 6:
                result = "Complete, Signed";
                break;
            default:
                result = '';
        }

        return result;
    }

    getStatusColor(status) {

        let result = '';
     
        switch (status) {
            case 0:
                result = "#FFFFFF";
                break;
             case "Needs Dictation":
            case 1:
                result = "#F40013";
                break;
            case 2:
                result = "#FD9941";
                break;
            case "In Progress":
            case 'InProgress':
            case 3:
                result = "#F5F545";
                break;
            case "Completed with Questions":
            case 'CompletedWithQuestions':
            case 4:
                result = "#31a7ff";
                break;
            case "Complete, Ready to Sign":
            case 'Completed':
            case 5:
                result = "#17c92c";
                break;
            case "Complete, Signed":
            case 'Signed':
            case 6:
                result = "#333333";
                break;
            default:
                result = '';
        }

        return result;
    }

    getDictationPriority(status) {

        let result = '';

        switch (status) {
            case 0:
                result = "Normal";
                break;
            case 1:
                result = "STAT";
                break;
            default:
                result = '';

        }

        return result;
    }

}