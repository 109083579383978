import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import { orange } from '@material-ui/core/colors';
import { yellow } from '@material-ui/core/colors';
import CheckCircleIconCheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
});

class CheckIconCellRenderer extends React.Component {
    render() {
        if (this.props.value) {
            if (parseInt(this.props.value) <= 24) {
                return (<CheckCircleIconCheckCircleIcon style={{ color: green[500] }} ></CheckCircleIconCheckCircleIcon>);
            }
            else if (parseInt(this.props.value) > 24 && parseInt(this.props.value) <= 48) {
                return (<CheckCircleIconCheckCircleIcon style={{ color: yellow[500] }} ></CheckCircleIconCheckCircleIcon>);
            }
            else if (parseInt(this.props.value) > 48 && parseInt(this.props.value) <= 72) {
                return (<CheckCircleIconCheckCircleIcon style={{ color: orange[500] }} ></CheckCircleIconCheckCircleIcon>);
            }
            else {
                return (<CheckCircleIconCheckCircleIcon style={{ color: red[500] }} ></CheckCircleIconCheckCircleIcon>);
            }
        }
        else {
            return (<span></span>);
        }    
    }
}

CheckIconCellRenderer.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(CheckIconCellRenderer);