import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ReactGA from 'react-ga4';
import Auth from '../services/auth';
import esignDoc from '../docs/E-SignatureForms.pdf';
import mobileUserGuide from '../docs/MobileTranscriptionAppUserManual.pdf';
import userGuide from '../docs/20230323 - Surgical Notes RCM Brain User Guide.pdf';
import quickReferenceGuide from '../docs/QuickReferenceGuide.pdf';
//import YoutubeDataAccess from '../services/youtubeDataAccess';
import CircularProgress from '@material-ui/core/CircularProgress';
/*const youtubeDataAccess = new YoutubeDataAccess();*/

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    }),
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    incompleteCard: {
        minWidth: 240,
        height: 145,
        borderLeft: '6px solid red'
    },
    openCard: {
        minWidth: 240,
        height: 145,
        borderLeft: '6px solid yellow'
    },
    completeQuestionsCard: {
        minWidth: 240,
        height: 145,
        borderLeft: '6px solid blue'
    }
    , completeCard: {
        minWidth: 240,
        height: 145,
        borderLeft: '6px solid green'
    },
    tatCard: {
        height: 250
    },
    tatCardReason: {
        height: 75
    }
});


class Help extends Component {
    displayName = Help.name
    constructor(props) {
        super(props);
        //this.state = {
        //    youtubeList:
        //    {
        //        playlistName: "",
        //        videos: [{
        //            title: "",
        //            url: ""
        //        }]
        //    },
        //    youtubeList2: [{
        //        title: "",
        //        url: ""
        //    }],
        //    isLoading: true,
        //};
        /*this.loadingYoutubeList = this.loadingYoutubeList.bind(this);*/
    }
    onClassicView = () => {
        const auth = new Auth();
        ReactGA.event({
            action: 'Classic Portal',
            category: `${auth.getGaUser()}`
        });
    }
    componentWillMount() {
        
    }

    render() {
        /*const { youtubeList, youtubeList2 } = this.state;*/
        return (
            <div>
                <Typography variant="h2" gutterBottom>
                    Help
                </Typography>
                <Card>
                    <CardContent>
                        <Grid container spacing={3} justify='space-between'>
                            <Grid item xs={12} lg={4} md={4}>
                                <Typography variant="h5" gutterBottom>
                                    How to's
                                </Typography>
                                <List>
                                    <ListItem button component="a" target="_blank" href={userGuide}>
                                        <ListItemText primary="User Manual (Web)" />
                                    </ListItem>
                                    <ListItem button component="a" target="_blank" href={mobileUserGuide}>
                                        {/*<ListItemText primary="User Manual (Mobile)" />*/}
                                    </ListItem>
                                    <ListItem button component="a" target="_blank" href={quickReferenceGuide}>
                                        {/*<ListItemText primary="Dictation Quick Reference Guide" />*/}
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} lg={4} md={4}>
                                {/*<Typography variant="h5" gutterBottom>*/}
                                {/*    FAQ's*/}
                                {/*</Typography>*/}
                                {/*<List>*/}
                                {/*    <ListItem button component={Link} to='/ChangePassword'>*/}
                                {/*        <ListItemText*/}
                                {/*            primary="Change Password"*/}
                                {/*        />*/}
                                {/*    </ListItem>*/}
                                {/*    <ListItem button component="a" target="_blank" href="https://www.surgicalnotes.com/solutions/transcription/document-retrieval/">*/}
                                {/*        <ListItemText*/}
                                {/*            primary="Archive Request"*/}
                                {/*        />*/}
                                {/*    </ListItem>*/}
                                {/*    <ListItem button component="a" target="_blank" href={esignDoc}>*/}
                                {/*        <ListItemText*/}
                                {/*            primary="E-Signature Forms"*/}
                                {/*        />*/}
                                {/*    </ListItem>*/}
                                {/*</List>*/}
                            </Grid>
                            <Grid item xs={12} lg={4} md={4}>
                                {/*<List>*/}
                                {/*    <Typography variant="h5" gutterBottom>*/}
                                {/*        Contact*/}
                                {/*    </Typography>*/}
                                {/*    <ListItem button component="a" target="_blank" href="mailto:AcctMgrs@surgicalnotes.com">*/}
                                {/*        <ListItemText*/}
                                {/*            primary="Account Manager Team"*/}
                                {/*        />*/}
                                {/*    </ListItem>*/}
                                {/*    <ListItem button component="a" target="_blank" href="tel:1-800-459-5616">*/}
                                {/*        <ListItemText*/}
                                {/*            primary="(800) 459-5616"*/}
                                {/*        />*/}
                                {/*    </ListItem>*/}
                                {/*</List>*/}
                            </Grid>
                                            
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(Help);
