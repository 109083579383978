import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { API_ROOT } from './apiConfig';
import { getDateOffset } from './accountSettings';

export default class Auth {


    exchangeTokenLogin(subjectToken) {
        return this.processToken({
            'subject_token': subjectToken,
            'grant_type': 'token_exchange'
        });

    }

    setSSOIdentity(username) {
        localStorage.setItem("SSOIdentity", username);
    }

    getSSOIdentity(username) {
       return localStorage.getItem("SSOIdentity") || "";
    }

    login(username, password) {

        return this.processToken({
            'username': username,
            'password': password,
            'grant_type': 'password'
        });
    }

    getSSOConfig(username) {

        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Users/SSOConfiguration?email=${username}`,
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(function (response) {
                    
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });

    }

    processToken(authParams) {
        return new Promise((resolve, reject) => {

            axios.get(`api/FedAuth/GetUserIdGroupsId`,
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(function (claimsResponse) {

                    sessionStorage.setItem('userAddId', claimsResponse.data.UserAddId);
                    sessionStorage.setItem('groupIds', claimsResponse.data.GroupIds);
                    sessionStorage.setItem('showMyItems', true);

                    axios.post(`${API_ROOT}/token`,
                        qs.stringify(authParams), {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    }).then(function (response) {
                        let expiresAt = JSON.stringify((response.data.expires_in * 1000) + new Date().getTime());
                        sessionStorage.setItem('access_token', response.data.access_token);
                        sessionStorage.setItem('expires_at', expiresAt);
                        sessionStorage.setItem('pwd_expires_in', response.data.pwd_expires_in);
                        axios.get(`${API_ROOT}/api/v1/users/me`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": `Bearer ${response.data.access_token}`
                                }
                            }).then(function (userResponse) {
                                let gaUser = '';
                                sessionStorage.setItem('homeAccount', userResponse.data.Account);
                                sessionStorage.setItem('secArchive', userResponse.data.secArchive);
                                sessionStorage.setItem('secMarkRead', userResponse.data.secMarkRead);
                                sessionStorage.setItem('secSave', userResponse.data.secSave);
                                sessionStorage.setItem('secSign', userResponse.data.secSign);
                                sessionStorage.setItem('userName', userResponse.data.Name);
                                sessionStorage.setItem('userId', userResponse.data.UserName);
                                sessionStorage.setItem('userType', userResponse.data.UserType);
                                sessionStorage.setItem('email', userResponse.data.Email);
                                sessionStorage.setItem('dictatorId', userResponse.data.DictatorId);
                                sessionStorage.setItem('fromDate', moment().subtract(getDateOffset(userResponse.data.Account), 'days').format("MM/DD/YYYY"));
                                sessionStorage.setItem('toDate', moment().format("MM/DD/YYYY"));
                                sessionStorage.setItem('roles', JSON.stringify(userResponse.data.Roles));
                                sessionStorage.setItem('ssoIdentityColumn', userResponse.data.SSOIdentity);
                                if (userResponse.data.DictatorId) {
                                    sessionStorage.setItem('isValidLogin', true);
                                    gaUser = userResponse.data.UserName;
                                }
                                else {
                                    //sessionStorage.setItem('isValidLogin', true);
                                    sessionStorage.setItem('isValidLogin', userResponse.data.IdentityMode > 0);
                                    gaUser = `${userResponse.data.Account}_${userResponse.data.LUID}`;
                                }

                                sessionStorage.setItem('gaUserId', gaUser);

                                let reqBody = { account: "zuludatabase", command: "GetClient" };

                                axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Authorization": `Bearer ${response.data.access_token}`
                                        }
                                    }).then(function (userAccResponse) {

                                        if (userAccResponse.data !== null && userAccResponse.data.length) {
                                            sessionStorage.setItem('centerName', userAccResponse.data[0].Facility);
                                            sessionStorage.setItem('activeAccount', userAccResponse.data[0].Account);
                                            if (userAccResponse.data.length > 1) {
                                                sessionStorage.setItem('canSwitchAccounts', "true");
                                                sessionStorage.setItem('userAccounts', JSON.stringify(userAccResponse.data));
                                            }
                                            else {
                                                sessionStorage.setItem('canSwitchAccounts', "false");
                                            }
                                        }

                                        //console.log(gaUser);
                                        ReactGA.set({ "user_id": gaUser });
                                        ReactGA.event({
                                            action: 'Login',
                                            category: `${gaUser}`
                                        });

                                        resolve(true);

                                    });

                            });
                    });

                }).catch(error => {
                    reject(error);
                });
        });
    }
   
    isInRole(role){

        var hasRole = false;
        switch(role) {
            case 'Archive':
                hasRole = sessionStorage.getItem('secArchive') === 'true';
                break;
            case 'MarkRead':
                hasRole = sessionStorage.getItem('secMarkRead') === 'true';
                break;
            case 'Save':
                hasRole = sessionStorage.getItem('secSave') === 'true';
                break;
            case 'Sign':
                hasRole = sessionStorage.getItem('secSign') === 'true';
                break;
             case 'Admin':
                hasRole = sessionStorage.getItem('homeAccount') === '998';
                break;
            case 'FacilityAdmin':
                let roles = JSON.parse(sessionStorage.getItem('roles'));
                hasRole = roles.indexOf('Administrator') > -1 || roles.indexOf('SupportAdmin') > -1;
                break;
            case 'AddEditUser':
                let rolesSuper = JSON.parse(sessionStorage.getItem('roles'));
                if (this.getActiveAccount() == "998") {
                    if (rolesSuper.indexOf('ServerAdmin') > -1)
                        hasRole = true;
                    else
                        hasRole = false;
                }
                else
                    hasRole = true;
                break;
            case 'Editor':
                if (sessionStorage.getItem('secMarkRead') === 'true' && sessionStorage.getItem('secSave') === 'true')
                    hasRole = true;
                break;
            default:
                hasRole = false;
        }
        return hasRole;
    }


    isInADDGroup() {

        var isInGroup = false;
        let RCMGlobalAdminGroup = "3f31d32f-77fc-44a5-a939-8d2bc659003e";
        let groups = sessionStorage.getItem('groupIds');
        isInGroup = groups.includes(RCMGlobalAdminGroup);

        return isInGroup;
    }

    isInEOMWizardGroup() {

        var isInGroup = false;
        let RCMEOMWizardGroup = "efae2b85-70ed-40ec-9aae-f19b1921c525";
        let groups = sessionStorage.getItem('groupIds');
        isInGroup = groups.includes(RCMEOMWizardGroup);

        return isInGroup;
    }

    isInEOMWizardHistoricalGroup() {

        var isInGroup = false;
        let RCMEOMWizardHistoricalGroup = "41578322-6895-4552-9651-02a866be9aad";
        let groups = sessionStorage.getItem('groupIds');
        isInGroup = groups.includes(RCMEOMWizardHistoricalGroup);

        return isInGroup;
    }

    isAuthenticated() {

        var expiresAt = JSON.parse(sessionStorage.getItem('expires_at') || '{}');
        return new Date().getTime() < expiresAt;
    }

    isPasswordExpired() {
        var expiresAt = parseInt(sessionStorage.getItem('pwd_expires_in'));
        return expiresAt <= 0;
    }

    getPasswordExpiresIn() {
        return parseInt(sessionStorage.getItem('pwd_expires_in'));
    }

    clearPasswordExpExpiresIn() {
        sessionStorage.setItem('pwd_expires_in',180);
    }

    saveUserLogin(userName) {
        sessionStorage.setItem('snuser', userName);
    }

    getUserLogin() {
        return sessionStorage.getItem('snuser');
    }

    getGaUser() {

        return sessionStorage.getItem('gaUserId');
    }

    getUserName() {
        return sessionStorage.getItem('userName');
    }

    getUserEmail() {
        return sessionStorage.getItem('email');
    }

    getAddUserId() {
        return sessionStorage.getItem('userAddId');
    }

    getAddGroupIds() {
        return sessionStorage.getItem('groupIds');
    }

    getUserId() {
        return sessionStorage.getItem('userId');
    }

    getSSOIdentityColumn() {
        return sessionStorage.getItem('ssoIdentityColumn');
    }

    getUserType() {
        return sessionStorage.getItem('userType');
    }

    getFromDate() {
        return sessionStorage.getItem('fromDate');
    }

    getToDate() {
        return sessionStorage.getItem('toDate');
    }

    isValidLogin() {
        return sessionStorage.getItem('isValidLogin') === "true";
    }

    setValidLogin(valid) {
        return sessionStorage.setItem('isValidLogin', valid);
    }

    setFromDate(value) {
        sessionStorage.setItem('fromDate', value);
    }

    setToDate(value) {
        sessionStorage.setItem('toDate', value);
    }

    getDictatorId() {
        return sessionStorage.getItem('dictatorId');
    }

    getActiveAccount() {
        return sessionStorage.getItem('activeAccount');
    }

    getHomeAccount() {
        return sessionStorage.getItem('homeAccount');
    }

    setActiveAccount(account) {
        return sessionStorage.setItem('activeAccount', account);
    }

    getUserAccounts() {
        return JSON.parse(sessionStorage.getItem('userAccounts') || '[{}]');
    }

    getCenterName() {
        return sessionStorage.getItem('centerName');
    }

    canSwitchAccounts() {
        return sessionStorage.getItem('canSwitchAccounts');
    }

    getToken() {
        // Retrieves the user token from localStorage
        return sessionStorage.getItem('access_token');
    }

    getTickler() {
        return sessionStorage.getItem('tickler');
    }

    setSchema(value) {
        sessionStorage.setItem('schema', value);
    }

    getSchema() {
        return sessionStorage.getItem('schema');
    }

    setShowMyItems(value) {
        sessionStorage.setItem('showMyItems', value);
    }

    getShowMyItems() {
        return (sessionStorage.getItem('showMyItems') === 'true');
    }

    isFacilityAdminFor(account) {

        if (this.isInRole('FacilityAdmin')) {
            var data = JSON.parse(sessionStorage.getItem('userAccounts') || '[{}]');
            const item = data.find(item => item.Account == account);

            if (item)
                return true;
            else
                return false;
        }

        return false;
    }

    signOut(reload = true) {
        // Clear user token and profile data from localStorage
        //const history = createBrowserHistory();
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('expires_at');
        sessionStorage.removeItem('pwd_expires_in');
        //location.reload(true);
        //history.replace('/');
        let self = this;

        let schema = self.getSchema();

        if (schema == "AAD") {
            self.setSchema("");
            window.location = `https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=${window.location.origin}/enterpriselogin`;
        } else {
            axios.get(`api/Logoff`,
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(function (response) {
                    if (reload) {
                        window.location.reload();                       
                    }

                }).catch(error => {
                    if (reload) {
                        window.location.reload();
                    }
                });
        }
    }

 
    markNotificationsRead(notifications) {

        var userKey = sessionStorage.getItem('gaUserId');
        var readNotifications = localStorage.getItem(`${userKey}_readNotifications`) != null ? JSON.parse(localStorage.getItem("readNotifications")) : [];
        if (!Array.isArray(readNotifications)) {
            readNotifications = [];
        }

        for (var i = 0; i < notifications.length; i++) {

            if (!notifications[i].IsRead) {
                notifications[i].IsRead = true;
                readNotifications.push(notifications[i].NotificationId);

                ReactGA.event({
                    action: 'NotificationRead',
                    category: `${userKey}_${notifications[i].NotificationId}`
                });
            }
        }
        localStorage.setItem(`${userKey}_readNotifications`, JSON.stringify(readNotifications));
        
        return notifications;
    }

    getNotifications() {

        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Users/Notifications`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                }).then(function (response) {

                    var userKey = sessionStorage.getItem('gaUserId');
                    var readNotifications = localStorage.getItem(`${userKey}_readNotifications`) != null ? JSON.parse(localStorage.getItem(`${userKey}_readNotifications`)) : [];
                    if (!Array.isArray(readNotifications)) {
                        readNotifications = [];
                    }
                    
                    const notifications = response.data.map((notification, i) => {
                        const result = Object.assign({}, notification,
                            {
                                Url: decodeURIComponent(notification.Url),
                                IsRead: readNotifications.indexOf(notification.NotificationId) > -1
                            });

                        return result;
                    });

                    resolve(notifications);
                })
                .catch(error => {
                    console.log(error)
                });
        });

    }

}