import React from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        width: '100%'
    }
});

class TableActionMenu extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null
        };
    }

    onOpenMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    onCloseMenu(e, option) {
        this.setState({ anchorEl: null });
        if (option !== null) {
            if (this.props.onActionSelected) {
                this.props.onActionSelected(e, option, this.props.item);
            }
        }
    }

    render() {

        return (

            <div style={{paddingLeft:8,paddingRight:8}}>
                <IconButton
                    aria-haspopup="true"
                    onClick={this.onOpenMenu}
                    style={{padding:0}}
                    color="inherit"
                    size="small"
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={(e) => this.onCloseMenu(e, null)}
                >
                    {this.props.actions.map(action => {
                        const actionKey = `${this.props.item.id}_${action.name}`;
                        if (this.props.onActionMapped(action, this.props.item)) {
                            switch (action.type) {
                                case 'option':
                                    return (
                                        <MenuItem key={actionKey} onClick={(e) => this.onCloseMenu(e, action.name)}>
                                            {action.description}
                                        </MenuItem>
                                    );
                                case 'divider':
                                    return (<Divider key={actionKey} />);
                                default:
                                    console.warn(
                                        'ActionMenu::Unknown action type. Valid types are \'option\' and \'divider\'.',
                                        action);
                                    return null;
                            }
                        } else {
                            return (
                                <MenuItem key={actionKey} disabled={true}>
                                    {action.description}
                                </MenuItem>
                            );
                        }
                    })}
                  
                </Menu></div>);
    }
}

TableActionMenu.propTypes = {
    actions: PropTypes.array.isRequired,
    item: PropTypes.object.isRequired,
    onActionSelected: PropTypes.func.isRequired
};

export default withStyles(styles)(TableActionMenu);