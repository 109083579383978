import React, { Component } from 'react';
import tinymce from 'tinymce/tinymce';
import Grid from '@material-ui/core/Grid';

// A theme is also required
import 'tinymce/themes/silver';
import { Editor } from '@tinymce/tinymce-react';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/advlist'
//import 'tinymce/plugins/pagebreak'


export default class RichTextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            html: '',
            htmlError: ''
        };
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.html === '') {
            tinymce.activeEditor.undoManager.clear();
            tinymce.activeEditor.undoManager.add();

            //console.log(tinymce.activeEditor.getDoc().body.style);
           // console.log(this.props.font);
            tinymce.activeEditor.getDoc().body.style.fontSize = this.props.fontSize; //'12px';
            tinymce.activeEditor.getDoc().body.style.fontFamily = this.props.font;
            // this.props.font;//

            //tinymce.activeEditor.dom.addStyle(`html, body, p {font-family:'${this.props.font}' !important; font-size:${this.props.fontSize};}`);
           // console.log(tinymce.activeEditor);
            //tinymce.activeEditor.settings.theme_advanced_fonts = "Courier New=courier new;";
          //  tinymce.activeEditor.editorCommands.execCommand("fontName", false, this.props.font);
          //  tinymce.activeEditor.execCommand("fontName", false, "arial");
         //   tinymce.activeEditor.execCommand("fontSize", false, this.props.fontSize);
        }

        //tinymce.activeEditor.editorCommands.execCommand("fontName", false, "Arial");
    }

    handleEditorChange = (html) => {
        this.setState({ html });
        this.props.onEditorChange(html);
    }


    render() {


        /* tinymce.init({
            // branding: false,
             valid_children: '+span[div]',
             extended_valid_elements: 'span[*]',
             plugins: 'legacyoutput,autoresize,paste',
             cleanup_on_startup: false,
             trim_span_elements: false,
             verify_html: false,
             cleanup: false,
             convert_urls: false,
             skin_url: '/lib/skins/lightgray',
             paste_auto_cleanup_on_paste: true,
             paste_remove_styles: true,
             paste_remove_styles_if_webkit: true,
             paste_strip_class_attributes: true,
         });*/

        return (
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Editor
                        value={this.props.reportData}
                        onEditorChange={this.handleEditorChange}
                        init={{
                            toolbar: 'undo redo | bold italic | forecolor backcolor | alignleft aligncenter alignright | numlist bullist | code ',
                            branding: false,
                            //font_formats: 'Arial=arial;',
                            skin_url: '/lib/skins/lightgray',
                            forced_root_block: false,
                            browser_spellcheck: true,
                         //   valid_children: '+span[div]',
                         //   extended_valid_elements: 'span[*]',
                            plugins: 'legacyoutput,autoresize,noneditable,paste,nonbreaking,lists,advlist,textcolor',
                            //plugins: 'legacyoutput,autoresize,noneditable',
                            //pagebreak_separator: '<br style="page-break-before: always; clear: both;">',
                    //      cleanup_on_startup: true,
                   //       trim_span_elements: false,
                   //       verify_html: false,
                   //       cleanup: false,
                        //    convert_urls: false,
                            content_style: "input { font-family: inherit; border: none; background-color: lightgray; width:auto} .page-break{border-top: dotted 1px;}",
                            removed_menuitems: 'newdocument',
                            //force_br_newlines: true,
                            //force_p_newlines: true,
                        //    forced_root_block: false,
                 //      remove_linebreaks: true,
                 //      convert_newlines_to_br: true,
                            paste_as_text: true, //!important,
                            paste_block_drop: true,
                            paste_preprocess: function (plugin, args) {

                                args.content = args.content.replace(new RegExp('<br>', 'g'), '<p/>');

                            },
                            paste_postprocess: function (plugin, args) {
                                args.node.innerHTML = args.node.innerHTML.replace(new RegExp('<br />', 'g'), "<p/>");
                                // console.log(args.node);
                            },
                            nonbreaking_force_tab: true,
                            paste_data_images: true,
                            //force_br_newlines: false,
                            force_p_newlines: true,
                            // paste_as_text: true
                            content_style: "body { overflow-y: auto !important; }",
                        }}

                    />
                </Grid>
            </Grid>
        )
    }
}