import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import UserDataAccess from '../services/userDataAccess';
import Auth from '../services/auth';
import Button from '@material-ui/core/Button';


const dataAccess = new UserDataAccess();
const auth = new Auth();

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function TranscriptionDetails(props) {
    const classes = useStyles();
    //const auth = new Auth();
    const [details, setDetails] = React.useState({ Id: "", JobId: "", ProcedureDescription: "", WorkType: "", WorkTypeDescription: "", DictEnd: ""});
    //const userHasUpdatePermission = auth.isInRole('SNChartGlobalAdmin') || auth.isInRole('SNChartSuperUser');


    useEffect(() => {
        async function fetchData() {
            dataAccess.getTranscriptionDetails(props.account, props.caseId).then(function (detailsData) {

                if (detailsData !== null && detailsData.length > 0) {
                    setDetails(detailsData[0]);
                }
            });
        }
        fetchData();
    }, []);



    return (
        <Grid container className={classes.root} spacing={2}>

            <Grid item xs={12}>
                <Paper className={classes.root}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="jobId"
                            label="Job Id"
                            className={classes.textField}
                            margin="normal"
                            name="confidenceScore"
                            variant="outlined"
                            value={details.JobId}
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                                disabled={false}
                                margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="procedureDescription"
                            label="Procedure Description"
                            className={classes.textField}
                            margin="normal"
                            name="confidenceScore"
                            variant="outlined"
                            value={details.ProcedureDescription}
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                                disabled={false}
                                margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="workType"
                            label="Work Type"
                            className={classes.textField}
                            margin="normal"
                            name="confidenceScore"
                            variant="outlined"
                            value={details.WorkType}
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                                disabled={false}
                                margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="confidenceThreshold"
                            label="Work Type Description"
                            className={classes.textField}
                            margin="normal"
                            name="confidenceScore"
                            variant="outlined"
                            value={details.WorkTypeDescription}
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                                disabled={false}
                                margin="dense"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            id="dictEnd"
                            label="Dictation End"
                            className={classes.textField}
                            margin="normal"
                            name="confidenceScore"
                            variant="outlined"
                            value={details.DictEnd}
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                                disabled={false}
                                margin="dense"
                        />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

        </Grid>
    );
}