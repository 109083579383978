import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Auth from '../services/auth';
import LastImportDateDataAccess from '../services/lastImportDateDataAccess';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { AgGridReact } from 'ag-grid-react';
import ProgressIndicator from './ProgressIndicator';
import CheckIconCellRenderer from './CheckIconCellRenderer.js';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const lastImportDateDataAccess = new LastImportDateDataAccess();
const auth = new Auth();

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(1),
    }),
    inputLabel: {
        paddingLeft: 2,
        marginLeft: 18,
        marginTop: -10,
        width: '100%',
    },
    formControl: {
        width: 150,
        paddingLeft: 5,
    },
});

class LastImportDate extends Component {

    displayName = LastImportDate.name

    constructor(props) {
        super(props);

        this.state = {
            systemNames: [],
            systemName: "",
            data: [],
            isLoading: false,
        };

        let self = this;

        lastImportDateDataAccess.getLastImportDateSystemNames().then(function (rs) {
            if (rs) {
                self.setState({ systemNames: rs });
                self.setState({ systemName: rs[0].System });
                self.loadImportReportDatesPerSystem();
            }        
        });

        this.gridRef = React.createRef();
    }


    loadDataIntoGrid() {
        var colDefs = [];
        var keys = Object.keys(this.state.data[0]);

        //keys.forEach(key => colDefs.push({ field: key, cellRenderer: CheckIconCellRenderer }));

        keys.forEach((key) => {
            if (key === 'FacilityID' || key.includes("_Date")) {
                colDefs.push({ field: key, hide: 'true'  })
            }
            else if (key === 'FacilityName'){
                colDefs.push({ field: key})
            }
            else {
                var keyDate = key + '_Date';
                colDefs.push({ field: key, cellRenderer: CheckIconCellRenderer, tooltipField: keyDate})
            }
        });

        this.gridRef.current.api.setColumnDefs(colDefs);

        this.gridRef.current.api.setRowData(this.state.data);
//        console.log(colDefs);
    }

    loadImportReportDatesPerSystem() {
        var self = this;
        self.setState({ isLoading: true });
        lastImportDateDataAccess.getLastImportDatesDynamically(this.state.systemName).then(function (reportData) {
            self.setState({ data: reportData, isLoading: false });
            self.loadDataIntoGrid();
        });
    }

    onSystemChange = () => event => {
        this.setState({
            systemName: event.target.value
        }, () => {
            sessionStorage.setItem('systemName', this.state.systemName);
            this.loadImportReportDatesPerSystem();
        });
    }
    
    render() {

        const { classes } = this.props;

        const columnDefs = [];
        const defaultColDef = { resizable: true};

        return (
            <div>
                {/*Progress indicator component*/}

                <ProgressIndicator showProgressIndicator={this.state.isLoading} progressTitle={'Please wait...'} progressText={'Loading data...'} />

                {/*Grid component container*/}

                <div className="ag-theme-alpine" style={{ height: '75vh', width: '100%' }}>
                    <h2>Import Last Date</h2>

                    {/*Dropdown component*/}

                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                id="System"
                                className={classes.inputLabel}>System</InputLabel >
                            <Select
                                value={this.state.systemName}
                                onChange={this.onSystemChange()}
                                id="System"
                                labelId="System"
                                variant="outlined"
                                margin="dense"
                                label="System"
                                fullWidth
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {this.state.systemNames.map((option) => (
                                    <MenuItem key={option.System} value={option.System}>{option.System}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div style={{ height: 10 }} />

                    {/*The grid*/}
                    <div className="ag-theme-alpine" style={{ height: '75vh', width: '100%' }}>
                        <AgGridReact
                            ref={this.gridRef}
                            rowData={this.state.data}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={false}
                            suppressRowClickSelection={true}
                            suppressMovableColumns={true}
                            onGridReady={
                                this.sortGrid
                            }
                        />
                    </div>
                </div>
                <div style={{ height: 80 }} />
            </div>
            );
    }
}

export default withStyles(styles, { withTheme: true })(LastImportDate);