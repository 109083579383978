import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import UserDataAccess from '../services/userDataAccess';
import Auth from '../services/auth';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PubSub from 'pubsub-js';
import Snackbar from '@material-ui/core/Snackbar';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import WorklistDataAccess from '../services/workListDataAccess';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InputLabel } from '@material-ui/core';
import CatalogsDataAccess from '../services/catalogsDataAccess';
import ProgressIndicator from './ProgressIndicator';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const dataAccess = new UserDataAccess();
const workListDataAccess = new WorklistDataAccess();
const catalogDataAccess = new CatalogsDataAccess();
const auth = new Auth();

const filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        if (cellValue == null) {
            return 0;
        }
        var dateParts = cellValue.split('/');
        var year = Number(dateParts[2]);
        var month = Number(dateParts[0]) - 1;
        var day = Number(dateParts[1]);
        var cellDate = new Date(year, month, day);

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        } else {
            return 0;
        }
    },
    buttons: ['clear'],
    browserDatePicker: true,
    inRangeInclusive: true,
};

const filterParamsCurrency = {
    buttons: ['clear'],
    filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange', 'blank', 'notBlank'],
    inRangeInclusive: true,
};

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(1),
    }),
    inputLabel: {
        paddingLeft: 2,
        marginLeft: 18,
        marginTop: -10,
        width: '100%',
    },
    formControl: {
        width: 150,
        paddingLeft: 5,
    },
});

function cellStyleFirstColumn(params) {
    if (params.data.Actioned) {
        return {
            borderLeftWidth: '2px',
            borderLeftColor: '#72ff00',
            backgroundColor: '#f0f0f0',
        };
    } else {
        return {
            borderLeftWidth: '2px',
            borderLeftColor: '#ff0000',
        };
    }
};

function cellStyle(params) {
    if (params.data.Actioned) {
        return {
            backgroundColor: '#f0f0f0',
        };
    }
};

class UserMenuActions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            open: false,
        };

    }

    onOpenMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    onCloseMenu = (event) => {
        this.setState({ anchorEl: null });
    }


    render() {

        const menuId = `extended-options-${this.props.item.Id}`;
        const { item } = this.props;


        return <span><IconButton aria-label="More" variant="contained" color="primary"
            aria-owns={this.state.anchorEl ? menuId : null}
            aria-haspopup="true"
            onClick={this.onOpenMenu}>

            <MoreHorizIcon />
        </IconButton>
            <Menu id={menuId}
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.onCloseMenu}>

                {(auth.isInRole('Admin')) &&

                    <MenuItem key="confirmCode" disabled={(!(item.IdentityMode !== 1 && !item.IsVerified && item.VerificationCode != null))} onClick={(e) => this.props.sendIdentityConfirmation(item)} >Re-Send Confirmation Email</MenuItem>
                }

                {((auth.isInRole('FacilityAdmin'))) &&

                    <MenuItem key="passwordReset" onClick={(e) => this.props.sendPasswordReset(item)}>Password Reset / Unlock Account </MenuItem>
                }

                {((auth.isInRole('Admin'))) &&

                    <MenuItem key="resetConfirmation" disabled={(!item.IdentityMode === 1)} onClick={(e) => this.props.resetConfirmation(item)}>Reset Confirmation / Update Email </MenuItem>
                }

            </Menu></span>

    }
}

class Worklist extends Component {
    displayName = Worklist.name

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            statusFilters: [],
            isLoading: false,
            openSnack: false,
            snackMessage: "",
            btnActionedState: true,
            tickler: auth.getTickler() ? auth.getTickler() : 1,
            actioned: "0",
            actionedTypes: [],
            ticklerTypes: [],
            isThereData: true,
        };

        let self = this;

        catalogDataAccess.getActionedCatalog().then(function (rs) {
            self.setState({ actionedTypes: rs });
        });

        catalogDataAccess.getTicklerCatalog().then(function (rs) {
            self.setState({ ticklerTypes: rs });
        });

        this.gridRef = React.createRef();
    }

    loadWorkingList() {

        var self = this;
        var isData = true;

        self.setState({ isLoading: true });

             workListDataAccess.getWorkinglistWithFilters(this.state.tickler, this.state.actioned).then(function (reportData) {
                if (reportData) {
                    if (reportData.length > 0) {
                        isData = false;
                    }
                }
                self.setState({ data: reportData, isLoading: false, isThereData: isData });
            });
        
    }

    accountChanged(msg, data) {
        this.loadWorkingList();
    }

    componentWillMount() {

        
        this.loadWorkingList();

        // This is where we subscribe this class to the 'GET FILES' subscription.
        // once a publish event for 'GET FILES' has been fired, FileList.subscriber() will be triggered.
        this.token = PubSub.subscribe('AccountChanged', this.accountChanged.bind(this));
    }
    componentWillUnmount() {
        
        PubSub.unsubscribe(this.token);
    }

    onAddUser = () => {

        this.props.history.push(`/userDetails/${auth.getActiveAccount()}/0`);
    }

    sendPasswordReset = (item) => {
        var self = this;
        dataAccess.requestPasswordReset(item.Email).then(function (r) {
            self.setState({ openSnack: true, snackMessage: "Password Reset Email Sent" });
        });
    }

    resetConfirmation = (item) => {
        var self = this;
        dataAccess.resetConfirmation(item.Account, item.Id).then(function (r) {
            self.setState({ openSnack: true, snackMessage: "Confirmation / Email Reset" });
        });
    }

    sendIdentityConfirmation = (item) => {

        var self = this;
        dataAccess.SendIdentityEmailConfirmation(item.Account, item.Id).then(function (r) {
            self.setState({ openSnack: true, snackMessage: "Confirmation Email Sent" });
        });
    }

    renderIdentityConfirmed(item) {


        if ((auth.isInRole('Admin')) && (item.IdentityMode !== 1 && !item.IsVerified && item.VerificationCode != null)) {
            return (<div key={item.Id}>
                <div style={{ fontWeight: 'bold' }}>Pending (Code: {item.VerificationCode})</div>

            </div>);
        }
        else {
            if (item.IdentityMode !== 1 && !item.IsVerified) {
                if (item.VerificationCode == null) {
                    return (<div key={item.Id}><span>Not Requested</span></div>);
                }
                else {
                    return (<div key={item.Id}><span>Pending</span></div>);
                }
            }
            else {
                if (item.IdentityMode !== null) {
                    return (<div key={item.Id}><span>Confirmed</span></div>);
                }
                else {
                    return (<div key={item.Id}><span>Not Requested</span></div>);
                }
            }
        }
    }

    handleSnackClose = () => {
        this.setState({ openSnack: false, snackMessage: "" });
    };

    onSelectionChanged = () => {
        var self = this;
        const selectedRows = this.gridRef.current.api.getSelectedNodes();
        self.setState({ btnActionedState: selectedRows.length > 0 ? false : true});
    };

    markAsActioned = () => {
        var self = this;
        const selectedRows = this.gridRef.current.api.getSelectedNodes();

        self.setState({ showProgressIndicator: true, progressText: "Marking cases..." });
        var selectedIds = `${selectedRows[0].data.CaseId}`;

        for (var i = 1; i < selectedRows.length; i++) {
            selectedIds += "," + `${selectedRows[i].data.CaseId}`;
        }

        workListDataAccess.markAsActioned(selectedIds)
            .then(function (rs) {
                self.setState({ openSnack: true, snackMessage: "Case marked as actioned", showProgressIndicator: false, progressText: "", btnActionedState: true }, () => {
                    self.loadWorkingList();
                });
            });
    };

    markAsNotActioned = () => {
        var self = this;
        const selectedRows = this.gridRef.current.api.getSelectedNodes();

        self.setState({ showProgressIndicator: true, progressText: "Marking cases..." });
        var selectedIds = `${selectedRows[0].data.CaseId}`;

        for (var i = 1; i < selectedRows.length; i++) {
            selectedIds += "," + `${selectedRows[i].data.CaseId}`;
        }

        workListDataAccess.markAsNotActioned(selectedIds)
            .then(function (rs) {
                self.setState({ openSnack: true, snackMessage: "Case marked as not actioned", showProgressIndicator: false, progressText: "", btnActionedState: true }, () => {
                    self.loadWorkingList();
                });
            });
    };

    onBtnExport = () => {
        var parameters = { allColumns: true};
        this.gridRef.current.api.exportDataAsCsv(parameters);
    };

   /* sortGrid = () => {
        this.gridRef.current.columnApi.applyColumnState({
            state: [
                {
                    colId: 'NoteDate',
                    sort: 'desc'
                }
            ],
        })
    };*/

    currencyFormatter(currency) {
        if (currency == null)
        return "";

        var sansDec = currency.toFixed(2);
        var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return '$' + `${formatted}`;
    }

    onTicklerChange = () => event => {
        this.setState({
            tickler: event.target.value
        }, () => {
            sessionStorage.setItem('tickler', this.state.tickler);
            this.loadWorkingList();
        });
    }

    onActionedChanged = () => event => {
        var self = this;
        self.setState({
            actioned: event.target.value
        }, () => {
            this.loadWorkingList();
        });
    }

    onFilterChanged(params) {
       // let filterModel = params.api.getFilterModel();
       // localStorage.setItem('ag-grid-persistane-filtering', JSON.stringify(filterModel));
    }

    clearAllFilters = (params) => {
        this.gridRef.current.api.setFilterModel(null);
    };

    onFirstDataRendered(params) {
        //const filterModel = localStorage.getItem('ag-grid-persistane-filtering');

        if (this.props.match.params.client && this.props.match.params.client != "") {
            params.api.setFilterModel({
                Client: {
                    filterType: 'text',
                    type: 'startsWith',
                    filter: this.props.match.params.client
                }
            });
        }
        //
    }

    dateFormatter2 = (dateAsString) => {
        var dateParts = dateAsString.split('/');
        return `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
    }

   dateComparator = (date1, date2) => {
        var date1Number = this.monthToNum(date1);
        var date2Number = this.monthToNum(date2);

        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }

        return date1Number - date2Number;
    }

    monthToNum = (date) => {
        if (date === undefined || date === null || date.length !== 10) {
            return null;
        }

        var yearNumber = date.substring(6, 10);
        var dayNumber = date.substring(3, 5);
        var monthNumber = date.substring(0, 2);

        var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
        // 29/08/2004 => 20040829
        return result;
    }

    render() {

        const { classes } = this.props;

        const {openSnack, snackMessage } = this.state;

        const columnDefs = [
            { field: 'ID', hide: 'true' },
            {
                field: 'Client', sortable: true, filter: true, resizable: true, headerCheckboxSelection: true, checkboxSelection: true
                , cellStyle: cellStyleFirstColumn
            },

            { field: 'Collector', sortable: true, filter: true, resizable: true, cellStyle: cellStyle},
            {
                headerName: 'Account Number', field: 'CaseId', sortable: true, filter: true, resizable: true, cellStyle: cellStyle
                , cellRenderer: function (params)
                {
                    return <Link to={`/worklistDetails/${params.data.Account}/${params.data.CaseId}/${params.data.ID}`}> {params.data.CaseId}</Link>
                }
            },
            { headerName: 'Patient Name', field: 'PatientName', sortable: true, filter: true, resizable: true, cellStyle: cellStyle},
            { field: 'DOS', comparator: this.dateComparator, sortable: true, filter: 'agDateColumnFilter', resizable: true, cellStyle: cellStyle, width: 116, filterParams: filterParams },
            { headerName: 'Billed Charges', field: 'Charges', valueFormatter: params => this.currencyFormatter(params.data.Charges), sortable: true, filter: 'agNumberColumnFilter', resizable: true, cellStyle: cellStyle, width: 144, filterParams: filterParamsCurrency },
            { headerName: 'Lst Pmt Amount', field: 'LstPmtAmount', valueFormatter: params => this.currencyFormatter(params.data.LstPmtAmount), sortable: true, filter: 'agNumberColumnFilter', resizable: true, cellStyle: cellStyle, width: 144, filterParams: filterParamsCurrency },
            { field: 'DateBilled', hide: 'true' },
            { field: 'Contract Fee', hide: 'true' },
            { field: 'Payments', valueFormatter: params => this.currencyFormatter(params.data.Payments), sortable: true, filter: 'agNumberColumnFilter', resizable: true, cellStyle: cellStyle, width: 144, filterParams: filterParamsCurrency },
            { headerName: 'Account Balance', field: 'Balance', valueFormatter: params => this.currencyFormatter(params.data.Balance), sortable: true, filter: 'agNumberColumnFilter', resizable: true, cellStyle: cellStyle, width: 144, filterParams: filterParamsCurrency },
            { field:'Status', hide:'true'},
            { field: 'Age', sortable: true, filter: 'agNumberColumnFilter', resizable: true, cellStyle: cellStyle, width: 80, filterParams: filterParamsCurrency },
            { field: 'Visit Category', hide: 'true'},
            { headerName: 'Fin Class', field: 'FinClass', sortable: true, filter: true, resizable: true, cellStyle: cellStyle},
            { field: 'Payer', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            { field: 'LstPmtDate', hide: 'true' },
            { field: 'LstSPPmtDate', hide: 'true' },
            { field: 'LstSPPmtAmount', hide: 'true' },
            { field: 'Date of Birth', hide: 'true' },
            { field: 'Policy Number', hide: 'true' },
            { field: 'Group Number', hide: 'true' },
            { field: 'FacilityId', hide: 'true' },
            { headerName: 'Status Code', field: 'StatusCode', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            { headerName: 'Classification', field: 'Classification', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },

            { headerName: 'Note Date', comparator: this.dateComparator, field: 'NoteDate', sortable: true, filter: 'agDateColumnFilter', resizable: true, cellStyle: cellStyle, width: 144, filterParams: filterParams },

            { field: 'Notes', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            { headerName: 'Target Date', comparator: this.dateComparator, field: 'TargetDate', sortable: true, filter: 'agDateColumnFilter', resizable: true, cellStyle: cellStyle, width: 144, filterParams: filterParams },
            { headerName: 'Nxt Wk Date', comparator: this.dateComparator, field: 'NxtWkDate', sortable: true, filter: 'agDateColumnFilter', resizable: true, cellStyle: cellStyle, width: 144, filterParams: filterParams },
            { field: 'Create By', hide: 'true' },
            { field: 'Follow up', hide: 'true' },
            { field: 'Tickler', hide: 'true' },
            { field: 'SPStatus', hide: 'true' },
            { field: 'StmtsSent', hide: 'true' },
            { field: 'Systm', hide: 'true' },
            { field: 'LstUpdate', hide: 'true' },
            { field: 'Actioned', hide: 'true' },
            { field: 'TeamStatusId', hide: 'true' },
        ]; 

        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />

                <ProgressIndicator showProgressIndicator={this.state.isLoading} progressTitle={'Please wait...'} progressText={'Loading data...'} />

                <div className="ag-theme-alpine" style={{ height: '75vh', width: '100%' }}>
                    <h2>Worklist</h2>
                    <div>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button variant="contained" color="primary" disabled={this.state.btnActionedState} onClick={this.markAsActioned}> Mark as Actioned</Button >
                            <Button variant="contained" color="secondary" disabled={this.state.btnActionedState} onClick={this.markAsNotActioned}> Mark as not Actioned</Button >
                            <Button variant="contained" color="primary" onClick={this.clearAllFilters}>Reset Filter</Button>
                            <Button variant="contained" color="secondary" disabled={this.state.isThereData} onClick={this.onBtnExport}>Export as CSV</Button>
                        </ButtonGroup>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                id="TicklerTypeId"
                                className={classes.inputLabel}>Work type</InputLabel >
                            <Select
                                value={this.state.tickler}
                                onChange={this.onTicklerChange()}
                                id="TicklerTypeId"
                                labelId="TicklerTypeId"
                                variant="outlined"
                                margin="dense"
                                label="Work type"
                                fullWidth
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {this.state.ticklerTypes.map((option) => (
                                    <MenuItem key={option.TicklerId} value={option.TicklerId}>{option.Description}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                id="ActionedId"
                                className={classes.inputLabel}>Actioned</InputLabel >
                            <Select
                                value={this.state.actioned}
                                onChange={this.onActionedChanged()}
                                id="ActionedId"
                                labelId="ActionedId"
                                variant="outlined"
                                margin="dense"
                                label="Actioned"
                                fullWidth
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {this.state.actionedTypes.map((option) => (
                                    <MenuItem key={option.ActionedId} value={option.ActionedId}>{option.Description}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ height: 10 }} />
                    <AgGridReact
                        ref={this.gridRef}
                        rowData={this.state.data}
                        columnDefs={columnDefs}
                        rowSelection={'multiple'}
                        onSelectionChanged={this.onSelectionChanged}
                        suppressColumnVirtualisation={true}
                        suppressRowVirtualisation={true}
                        pagination={true}
                        paginationPageSize={25}
                        suppressRowClickSelection={true}
                        autoSizeColumns={true}
                        onFilterChanged={this.onFilterChanged.bind(this)}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                    />
                </div>
                <div style={{ height: 80 }} />
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(Worklist);
