import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Auth from '../services/auth';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import UserDataAccess from '../services/userDataAccess';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactGA from 'react-ga4';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';

const userDataAccess = new UserDataAccess();

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    }),
    logo: {
        maxWidth: '100%',
        margin: 'auto',
        maxHeight: '40px'
    },
    page: {
        backgroundImage: `url(${"images/login_bg.jpg"})`,
        backgroundSize: 'cover',
        minHeight: 'calc(100vh - 24px)',
    },
    center: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    SNBrand: {
        color: theme.palette.secondary.main
    },
    SNBrandBlue: {
        color: theme.palette.primary.main
    },
    SNBrandRegister: {
        fontSize: 'small'
    }
});




class EnterpriseLogin extends React.Component {


    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.Auth = new Auth();

        this.state = {
            username: this.Auth.getUserLogin() || '',
            password: '',
            updateLogin: false,
            headline: 'SINGLE SIGN ON',
            hasError: false,
            confirmationSent: false,
            loginErrorMessage: 'Email is invalid or not configured.',
            emailcode: '',
            newusername: '',
            activeStep: 0,
            confirmError: '',
            confirmIdentityError: '',
            sendingConfirmation: false,
            confirmingCode: false,
            loggingIn: false
        };
    }

    componentWillMount() {
        this.Auth.signOut(this.Auth.isAuthenticated());
        this.setState({ username: this.Auth.getSSOIdentity() });
    }
    
    isStepOptional = step => {
        return step === 1;
    };

    submit = (event) => {
        event.preventDefault();
        this.login();
    };

  
    
    handleChange = field => event => {

        const { target: { value } } = event;
        this.setState({ [field]: value });
    }

    login() {
        debugger;
        var history = this.props.history;
        var self = this;
        self.setState({ loggingIn: true });
        this.Auth.getSSOConfig(this.state.username)
            .then(function (response) {
                self.setState({ loggingIn: false });
                window.location = `/api/fedauth?scheme=${response.Scheme}`;
            }).catch(error => {
                self.setState({ loggingIn: false });
                let errorMessage = self.state.loginErrorMessage;

                if (error.response && error.response.data && error.response.data.error_description) {
                    if (error.response.data.error_description === "AccountLocked") {
                        errorMessage = "Maximum login attempts exceeded.  Use the Forgot username or password link to reset your password."
                    }
                    else if (error.response.data.error_description === "AccountHold") {
                        errorMessage = "Your access has been placed on hold. Please contact Surgical Notes accounting at (800) 459-5616 ext 129 for further instruction."
                    }
                }
                self.setState({ hasError: true, loginErrorMessage: errorMessage });
            });
    }

    render() {
        const { classes } = this.props;
        const {  loggingIn } = this.state;

        return (<div className={classes.page}>
            <Grid container spacing={6} justify="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.root} elevation={4}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item>
                                        <Typography variant="h3" color="inherit" noWrap>
                                            <span className={classes.SNBrand}>RCM</span><span className={classes.SNBrandBlue}>brain</span><span className={classes.SNBrandRegister}>&#174;</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item>
                                        <Typography variant="h5">
                                            {this.state.headline}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!this.state.updateLogin && <Grid item xs={12}>
                                <form onSubmit={this.submit}>
                                    <TextField fullWidth
                                        name="txtUserName"
                                        id="txtUserName"
                                        label="Email"
                                        required
                                        onChange={this.handleChange('username')}
                                        error={this.state.hasError}
                                        value={this.state.username}
                                    />
                                    {this.state.hasError && <FormHelperText id="name-error-text" error>{this.state.loginErrorMessage}</FormHelperText>}
                                </form>
                            </Grid>}

                           
                           
                            {!this.state.updateLogin && <Grid item xs={12}>
                                <Grid container spacing={6} justify="center">
                                    <Grid item>
                                        <div className={classes.wrapper}>
                                            <Button disabled={loggingIn} variant="contained" onClick={() => { this.login() }}>Continue</Button>
                                            {loggingIn && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>}

                             <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item>
                                        <Link to='/login/1' className={classes.link}>
                                            <Typography align="center" variant="body2" color="primary" gutterBottom>
                                                Return to classic login
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item xs={12} className={classes.center}>
                                        <img className={classes.logo} src='/images/SNLogo.png' alt='Surgical Notes' />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
        </div>);
    }

}

export default withStyles(styles)(EnterpriseLogin);