import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ReactGA from 'react-ga4';
import Auth from '../services/auth';

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    }),
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    incompleteCard: {
        minWidth: 240,
        height: 145,
        borderLeft: '6px solid red'
    },
    openCard: {
        minWidth: 240,
        height: 145,
        borderLeft: '6px solid yellow'
    },
    completeQuestionsCard: {
        minWidth: 240,
        height: 145,
        borderLeft: '6px solid blue'
    }
    , completeCard: {
        minWidth: 240,
        height: 145,
        borderLeft: '6px solid green'
    },
    tatCard: {
        height: 250
    },
    tatCardReason: {
        height: 75
    }
});


class LoginHelp extends Component {
    displayName = LoginHelp.name
    
    onClassicView = () => {
        const auth = new Auth();
        ReactGA.event({
            action: 'Classic Portal',
            category: `${auth.getActiveAccount()}-${auth.getUserId()}`
        });
    }

    render() {

        return (
            <div style={{margin:8}}>

                <Card>
                    <CardContent>

                        <Grid container spacing={3} justify='space-between'>
                       
                            <Grid item xs={12} md={8}>
                                <Typography variant="h5">Logging in for the First Time</Typography>
                                <div style={{ position: "relative", height: 0, overflow: "hidden", maxWidth: "100%", paddingBottom: "56.25%" }}>

                                    <iframe title="Logging in for the First Time"
                                        style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                                        src="https://www.youtube.com/embed/yJmSumVTHAw?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                                </div>
                            </Grid>

                           
                            
                            <Grid item xs={12} lg={4} md={4}>

                                <List>

                                    <Typography variant="h5" gutterBottom>
                                        Contact
                                    </Typography>

                                    <ListItem button component="a" target="_blank" href="mailto:AcctMgrs@surgicalnotes.com">
                                        <ListItemText
                                            primary="Account Manager Team"
                                        />
                                    </ListItem>
                                    <ListItem button component="a" target="_blank" href="tel:1-800-459-5616">
                                        <ListItemText
                                            primary="(800) 459-5616"
                                        />
                                    </ListItem>

                                    <ListItem button component="a" target="_blank" href="/login">
                                        <ListItemText
                                            primary="Login"
                                        />
                                    </ListItem>
                                 
                                </List>

                            </Grid>
                     
                     
                        </Grid>

                    </CardContent>
                </Card>





            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(LoginHelp);
