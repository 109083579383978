import axios from 'axios';
import Auth from './auth';
import moment from 'moment';
import { API_ROOT } from './apiConfig';

export default class LastImportDateDataAccess {

    getLastImportDatesDynamically(systemName) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetLastImportDatesDynamically", parameters: { system: systemName}
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    //console.log(response);
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getLastImportDateSystemNames() {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetLastImportDateSystemNames"
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    //console.log(response);
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }    
}