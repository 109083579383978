import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import PubSub from 'pubsub-js';
import * as React from 'react';
import Auth from '../services/auth';
import EnumLookup from '../services/enumLookup';
import ScheduleDataAccess from '../services/scheduleDataAccess';
import UserDataAccess from '../services/userDataAccess';

const dataAccess = new ScheduleDataAccess();
const userDataAccess = new UserDataAccess();
const enumLookup = new EnumLookup();
const auth = new Auth();


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    paperReports: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        height: '100%',
        maxHeight: 324,
        overflow: 'auto'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    }
    ,
    textFieldSm: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '50%',
    },
    formControl: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        minWidth: 120,
    }
});

class ScheduleAdd extends React.Component {



    constructor(props) {
        super(props);

        let currentDate = new Date();
        this.state = {
            reports: [],

            availableDictators: [],
            appointment: {
                Id: 0,
                Account: auth.getActiveAccount(),
                AppointmentId: "",
                DateOfBirth: new Date().toJSON().slice(0, 10),
                DateOfService: new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toJSON().slice(0, 19),
                DictatorId: "",
                OperatingRoom: "",
                PatientFirstName: "",
                PatientId: "",
                CaseId: "",
                PatientLastName: "",
                PrimaryPhysican: "",
                PrimaryPhysicanNpi: "",
                Procedure: "",
                ProcedureId: "",
                ReferringPhysician: "",
                ReferringPhysicianNpi: "",
                Reports: []
            },
            openSnack: false,
            snackMessage: "",
            fNameValid: false,
            lNameValid: false,
            patIdValid: false,
            caseIdValid: false,
            phyValid: false,
            formValid: false
        };

        let self = this;
        let accounts = [];
        accounts.push(auth.getActiveAccount())
        self.loadAvailableDictators(accounts);

    }


    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
    };

    componentWillMount() {
        this.token = PubSub.subscribe('AccountChanged', this.accountChanged.bind(this));
        //this.state.appointment.DateOfService = new Date().toJSON().slice(0, 19);
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }
    accountChanged(msg, data) {
        this.props.history.push("/");
    }

    loadAvailableDictators = (accounts) => {

        var self = this;
        userDataAccess.getAvailableDictators(accounts).then(function (dictators) {
            console.log(dictators);
            self.setState({ availableDictators: dictators });
        });
    }

    handlePatientChange = name => event => {

        var appointment = this.state.appointment;
        appointment[name] = event.target.value;

        this.setState({
            appointment: appointment
        }, this.validateField(name, appointment[name]));
    };

    clearForm() {

        let currentDate = new Date();
        this.setState({
            appointment: {
                Id: 0,
                Account: auth.getActiveAccount(),
                AppointmentId: "",
                DateOfBirth: new Date().toJSON().slice(0, 10),
                DateOfService: new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toJSON().slice(0, 19),
                DictatorId: "",
                OperatingRoom: "",
                PatientFirstName: "",
                PatientId: "",
                CaseId: "",
                PatientLastName: "",
                PrimaryPhysican: "",
                PrimaryPhysicanNpi: "",
                Procedure: "",
                ProcedureId: "",
                ReferringPhysician: "",
                ReferringPhysicianNpi: "",
                Reports: []
            }
        });

        this.props.onCancel();
    }

    validateField(fieldName, value) {



        let fNameValid = this.state.fNameValid;
        let lNameValid = this.state.lNameValid;
        let patIdValid = this.state.patIdValid;
        let caseIdValid = this.state.caseIdValid;
        let phyValid = this.state.phyValid;

        switch (fieldName) {
            case 'PatientFirstName':
                fNameValid = value.length >= 1;
                break;
            case 'PatientLastName':
                lNameValid = value.length >= 1;
                break;
            case 'PatientId':
                patIdValid = value.length >= 1;
                break;
            case 'AppointmentId':
                caseIdValid = value.length >= 1;
                break;
            case 'DictatorId':
                phyValid = value.length >= 1;
                break;
            default:
                break;
        }
        this.setState({
            fNameValid: fNameValid,
            lNameValid: lNameValid,
            patIdValid: patIdValid,
            caseIdValid: caseIdValid,
            phyValid: phyValid
        }, this.validateForm);
    }

    validateForm() {
        this.setState({ formValid: this.state.fNameValid && this.state.lNameValid && this.state.patIdValid && this.state.caseIdValid && this.state.phyValid });
        console.log("formValid: " + this.state.formValid);
    }

    onCancel = () => {

        this.props.onCancel();
    }



    onAddAppointment = () => {

        var patient = this.state.appointment;
        var self = this;

        dataAccess.saveAppointmentInfo(patient).then(function (rs) {
            self.clearForm();
            self.props.onSaveComplete();
        });


    }

    render() {

        const { classes, open } = this.props;
        const { openSnack, snackMessage } = this.state;
        return (
            <div>
                <Dialog open={open} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add New Schedule Item</DialogTitle>
                    <DialogContent>

                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            open={openSnack}
                            autoHideDuration={3000}
                            onClose={this.handleSnackClose}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{snackMessage}</span>}
                        />
                        <Grid container spacing={1}>
                            <Grid item xs={12}>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="fName"
                                                    label="First Name"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onChange={this.handlePatientChange('PatientFirstName')}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="lName"
                                                    label="Last Name"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onChange={this.handlePatientChange('PatientLastName')}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Patient ID"
                                                    className={classes.textField}
                                                    onChange={this.handlePatientChange('PatientId')}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Case ID"
                                                    className={classes.textField}
                                                    onChange={this.handlePatientChange('AppointmentId')}
                                                    margin="normal"
                                                />
                                            </Grid>


                                            <Grid item xs={12}>
                                                <TextField
                                                    type="date"
                                                    label="Date of Birth"
                                                    className={classes.textField}
                                                    value={this.state.appointment.DateOfBirth}
                                                    margin="normal"
                                                    onChange={this.handlePatientChange('DateOfBirth')}
                                                />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    type="datetime-local"
                                                    label="Date of Service"
                                                    className={classes.textField}
                                                    value={this.state.appointment.DateOfService}
                                                    margin="normal"
                                                    onChange={this.handlePatientChange('DateOfService')}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Operating Room"
                                                    className={classes.textField}
                                                    onChange={this.handlePatientChange('OperatingRoom')}
                                                    margin="normal"
                                                />
                                            </Grid>

                                            <FormControl className={classes.formControl} fullWidth>
                                                <InputLabel htmlFor="DictatorId">Physician</InputLabel>
                                                <Select
                                                    value={this.state.appointment.DictatorId}
                                                    onChange={this.handlePatientChange('DictatorId')}
                                                    name="DictatorId"
                                                    displayEmpty


                                                >
                                                    {this.state.availableDictators.map(s => {
                                                        return <MenuItem value={s.DictatorId}>{s.Name}</MenuItem>
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>


                                    </Grid>


                                </Grid>

                            </Grid>


                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.onCancel} color="primary">Cancel</Button>

                        <Grid item>
                            <Button variant="contained" onClick={this.onAddAppointment} color="primary" disabled={!this.state.formValid}>Add</Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ScheduleAdd);