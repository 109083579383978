import React from 'react';
import PropTypes from 'prop-types';
import workListDataAccess from '../services/workListDataAccess';
import Auth from '../services/auth';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom'

const dataAccess = new workListDataAccess();
const auth = new Auth();

const styles = theme => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
});

class DocumentList extends React.Component {

    constructor(props) {
        super(props);

        //console.log(this.props.account);

        this.state = {
            documentData: []
        };

        let self = this;

        //console.log("first?");
        //console.log(this.props.caseId);

        dataAccess.getRelatedDocuments(this.props.account, this.props.caseId).then(    
            function (docData) {
                self.setState({ documentData: docData });
            });
    }
    
    render() {        
        const { account, caseId } = this.props;
        return (
            <List>
                

                {(this.state.documentData && this.state.documentData.length > 0) && this.state.documentData.map(report => {
                    return (<ListItem button onClick={(e) => this.props.history.push(`/reportDetails/${report.Account}/${report.Id}`)}>
                        <Avatar style={{ backgroundColor: "#17c92c", height: '24px', width: '24px' }}>
                            &nbsp;
                        </Avatar>
                        &nbsp;&nbsp;<ListItemText primary={`JobId: ${report.JobId} | ${report.WorkTypeDescription}`} secondary={`ProcedureDescription: ${report.ProcedureDescription}`} />
                    </ListItem>)
                }
                )
                }

                {(!this.state.documentData || this.state.documentData.length === 0) &&
                    <ListItem>
                        <ListItemText primary="No Documents Found" />
                    </ListItem>
                }
            </List>
        )
    }
}

DocumentList.propTypes = {
    classes: PropTypes.object.isRequired,
    caseId: PropTypes.string,
    account: PropTypes.string
}

//export default withStyles(styles, { withTheme: true })(DocumentList);
export default withRouter(withStyles(styles, { withTheme: true })(DocumentList))