import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import UserDataAccess from '../services/userDataAccess';
import Auth from '../services/auth';
import Button from '@material-ui/core/Button';
import PubSub from 'pubsub-js';
import Snackbar from '@material-ui/core/Snackbar';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import WorklistDataAccess from '../services/workListDataAccess';
import CatalogsDataAccess from '../services/catalogsDataAccess';
import ProgressIndicator from './ProgressIndicator';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const dataAccess = new UserDataAccess();
const workListDataAccess = new WorklistDataAccess();
const catalogDataAccess = new CatalogsDataAccess();
const auth = new Auth();

const filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateAsString.split('/');
        const year = Number(dateParts[2]);
        const month = Number(dateParts[0]) - 1;
        const day = Number(dateParts[1]);
        const cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    },
    buttons: ['clear'],
    suppressAndOrCondition: true,
    browserDatePicker: true,
    inRangeInclusive: true,
};

const filterParamsCurrency = {
    buttons: ['clear'],
    suppressAndOrCondition: true,
    filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange', 'blank', 'notBlank']
};

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(1),
    }),
    inputLabel: {
        paddingLeft: 2,
        marginLeft: 18,
        marginTop: -10,
        width: '100%',
    },
    formControl: {
        width: 150,
        paddingLeft: 5,
    },
});

function cellStyleFirstColumn(params) {
    if (params.data.Actioned) {
        return {
            borderLeftWidth: '2px',
            borderLeftColor: '#72ff00',
            backgroundColor: '#f0f0f0',
        };
    } else {
        return {
            borderLeftWidth: '2px',
            borderLeftColor: '#ff0000',
        };
    }
};

function cellStyle(params) {
    if (params.data.Actioned) {
        return {
            backgroundColor: '#f0f0f0',
        };
    }
};


class Ivlist extends Component {
    displayName = Ivlist.name

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            statusFilters: [],
            isLoading: false,
            openSnack: false,
            snackMessage: "",
            btnActionedState: true,
            tickler: auth.getTickler() ? auth.getTickler() : 1,
            actioned: "0",
            actionedTypes: [],
            ticklerTypes: [],
            isThereData: true,
        };

        let self = this;

        catalogDataAccess.getActionedCatalog().then(function (rs) {
            self.setState({ actionedTypes: rs });
        });

        catalogDataAccess.getTicklerCatalog().then(function (rs) {
            self.setState({ ticklerTypes: rs });
        });

        this.gridRef = React.createRef();
    }

    loadWorkingList() {

        var self = this;
        var isData = true;

        self.setState({ isLoading: true });
        workListDataAccess.getIvlistWithFilters().then(function (reportData) {
            if (reportData) {
                if (reportData.length > 0) {
                    isData = false;
                }
            }
            self.setState({ data: reportData, isLoading: false, isThereData: isData });
        });
    }

    accountChanged(msg, data) {
        this.loadWorkingList();
    }

    componentWillMount() {

        this.loadWorkingList();

        // This is where we subscribe this class to the 'GET FILES' subscription.
        // once a publish event for 'GET FILES' has been fired, FileList.subscriber() will be triggered.
        this.token = PubSub.subscribe('AccountChanged', this.accountChanged.bind(this));
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    onAddUser = () => {

        this.props.history.push(`/userDetails/${auth.getActiveAccount()}/0`);
    }

    sendPasswordReset = (item) => {
        var self = this;
        dataAccess.requestPasswordReset(item.Email).then(function (r) {
            self.setState({ openSnack: true, snackMessage: "Password Reset Email Sent" });
        });
    }

    resetConfirmation = (item) => {
        var self = this;
        dataAccess.resetConfirmation(item.Account, item.Id).then(function (r) {
            self.setState({ openSnack: true, snackMessage: "Confirmation / Email Reset" });
        });
    }

    sendIdentityConfirmation = (item) => {

        var self = this;
        dataAccess.SendIdentityEmailConfirmation(item.Account, item.Id).then(function (r) {
            self.setState({ openSnack: true, snackMessage: "Confirmation Email Sent" });
        });
    }

    renderIdentityConfirmed(item) {


        if ((auth.isInRole('Admin')) && (item.IdentityMode !== 1 && !item.IsVerified && item.VerificationCode != null)) {
            return (<div key={item.Id}>
                <div style={{ fontWeight: 'bold' }}>Pending (Code: {item.VerificationCode})</div>

            </div>);
        }
        else {
            if (item.IdentityMode !== 1 && !item.IsVerified) {
                if (item.VerificationCode == null) {
                    return (<div key={item.Id}><span>Not Requested</span></div>);
                }
                else {
                    return (<div key={item.Id}><span>Pending</span></div>);
                }
            }
            else {
                if (item.IdentityMode !== null) {
                    return (<div key={item.Id}><span>Confirmed</span></div>);
                }
                else {
                    return (<div key={item.Id}><span>Not Requested</span></div>);
                }
            }
        }
    }

    handleSnackClose = () => {
        this.setState({ openSnack: false, snackMessage: "" });
    };

    onSelectionChanged = () => {
        var self = this;
        const selectedRows = this.gridRef.current.api.getSelectedNodes();
        self.setState({ btnActionedState: selectedRows.length > 0 ? false : true });
    };

    onBtnExport = () => {
        var parameters = { allColumns: true };
        this.gridRef.current.api.exportDataAsCsv(parameters);
    };

    sortGrid = () => {
        this.gridRef.current.columnApi.applyColumnState({
            state: [
                {
                    colId: 'NoteDate',
                    sort: 'desc'
                }
            ],
        })
    };

    currencyFormatter(currency) {
        var sansDec = Number(currency).toFixed(2);
        var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return '$' + `${formatted}`;
    }

    onTicklerChange = () => event => {
        this.setState({
            tickler: event.target.value
        }, () => {
            sessionStorage.setItem('tickler', this.state.tickler);
            this.loadWorkingList();
        });
    }

    onActionedChanged = () => event => {
        var self = this;
        self.setState({
            actioned: event.target.value
        }, () => {
            this.loadWorkingList();
        });
    }

    onFilterChanged(params) {
        let filterModel = params.api.getFilterModel();
        localStorage.setItem('ag-grid-persistane-filtering', JSON.stringify(filterModel));
    }

    clearAllFilters = (params) => {
        this.gridRef.current.api.setFilterModel(null);
    };

    onFirstDataRendered(params) {
        const filterModel = localStorage.getItem('ag-grid-persistane-filtering');
        params.api.setFilterModel(JSON.parse(filterModel));
    }

    dateFormatter2 = (dateAsString) => {
        var dateParts = dateAsString.split('/');
        return `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
    }

    dateComparator = (date1, date2) => {
        var date1Number = this.monthToNum(date1);
        var date2Number = this.monthToNum(date2);

        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }

        return date1Number - date2Number;
    }

    monthToNum = (date) => {
        if (date === undefined || date === null || date.length !== 10) {
            return null;
        }

        var yearNumber = date.substring(6, 10);
        var dayNumber = date.substring(3, 5);
        var monthNumber = date.substring(0, 2);

        var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
        // 29/08/2004 => 20040829
        return result;
    }


    render() {

        const { openSnack, snackMessage } = this.state;

        const columnDefs = [
            { field: 'Id', hide: 'true' },
            {
                field: 'Client', sortable: true, filter: true, resizable: true, headerCheckboxSelection: true, checkboxSelection: true
                , cellStyle: cellStyleFirstColumn
            },

            { field: 'IVStaff', headerName:'IV Staff', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            {
                headerName: 'Account Number', field: 'CaseId', sortable: true, filter: true, resizable: true, cellStyle: cellStyle
                , cellRenderer: function (params) {
                    return <Link to={`/ivlistDetails/${params.data.FacilityID}/${params.data.Id}`}> {params.data.CaseId}</Link>
                }
            },
            { headerName: 'Patient Name', field: 'PatientName', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            { field: 'DOS', sortable: true, filter: 'agDateColumnFilter', resizable: true, cellStyle: cellStyle, width: 116, filterParams: filterParams },
            { headerName: 'Physician', field: 'PhysName', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            { field: 'Procedure', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            {  field: 'Payer', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            { headerName: 'Payer Name', field: 'PayerName', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            { field: 'Verified', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            { headerName: 'Balance Due', field: 'BalanceDue', valueFormatter: params => this.currencyFormatter(params.data.BalanceDue), sortable: true, filter: 'agNumberColumnFilter', resizable: true, cellStyle: cellStyle, filterParams: filterParamsCurrency },
            { field: 'Authorized', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            { headerName: 'Reference Num', field: 'refNum', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },
            { headerName: 'Import Date', field: 'ImportDate', sortable: true, filter: true, resizable: true, cellStyle: cellStyle },

        ];

        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />

                <ProgressIndicator showProgressIndicator={this.state.isLoading} progressTitle={'Please wait...'} progressText={'Loading data...'} />

                <div className="ag-theme-alpine" style={{ height: '75vh', width: '100%' }}>
                    <h2>IV List</h2>
                    <div>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                           
                            <Button variant="contained" color="primary" onClick={this.clearAllFilters}>Clear Filter</Button>
                            <Button variant="contained" color="secondary" disabled={this.state.isThereData} onClick={this.onBtnExport}>Export as CSV</Button>
                        </ButtonGroup>
                    
                    </div>
                    <div style={{ height: 10 }} />
                    <AgGridReact
                        ref={this.gridRef}
                        rowData={this.state.data}
                        columnDefs={columnDefs}
                        rowSelection={'multiple'}
                        onSelectionChanged={this.onSelectionChanged}
                        suppressColumnVirtualisation={true}
                        suppressRowVirtualisation={true}
                        pagination={true}
                        paginationPageSize={25}
                        suppressRowClickSelection={true}
                        autoSizeColumns={true}
                        onGridReady={
                            this.sortGrid
                        }
                        onFilterChanged={this.onFilterChanged.bind(this)}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                    />
                </div>
                <div style={{ height: 80 }} />
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(Ivlist);
