import axios from 'axios';
import Auth from '../services/auth';
import { API_ROOT } from './apiConfig';

export default class WorkListDataAccess {

    getAccounts() {

        const auth = new Auth();

        return auth.getUserAccounts();
    }
    getIvlist() {

        const auth = new Auth();

        let params;
        let showMyItems = auth.getShowMyItems();
        var selectedClients = this.getSelectedClients();

    
        params = { userGroups: auth.getAddGroupIds() };

        if (selectedClients) {
            params.clients = selectedClients;
        }

        if (showMyItems) {
            params.userid = auth.getAddUserId();
        }


        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetIvlist", parameters: params
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    //console.log("response from procedure here");
                    //console.log(response);

                    const users = response.data.map((user, i) => {
                        const result = Object.assign({}, user,
                            {
                                Id: user.Id,
                                Client: user.Client,
                                FacilityID: user.FacilityID
                                , FacilityName: user.FacilityName
                                , IVStaff: user.IVStaff
                                , DOS: user.DOS
                                , PtIDVst: user.PtIDVst
                                , PtNameAcct: user.PtNameAcct
                                , PhysName: user.PhysName
                                , Procedure: user.Procedure
                                , Payer: user.Payer
                                , PayerName: user.PayerName
                                , Verified: user.Verified
                                , BalanceDue: user.BalanceDue
                                , Authorized: user.Authorized
                                , refNum: user.refNum
                                , ImportDate: user.ImportDate
                            });
                        return result;
                    });

                    resolve(users);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getIvlistWithFilters() {

        const auth = new Auth();

        let params;
        let showMyItems = auth.getShowMyItems();
        var selectedClients = this.getSelectedClients();


        params = { userGroups: auth.getAddGroupIds() };

        if (selectedClients) {
            params.clients = selectedClients;
        }

        if (showMyItems) {
            params.userid = auth.getAddUserId();
        }


        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetIvlistWithFilters", parameters: params
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    //console.log("response from procedure here");
                    //console.log(response);

                    const users = response.data.map((user, i) => {
                        const result = Object.assign({}, user,
                            {
                                Id: user.Id,
                                Client: user.Client,
                                FacilityID: user.FacilityID
                                , FacilityName: user.FacilityName
                                , IVStaff: user.IVStaff
                                , DOS: user.DOS
                                , PtIDVst: user.PtIDVst
                                , PtNameAcct: user.PtNameAcct
                                , PhysName: user.PhysName
                                , Procedure: user.Procedure
                                , Payer: user.Payer
                                , PayerName: user.PayerName
                                , Verified: user.Verified
                                , BalanceDue: user.BalanceDue
                                , Authorized: user.Authorized
                                , refNum: user.refNum
                                , ImportDate: user.ImportDate
                            });
                        return result;
                    });

                    resolve(users);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getWorkinglistWithFilters(ticklerType, actionedType) {

        const auth = new Auth();

        let params;
        let showMyItems = auth.getShowMyItems();
        var selectedClients = this.getSelectedClients();

        console.log('selected clients: ' + selectedClients);

        params = { ticklerType: ticklerType, actionedType: actionedType, userGroups: auth.getAddGroupIds() };

        if (selectedClients) {
            params.clients= selectedClients;
        }

        if (showMyItems) {
            params.userid= auth.getAddUserId();   
        }


        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetWorklistWithFilters", parameters: params
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    //console.log("response from procedure here");
                    //console.log(response);

                    const users = response.data.map((user, i) => {
                        const result = Object.assign({}, user,
                            {
                                ID: user.ID,
                                Client: user.Client,
                                PatientName: user.PatientName,
                                Manager: user.Manager,
                                Collector: user.Collector,
                                CaseId: user.CaseId,
                                DOS: user.DOS,
                                Status: user.Status,
                                Account: user.FacilityId,
                                Age: user.Age,
                                Payer: user.Payer,
                                FinClass: user.FinClass,
                                Notes: user.Notes,
                                Actioned: user.Actioned
                            });
                        return result;
                    });

                    resolve(users);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getWorkinglistForFTC(ticklerType, actionedType) {

        const auth = new Auth();

        let params;
        let showMyItems = auth.getShowMyItems();
        var selectedClients = this.getSelectedClients();

        params = { ticklerType: ticklerType, actionedType: actionedType, userGroups: auth.getAddGroupIds() };

        if (selectedClients) {
            params.clients = selectedClients;
        }

        if (showMyItems) {
            params.userid = auth.getAddUserId();
        }


        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "getWorkinglistForFTC", parameters: params
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    //console.log("response from procedure here");
                    //console.log(response);

                    const users = response.data.map((user, i) => {
                        const result = Object.assign({}, user,
                            {
                                ID: user.ID,
                                Client: user.Client,
                                PatientName: user.PatientName,
                                Manager: user.Manager,
                                Collector: user.Collector,
                                CaseId: user.CaseId,
                                DOS: user.DOS,
                                Status: user.Status,
                                Account: user.FacilityId,
                                Age: user.Age,
                                Payer: user.Payer,
                                FinClass: user.FinClass,
                                Notes: user.Notes,
                                Actioned: user.Actioned
                            });
                        return result;
                    });

                    resolve(users);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getRelatedDocuments(account, caseId) {

        //console.log("parameters:");
        //console.log(account);
        //console.log(caseId);

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetRelatedDocuments", parameters: { account: account, caseId: caseId }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    const documents = response.data.map((document, i) => {
                        const result = Object.assign({}, document,
                            {
                                Id: document.Id,
                                JobId: document.JobId,
                                ProcedureDescription: document.ProcedureDescription,
                                Account: document.Account,
                                WorkType: document.WorkType,
                                WorkTypeDescription: document.WorkTypeDescription,
                                DictEnd: document.DictEnd,

                            });
                        
                        return result;
                    });

                    resolve(documents);

                })
                .catch(error => {
                    console.log("Error here...");
                    console.log(error);
                });
        });

    }

    getIvDetailsById(id) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetIvlistDetail", parameters: { id: id }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    /*console.log(response);*/

                    const users = response.data.map((user, i) => {
                        const result = Object.assign({}, user,
                            {
                                Id: user.Id,
                                Client: user.Client,
                                FacilityID: user.FacilityID
                                , FacilityName: user.FacilityName
                                , IVStaff: user.IVStaff
                                , DOS: user.DOS
                                , PtIDVst: user.PtIDVst
                                , PtNameAcct: user.PtNameAcct
                                , PhysName: user.PhysName
                                , Procedure: user.Procedure
                                , Payer: user.Payer
                                , PayerName: user.PayerName
                                , Verified: user.Verified
                                , BalanceDue: user.BalanceDue
                                , Authorized: user.Authorized
                                , refNum: user.refNum
                                , ImportDate: user.ImportDate
                            });
                        return result;
                    });

                    resolve(users[0]);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getDetailsById(id) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetWorklistDetail", parameters: { id: id}
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    /*console.log(response);*/

                    const users = response.data.map((user, i) => {
                        const result = Object.assign({}, user,
                            {
                                CaseId: user.CaseId,
                                Collector: user.Collector,
                                PatientName: user.PatientName,
                                DOS: user.DOS,
                                Charges: user.Charges,
                                ContractFee: user.ContractFee,
                                Payments: user.Payments,
                                LstPmtDate: user.LstPmtDate,
                                LstPmtAmount: parseFloat(user.LstPmtAmount) * -1,
                                Balance: user.Balance,
                                FinClass: user.FinClass,
                                Payer: user.Payer,
                                DateOfBirth: user.DateOfBirth,
                                PolicyNumber: user.PolicyNumber,
                                GroupNumber: user.GroupNumber,
                                NoteDate: user.NoteDate,
                                Age: user.Age,
                                StatusCode: user.StatusCode,
                                StatusDesc: user.StatusDesc,
                                Notes: user.Notes,
                                TargetDate: user.TargetDate,
                                CreateBy: user.CreateBy,
                                Followup: user.Followup,
                                NxtWkDate: user.NxtWkDate,
                                Tickler: user.Tickler,
                                LstUpdate: user.LstUpdate,
                                Systm: user.Systm,
                                SPStatus: user.SPStatus,
                                StmtsSent: user.StmtsSent,
                                DateBilled: user.DateBilled,
                                TeamStatusId: user.TeamStatusId,
                                Actioned: user.Actioned,
                                Classification: user.Classification
                            });
                    return result;
                    });

                    resolve(users[0]);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    updateTeamStatusId(caseId, teamStatusId) {

        const auth = new Auth();
        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "UpdateWorkListTeamStatus", parameters: { caseId: caseId, teamStatusId: teamStatusId }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    markAsActioned(ids) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "UpdateMarkAsActioned", parameters: { ids: ids, actioned: true }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    markAsNotActioned(ids) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "UpdateMarkAsActioned", parameters: { ids: ids, actioned: false }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    getWorkinglistSummary() {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let params;
            let showMyItems = auth.getShowMyItems();
            var selectedClients = this.getSelectedClients();

//            console.log('selected clients: ' + selectedClients);

            params = { userGroups: auth.getAddGroupIds() };

            if (selectedClients) {
                params.clients = selectedClients;
            }

            if (showMyItems) {
                params.userid= auth.getAddUserId();
            }

            //console.log('params: ');
            //console.log(params);

            let reqBody = {
                account: "zuludatabase", command: "GetWorkinglistSummary", parameters: params
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    //console.log(response);

                    const users = response.data.map((worklist, i) => {
                        const result = Object.assign({}, worklist,
                            {
                                Tickler: worklist.Tickler,
                                Description: worklist.Description,
                                Count: worklist.Count,
                                Label: worklist.Label,
                                Show: worklist.Show,
                                ListType: worklist.ListType
                            });
                        return result;
                    });

                    resolve(users);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getSelectedClients() {
        var selClients = sessionStorage.getItem('selectedClients');
        if (selClients) {
            return JSON.parse(sessionStorage.getItem('selectedClients')).join('|');
        }
        else {
            return '';
        }
    }

    setSelectedClients(value) {
        sessionStorage.setItem('selectedClients', JSON.stringify(value));
    }
}