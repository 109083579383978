import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import UserDataAccess from '../services/userDataAccess';
import Auth from '../services/auth';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const dataAccess = new UserDataAccess();
const auth = new Auth();

const rowsCPT = [];
const rowsICD = [];

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function CodingDetails(props) {
    const classes = useStyles();
    //const auth = new Auth();
    const [details, setDetails] = React.useState({ Comments: {}, CptCodes: {}, Icd10Codes: {} });
    //const userHasUpdatePermission = auth.isInRole('SNChartGlobalAdmin') || auth.isInRole('SNChartSuperUser');


    useEffect(() => {
        async function fetchData() {
            dataAccess.getCodingDetails(props.account, props.caseId).then(function (detailsData) {
                //setDetails(detailsData[0]);
                //console.log(detailsData[0])
                if (detailsData !== null) {
                    setDetails(detailsData);
                    //console.log(detailsData[0]);
                }
                //console.log(detailsData);
                
            });
        }
        fetchData();
    }, []);



    return (
        <Grid container className={classes.root} spacing={2}>

            <Grid item xs={12}>
                <Paper className={classes.root}>
                        <Grid item xs={12} md={6}>
                      
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right" >CPT Code</TableCell>
                                       
                                        <TableCell align="right">Modifier</TableCell>
                                        <TableCell align="right">Payment Indicator</TableCell>
                                        <TableCell align="right">ICD Link</TableCell>
                                        <TableCell align="right">Quatity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {details.CptCodes.CptCode ? details.CptCodes.CptCode.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell align="right">{row.CptCode}</TableCell>
                                     
                                            <TableCell align="right">{row.CptModifier}</TableCell>
                                            <TableCell align="right">{row.AcsPi}</TableCell>
                                            <TableCell align="right">{row.IcdLink}</TableCell>
                                            <TableCell align="right">{row.Quantity}</TableCell>
                                        </TableRow>
                                    )) : []}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </Grid>
                        <Grid item xs={12} md={6}>
                 

                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">ICD-10 CODE</TableCell>
                                  
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {details.Icd10Codes.Icd10Code ? details.Icd10Codes.Icd10Code.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell align="right">{row.Code}</TableCell>
                                          
                                        </TableRow>
                                    )) : []}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>


                        <Grid item xs={12} md={6}>
                    
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">Comment</TableCell>
                                        <TableCell align="right">Entered By</TableCell>
                                        <TableCell align="right">Create Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                        {details.Comments.Comment ? details.Comments.Comment.map(row => {
                            return row.JobId && (
                                <TableRow key={row.JobCommentId}>
                                    <TableCell align="right">{row.CommentText}</TableCell>
                                    <TableCell align="right">{row.EnteredBy}</TableCell>
                                    <TableCell align="right">{row.CreateDate}</TableCell>
                                </TableRow>
                            )
                        }) : []
                        }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        </Grid>
                        
                    
                </Paper>
            </Grid>

        </Grid>
    );
}