import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import ProgressIndicator from './ProgressIndicator';

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    })
});

class Loading extends React.Component {

    render() {

        return (<div>
<ProgressIndicator showProgressIndicator={true} progressTitle={'Please wait...'} progressText={'Processing request'} />
    </div>);
       }
}



export default withStyles(styles, { withTheme: true })(Loading);
