import * as React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import GlobalDrawer from './GlobalDrawer';
import IdleTimer from 'react-idle-timer'
import Auth from '../services/auth';
import PubSub from 'pubsub-js';

export class Layout extends React.Component {
    displayName = Layout.name
    
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
        //this.onAction = this._onAction.bind(this);
    }

   /* _onAction(e) {
        console.log('user did something', e)
    }*/

    _onActive(e) {
        //console.log('here');
        //console.log('user is active', e)
        //console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    _onIdle(e) {

        const auth = new Auth();
        if (auth.isAuthenticated()  && auth.isValidLogin()) {
            auth.signOut();
        }
        //console.log('user is idle', e)
        //console.log('last active', this.idleTimer.getLastActiveTime())
    }

    componentWillMount() {
        this.token = PubSub.subscribe('IdleReset', this.idleReset.bind(this));
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    idleReset(msg, data) {
        this.idleTimer.reset();
    }

    render() {

        const theme = createMuiTheme({
            palette: {
                primary: {
                    light: '#0690f9',
                    main: '#0956a8',
                    dark: '#03487c',
                },
                secondary: {
                    main: '#E08827',
                    contrastText: '#fff',
                },
            },
        });
        return (<MuiThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <GlobalDrawer>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    timeout={1800000}>
                    {this.props.children}
                </IdleTimer>
            </GlobalDrawer>
        </MuiThemeProvider>);
    }
}