import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Auth from '../services/auth';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import FormHelperText from '@material-ui/core/FormHelperText';
import UserDataAccess from '../services/userDataAccess';

const userDataAccess = new UserDataAccess();


const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    }),
    logo: {
        maxWidth: '100%',
        margin: 'auto'
    },
    page: {
        backgroundImage: `url(${"images/login_bg.jpg"})`,
        backgroundSize: 'cover',
        minHeight: 'calc(100vh - 24px)',
    },
    center: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    }
});



class ResetPassword extends React.Component {


    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.Auth = new Auth();

        this.state = {
            passwordErrors: [],
            Account: "",
            Email: "",
            EmailCode: "",
            Luid: 0,
            Password: '',
            hasError: false,
            loginErrorMessage: 'Invalid username or password.',
            loading: true,
            openSnack: false,
            snackMessage: ""
        };
    }

    componentWillMount() {

        var self = this;

        if (this.Auth.isAuthenticated()) {
            let path = this.props.location.state !== undefined ? this.props.location.state.from.pathname || '/' : '/';
            this.props.history.replace(path);
        }

        userDataAccess.getRegistration(this.props.match.params.code).then(function (result) {

            self.setState({ loading: false, Email: result.Email, Account: result.Account, EmailCode: result.EmailCode, Luid: result.Luid });
        });
    }

    submit = (event) => {
        event.preventDefault();
        this.login();
    };

    render() {
        const { classes } = this.props;
        const { openSnack, snackMessage } = this.state;
        return <div className={classes.page}>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={openSnack}
                autoHideDuration={3000}
                onClose={this.handleSnackClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{snackMessage}</span>}
            />
            <Grid container spacing={6} justify="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.root} elevation={4}>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item xs={12}>
                                        <img className={classes.logo} src='/images/SNChart.jpg' alt='SNChart' />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item>
                                        <Typography variant="h5" gutterBottom>
                                            Complete Password Reset
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <form onSubmit={this.submit}>

                                    <TextField fullWidth
                                        label="User Name"
                                        disabled
                                        readOnly
                                        error={this.state.hasError}
                                        value={this.state.Email}
                                    /><br /><br />
                                    <TextField fullWidth
                                        name="txtPassword"
                                        id="txtPassword"
                                        type="password"
                                        label="Password"
                                        onChange={this.handleChange('password')}
                                        error={this.state.hasError}

                                    /><br /><br />
                                    <TextField fullWidth
                                        name="txtPasswordConfirm"
                                        id="txtPasswordConfirm"
                                        type="password"
                                        label="Confirm Password"
                                        onChange={this.handleChange('confirmPassword')}
                                        onKeyPress={(ev) => {
                                            if (ev.key === 'Enter') {

                                                ev.preventDefault();
                                                this.login();
                                            }
                                        }}
                                        error={this.state.passwordErrors.length > 0}

                                    />
                                    {this.state.passwordErrors.length > 0 && <FormHelperText id="name-error-text" error>{this.state.passwordErrors.map((e, i) => {

                                        return (
                                            <span key={i}>{e}<br /></span>
                                        );
                                    }, this)}</FormHelperText>}
                                </form>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item>
                                        <Button variant="contained" onClick={() => { this.login() }}>Set Password</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item xs={12} className={classes.center}>
                                        <img className={classes.logo} src='/images/SNLogo.png' alt='Surgical Notes' />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
        </div>;
    }

    handleChange = field => event => {

        const { target: { value } } = event;
        this.setState({ [field]: value });
    }

    login() {

        var history = this.props.history;
        var self = this;

        userDataAccess.resetPassword(this.state).then(function (result) {
            if (result) {
                self.Auth.saveUserLogin(self.state.Email);
                history.replace("/");
            }
            //
        }).catch(error => {
         
            if (error.response.status === 409) {
                if (error.response.data && error.response.data.Result) {
                    self.setState({ passwordErrors: error.response.data.Result.Errors });
                } else if (error.response.data && error.response.data.Errors) {
                    self.setState({ passwordErrors: error.response.data.Errors });
                }
                else {
                    self.setState({ openSnack: true, snackMessage: 'Unexpected Error Saving User' });
                }
                // console.log(error.response.data.Result);
            } else {
                self.setState({ openSnack: true, snackMessage: 'Unexpected Error Saving User' });
            }
            // console.log(error.response);

        });

    }

}

export default withStyles(styles)(ResetPassword);