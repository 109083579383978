import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import InputLabel from '@material-ui/core/InputLabel';
import ReactDOM from 'react-dom';
import 'react-datepicker/dist/react-datepicker.css';
import AddCircleIcon from '@material-ui/icons/AddCircle';

function desc(a, b, orderBy) {

  if (moment(b[orderBy]).isValid() || moment(a[orderBy]).isValid()){
         b = moment(b[orderBy]).isValid() ? moment(b[orderBy]) : moment(new Date(8640000000000000));
         a = moment(a[orderBy]).isValid() ? moment(a[orderBy]) : moment(new Date(8640000000000000));
    return b.diff(a);
    }
    else{
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, allowSelections } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {allowSelections && <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            
                        />
                    </TableCell>}
                    {this.props.columnData.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                align={column.numeric? 'right': 'left'}
                                padding={column.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === column.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        onClick={this.createSortHandler(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    columnData: PropTypes.array.isRequired
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing(1),
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        paddingBottom: theme.spacing(1),
        overflowY: 'auto'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        flex: '0 0 auto',


    },
    titleContainer: {
        paddingRight: theme.spacing(1),

    },
    title: {
        flex: '0 0 auto',
    },
    selectActions: {
        flex: '0 0 auto',
    },
    textField: {
        maxWidth: 155,
        
    },
    filterLabel: {
        marginTop: theme.spacing(2)
    },
    actionButton: {
        marginLeft: theme.spacing(.5),
        letterSpacing: 0
    },
    dateSelectformControl: {
        marginLeft: theme.spacing(1),
        minWidth: 145,
    }
});

class EnhancedTableToolbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            selectFilterLabel: 'All',
            selectedFilters: this.props.defaultFilters,
            anchorDownloadEl: null,
            anchorCodingExportEl: null,
            fromDate: moment(this.props.defaultStartDate, 'MM/DD/YYYY'),
            toDate: moment(this.props.defaultEndDate, 'MM/DD/YYYY'),
            fromDateInput: this.props.defaultStartDate,
            toDateInput: this.props.defaultEndDate,
            dateType: this.props.dateType,
            fromDateLabel: this.props.showDateOptions ? "From Date" : "From Date of Service",
            toDateLabel: this.props.showDateOptions ? "To Date" : "To Date of Service"
            //fromDateLabel: this.props.dateType == "CodedDate" ? "From Coded Date": "From Date of Service",
            //toDateLabel: this.props.dateType == "CodedDate" ? "To Coded Date" : "To Date of Service"
        };
    }


    onOpenDownloadMenu = (event) => {
        this.setState({ anchorDownloadEl: event.currentTarget });
    }

    onOpenCodingExportMenu = (event) => {
        this.setState({ anchorCodingExportEl: event.currentTarget });
    }

    onCloseDownloadMenu = (event) => {
        this.setState({ anchorDownloadEl: null });
    }

    onCloseCodingExportMenu = (event) => {
        this.setState({ anchorCodingExportEl: null });
    }

    onGlobalFilterChange = (event, filterName) => {

        this.setState({[filterName + 'Input']: event.target.value});
        if (moment(event.target.value).isValid()) {
            this.props.onGlobalFilterChanged(event.target.value, filterName);
        }

    }

    onGlobalSelectFilterChange = (label, value, filterName) => {
        let filters = this.state.selectedFilters;
        let index = filters.indexOf(value);
        if (index > -1) {
            filters.splice(index, 1);
        }
        else {
            filters.push(value);
        }

        this.setState({
            selectedFilters: filters
        }, () => {
            this.props.onGlobalFilterChanged(this.state.selectedFilters, filterName);
        });


    }

    handleDateChange = (date, field) => {
        this.setState({ [field]: moment(date), [field + 'Input']: date });
        this.props.onGlobalFilterChanged(date, field);
    }

    handleFromDateChange = (date) => {
        if (moment(date).isValid()) {
            this.handleDateChange(moment(date).format("MM/DD/YYYY"), 'fromDate');
        }
    }

      handleToDateChange = (date) => {
          if (moment(date).isValid()) {
              this.handleDateChange(moment(date).format("MM/DD/YYYY"), 'toDate');
          }
    }

    handleDateTypeChange = event => {

        //let labelFromInput = "From Date of Service";
        //let labelToInput = "To Date of Service";
                
        //if (event.target.value == "CodedDate") {
        //     labelFromInput = "From Coded Date";
        //     labelToInput = "To Coded Date";
        //}

        this.setState({ dateType: event.target.value });

        if (this.props.onDateTypeChanged) {
            this.props.onDateTypeChanged(event.target.value);
        }
    };



    render() {

        const $el = document.querySelector('body');

        const PopperContainer = ({ children }) => (
            ReactDOM.createPortal(
                children,
                $el,
            )
        );
        let tableFilterToggle = "";
        if (this.props.tableFilterToggle) {
            tableFilterToggle = this.props.tableFilterToggle();
        }

        const { numSelected, totalCount, classes, onSelectAction, selected, includeActions, globalSelectFilters, defaultFilters, canPerformAction, showActions, customActions, showDateOptions, showAddButton, onAddClick} = this.props;
        const { anchorEl, anchorDownloadEl, anchorCodingExportEl } = this.state;
     
        return (

            <Toolbar className={classes.root}>
                <div className={classes.selectActions}>
                    {showAddButton && 
                        <Grid item>
                        <IconButton onClick={(e) => onAddClick()} color="secondary">
                            <AddCircleIcon />
                        </IconButton>
                        </Grid>}
                    {customActions && customActions}
                    {includeActions && <Grid container spacing={0} alignContent="stretch" alignItems="flex-end">
                        <Grid item>
                            <Typography variant="caption">Selected Items</Typography>

                            <Typography variant="h5">{numSelected} of {totalCount}</Typography>

                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item style={{ display: 'none' }}>
                                    <Button className={classes.actionButton} size="small" disabled={numSelected === 0} variant="contained" color="secondary">Re-Assign</Button>
                                </Grid>
                               
                                <Grid item>
                                    <Button className={classes.actionButton} size="small" disabled={numSelected === 0} variant="contained" color="secondary">Mark Actioned</Button>
                                </Grid>
                              
                                
                            </Grid>
                        </Grid>
                    </Grid>}
                </div>
                <div className={classes.spacer} />
                {showActions && <div className={classes.actions}>
                    <Grid container spacing={1} justify='flex-end' alignItems="center">
                        {this.props.tableFilterToggle && <Grid item>
                            {tableFilterToggle}
                            </Grid>}
                        <Grid item>
                            {showDateOptions &&
                                <FormControl className={classes.dateSelectformControl}>
                                    <InputLabel htmlFor="dateTypeSelect">Date Type</InputLabel>
                                    <Select

                                        onChange={this.handleDateTypeChange}
                                        input={<Input name='dateTypeSelect' id='dateTypeSelect' />}
                                        value={this.state.dateType}
                                    >
                                        <MenuItem key={'CodedDate'} value={'CodedDate'}>
                                            Coded Date
                                    </MenuItem>
                                        <MenuItem key={'DateOfService'} value={'DateOfService'}>
                                            Date of Service
                                    </MenuItem>
                                        <MenuItem key={'ESignDate'} value={'ESignDate'}>
                                            E-Signature Date
                                    </MenuItem>
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item>
                            <div className={classes.dateWrapper}>
                               
                                        <DatePicker 
                                          popperContainer={PopperContainer}
                                          disabledKeyboardNavigation
                                          selected={this.state.fromDate}
                                          onChange={this.handleFromDateChange}
                                            customInput={<div><TextField
                                            className={classes.textField}
                                            id="fromDate"
                                            label={this.state.fromDateLabel}
                                            value={this.state.fromDateInput}
                                            InputLabelProps={{
                                                shrink: true,
                                                }}
                                                
                                             onChange={(e) => this.onGlobalFilterChange(e, 'fromDate')}
                                        /></div>} />
                                        </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.dateWrapper}>
                           
                                        <DatePicker 
                                          popperContainer={PopperContainer}
                                          disabledKeyboardNavigation
                                          selected={this.state.toDate}
                                          onChange={this.handleToDateChange}
                                            customInput={<div><TextField
                                            className={classes.textField}
                                            id="toDate"
                                            label={this.state.toDateLabel}
                                            value={this.state.toDateInput}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                             onChange={(e) => this.onGlobalFilterChange(e, 'toDate')}
                                        /></div>} />
                                        </div>
                                        
                        </Grid>
                        <Grid item>


                            <Tooltip title="Filter list">
                                <IconButton aria-label="Filter list" onClick={(e) => this.setState({ anchorEl: e.currentTarget })} aria-owns={anchorEl ? 'globalSelectionMenu' : null}
                                    aria-haspopup="true">
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                            <Menu id="globalSelectionMenu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={(e) => this.setState({ anchorEl: null })}>

                                {globalSelectFilters && globalSelectFilters.map((f,i) => {

                                    if (f.Divider) {
                                        return (<Divider key={i}/>)
                                    }
                                    else {
                                        return (
                                            <MenuItem style={f.style} key={f.value} onClick={(e) => this.onGlobalSelectFilterChange(f.label, f.value, 'status')}>
                                                <Checkbox checked={(this.state.selectedFilters.indexOf(f.value) > -1)} />
                                                <ListItemText primary={f.label} />
                                            </MenuItem>
                                        )
                                    }
                                })}

                            </Menu>

                        </Grid>
                    </Grid>

                </div>}
            </Toolbar>
        );
    }

};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    onSelectAction: PropTypes.func,
    globalSelectFilters: PropTypes.array,
    defaultFilters: PropTypes.array,
    canPerformAction: PropTypes.func,
    showActions: PropTypes.bool,
    showDateOptions: PropTypes.bool,
    onAfterSelect: PropTypes.func,
    showAddButton: PropTypes.bool,
    onAddClick: PropTypes.func
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%'
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableTitle: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3)
    },
    formControl: {
        marginRight: theme.spacing(1),
        marginLeft: 0,
        minWidth: 150,
        maxWidth: 170,
    },
    filterTextField: {
        paddingRight: theme.spacing(1)
    },
});

class EnhancedTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            order: this.props.orderDirection,
            orderBy: this.props.orderBy,
            selected: [],
            data: [],
            page: 0,
            rowsPerPage: 25,
            selectAllChecked: false
        };
    }

    componentDidUpdate(previousProps) {

        if (previousProps.rowData !== this.props.rowData) {
            this.setState({ "data": this.props.rowData, "selected": [] });
        }

    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (page, event, checked) => {

        if (checked) {
            var self = this;
            this.setState(state => ({
                selectAllChecked: true,
                selected: page.filter(function (item) {
                    return self.props.canSelect(item);
                }).map(n => n.id)
            }));




            return;
        }

        this.setState({ selected: [], selectAllChecked: false });
    };

    handleClick = (event, id) => {

        if (this.props.allowSelections) {
            const { selected } = this.state;
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];


            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }

            this.setState({ selected: newSelected });
        }
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {

        const value = event.target.value;
        const rowsPerPage = value === 'All' ? this.state.data.length : value;
        this.setState({ rowsPerPage: rowsPerPage });
    };

    createSortHandler = property => event => {
        this.handleRequestSort(event, property);
    };

    filterData = (dataRow) => {

        var canInclude = true;
        for (var i = 0; i < this.props.columnData.length; i++) {

            var filtersForColumn = this.state[`filter_${this.props.columnData[i].id}`];

            if (filtersForColumn != null && filtersForColumn.length > 0) {

                let filterType = this.props.columnData[i].filter;

                if (filterType === 'choice' && filtersForColumn.indexOf(dataRow[this.props.columnData[i].id]) < 0) {
                    return false;
                }

                if (filterType === 'textSearch') {
                    if (dataRow[this.props.columnData[i].id] === null) {
                        return false;
                    }

                    if (dataRow[this.props.columnData[i].id].toLowerCase().indexOf(filtersForColumn.toLowerCase()) < 0) {
                        return false;
                    }
                }

                if (filterType === 'dateSearch') {
                    if (dataRow[this.props.columnData[i].id] === null) {
                        return false;
                    }

                    if (!moment(dataRow[this.props.columnData[i].id]).isSame(filtersForColumn, 'day')) {
                        return false;
                    }
                }

            }
        }

        return canInclude;

    }

    onAfterSelect = () => {

        this.setState({ selected: [] });
    }

    onSelectAction = (e, selected, action, sort) => {

        if (sort) {
            const dataIds = this.state.data
                .sort(getSorting(this.state.order, this.state.orderBy))
                .filter(item => this.isSelected(item.id))
                .map(item => { return item.id });

            selected.sort(function (a, b) {
                return dataIds.indexOf(a) - dataIds.indexOf(b);
            });
        }
       
        this.props.onSelectAction(e, selected, action);
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes, allowSelections, includeActions, isLoading, showActions, customActions, showDateOptions, showAddButton} = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page } = this.state;

        const dataPage = data
            .filter(this.filterData)
            .sort(getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        for (var i = 0; i < this.props.columnData.length; i++) {
            this.props.columnData[i].filters = [];
        }

        for (var y = 0; y < data.length; y++) {
            for (var x = 0; x < this.props.columnData.length; x++) {
                if (this.props.columnData[x].filter === 'choice') {
                    if (this.props.columnData[x].filters.indexOf(data[y][this.props.columnData[x].id]) < 0) {
                        this.props.columnData[x].filters.push(data[y][this.props.columnData[x].id]);
                    }
                }
            }
        }

        //const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    minWidth: 250,
                },
            },
        };

  
        return (<Paper className={classes.root} style={{ marginBottom: 100 }}>
            <Typography className={classes.tableTitle} variant="h6" id="tableTitle">
                {this.props.tableTitle}
            </Typography>
           
            <EnhancedTableToolbar includeActions={includeActions}
                showActions={showActions}
                showAddButton={showAddButton}
                customActions={customActions}
                numSelected={selected.length}
                totalCount={data.length}
                onSelectAction={this.onSelectAction}
                selected={selected}
                showDateOptions={showDateOptions}
                defaultEndDate={this.props.defaultEndDate}
                defaultStartDate={this.props.defaultStartDate}
                onGlobalFilterChanged={this.props.onGlobalFilterChanged}
                onDateTypeChanged={this.props.onDateTypeChanged}
                globalSelectFilters={this.props.globalSelectFilters}
                defaultFilters={this.props.defaultFilters}
                canPerformAction={this.props.canPerformAction}
                onAfterSelect={this.onAfterSelect}
                dateType={this.props.dateType}
                tableFilterToggle={this.props.tableFilterToggle}
                onAddClick={this.props.onAddClick}            />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-label={this.props.tableTitle} size="small">

                        <TableHead>
                            <TableRow>
                            {allowSelections && <TableCell padding="checkbox" style={{paddingLeft:26}}>
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < data.length}
                                        checked={(selected.length === data.length) || this.state.selectAllChecked}
                                        onChange={(e, checked) => this.handleSelectAllClick(dataPage, e, checked)}
                                    />
                                    </TableCell>}
                                 {data && this.props.columnData.map(column => {
                                    return (
                                        <TableCell
                                            key={column.id}
                                            padding={column.disablePadding ? 'none' : 'default'}
                                        >

                                            {column.filter === 'choice' && <div>
                                                <Tooltip
                                                    title="Sort"
                                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                                    enterDelay={300}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === column.id}
                                                        direction={order}
                                                        style={{whiteSpace:'nowrap'}}
                                                        onClick={this.createSortHandler(column.id)}
                                                    >
                                                        {column.label}
                                                    </TableSortLabel>
                                                </Tooltip>
                                                <FormControl className={classes.formControl}>
                                                    <Select
                                                        autoWidth
                                                        multiple
                                                        value={this.state[`filter_${column.id}`] || []}
                                                        onChange={(event) => this.setState({ [`filter_${column.id}`]: event.target.value })}
                                                        input={<Input />}
                                                        renderValue={selected => selected.join(', ')}
                                                        MenuProps={MenuProps}>


                                                        {column.filters.sort().map(name => (
                                                            <MenuItem key={name} value={name}>
                                                                <Checkbox checked={(this.state[`filter_${column.id}`] != null ? this.state[`filter_${column.id}`].indexOf(name) > -1 : false)} />
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl></div>}
                                            {(column.filter === 'textSearch') && <div>
                                                <Tooltip
                                                    title="Sort"
                                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                                    enterDelay={300}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === column.id}
                                                        direction={order}
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        onClick={this.createSortHandler(column.id)}
                                                    >
                                                        {column.label}
                                                    </TableSortLabel>
                                                </Tooltip>
                                                <TextField
                                                    id={column.id}
                                                    className={classes.filterTextField}
                                                    onChange={(event) => this.setState({ [`filter_${column.id}`]: event.target.value })}
                                                /></div>}
                                            {(column.filter === 'dateSearch') && <div><Tooltip
                                                title="Sort"
                                                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                                enterDelay={300}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={order}
                                                    style={{ whiteSpace: 'nowrap' }}
                                                    onClick={this.createSortHandler(column.id)}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            </Tooltip><TextField
                                                    id={column.id}
                                                    type="date"
                                                    className={classes.filterTextField}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(event) => this.setState({ [`filter_${column.id}`]: event.target.value })}
                                                /></div>}
                                            {(column.filter === 'none') && <div><Tooltip
                                                title="Sort"
                                                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                                enterDelay={300}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={order}
                                                    style={{ whiteSpace: 'nowrap' }}
                                                    onClick={this.createSortHandler(column.id)}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            </Tooltip><TextField
                                                    disabled
                                                    id={column.id}
                                                    className={classes.filterTextField}
                                                    onChange={(event) => this.setState({ [`filter_${column.id}`]: event.target.value })}
                                                /></div>}
                                        </TableCell>
                                    );
                                }, this)}

                            </TableRow>
                        </TableHead>
                       {(isLoading) && <TableBody><TableRow><TableCell colSpan={9}><CircularProgress/></TableCell></TableRow></TableBody> }
                       {(!isLoading && data.length === 0) && <TableBody><TableRow><TableCell colSpan={9}>No results found</TableCell></TableRow></TableBody> }
                       {(!isLoading && data.length > 0) && <TableBody>
                            {dataPage
                                .map(n => {
                                    const isSelected = this.isSelected(n.id);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={event => this.props.canSelect(n) && this.handleClick(event, n.id)}
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                            style={n.RowStyle ? n.RowStyle : null}
                                        >
                                            {allowSelections && <TableCell key={`chk_${n.id}`} padding="checkbox">
                                                <Checkbox checked={isSelected} disabled={!this.props.canSelect(n)} />
                                            </TableCell>}


                                            {this.props.columnData.map(column => {
                                                const key = `row_cell_${column.id}_${n['Id']}`;
                                                if (column.link) {

                                                    let linkParams = "";
                                                    for (var i = 0; i < column.linkParams.length; i++) {
                                                        linkParams += "/" + n[column.linkParams[i]];
                                                    }

                                                    let renderActions = "";
                                                    if (column.renderActions)  {
                                                        renderActions = column.renderActions(n)
                                                    }
                                                    return (
                                                        <TableCell key={key} component="th" scope="row" padding={column.disablePadding ? 'none' : 'default'} style={{ whiteSpace: 'nowrap' }} >
                                                            <Grid container alignItems="center" justify="space-between" direction="row" wrap="nowrap" style={{ maxWidth: column.maxWidth ? column.maxWidth + 16 : 'none' }}>
                                                                <Grid item>
                                                                    <Link to={`/${column.linkPath}${linkParams}`}>
                                                                        <Typography variant="body2" noWrap style={{ maxWidth: column.maxWidth ? column.maxWidth : 'none' }} >{n[column.id]}
                                                                           </Typography> 
                                                                            </Link>
                                                                    {(n.StatusFlag && n.StatusFlag !== "") && <span style={{ color: 'red', fontWeight: 600, paddingLeft: 15 }}>{n.StatusFlag}</span>}

                                                                </Grid>
                                                                <Grid item>
                                                                    {renderActions}
                                                                </Grid>
                                                            </Grid>
                                                        
                                                        </TableCell>
                                                    );
                                                } else if (column.linkCallBack) {

                                                    let renderActions = "";
                                                    if (column.renderActions) {
                                                        renderActions = column.renderActions(n)
                                                    }

                                                    let linkParams = "";
                                                    for (var j = 0; j < column.linkParams.length; j++) {

                                                        linkParams += "/" + n[column.linkParams[j]];
                                                    }

                                                    return (
                                                        <TableCell key={key} component="th" scope="row" padding={column.disablePadding ? 'none' : 'default'} style={{ whiteSpace: 'nowrap' }}>
                                                            <Grid container alignItems="center" justify="space-between" direction="row" wrap="nowrap" style={{ maxWidth: column.maxWidth ? column.maxWidth + 16 : 'none' }}>
                                                                <Grid item>

                                                            <Link onClick={(e) => column.linkCallBack(e, n, selected)} to={`/${column.linkPath}${linkParams}`}>{n[column.id]}</Link>
                                                            {(n.StatusFlag && n.StatusFlag !== "") && <span style={{ color: 'red', fontWeight: 600, paddingLeft: 15 }}>{n.StatusFlag}</span>}

                                                                    </Grid>
                                                                    <Grid item>
                                                                {renderActions}
                                                                </Grid>
                                                           </Grid>
                                                        </TableCell>
                                                    );

                                                } else {
                                                    if (column.render) {
                                                        return (
                                                        <TableCell padding={column.disablePadding ? 'none' : 'default'} key={key}>{column.render(n)}</TableCell>
                                                    );
                                                    }
                                                    else {
                                                        return (
                                                            <TableCell padding={column.disablePadding ? 'none' : 'default'} key={key}>
                                                                <Typography noWrap variant="body2" style={{ maxWidth: column.maxWidth ? column.maxWidth : 'none'}}>{n[column.id]}</Typography>
                                                            </TableCell>
                                                    );
                                                    }

                                                }
                                            })}
                                        </TableRow>
                                    );

                                })}
                          
                        </TableBody>}
                    </Table>
                </div>
                <TablePagination
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25 , 50, 'All']}
                />
            </Paper>
        );
    }
}

EnhancedTable.defaultProps = {
    canSelect: () => { return true; }
  
};


EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columnData: PropTypes.array.isRequired,
    rowData: PropTypes.array.isRequired,
    tableTitle: PropTypes.string.isRequired,
    onSelectAction: PropTypes.func,
    defaultStartDate: PropTypes.string,
    defaultEndDate: PropTypes.string,
    onGlobalFilterChanged: PropTypes.func,
    includeActions: PropTypes.bool.isRequired,
    allowSelections: PropTypes.bool.isRequired,
    globalSelectFilters: PropTypes.array,
    defaultFilters: PropTypes.array,
    toolBar: PropTypes.func,
    canPerformAction: PropTypes.func,
    isLoading: PropTypes.bool,
    orderBy: PropTypes.string,
    orderDirection: PropTypes.string,
    canSelect: PropTypes.func, 
    showActions: PropTypes.bool,
    showAddButton: PropTypes.bool,
    showDateOptions: PropTypes.bool,
    onDateTypeChanged: PropTypes.func,
    onAddClick: PropTypes.func
};

export default withStyles(styles)(EnhancedTable);
