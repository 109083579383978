import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Auth from '../services/auth';
import ProgressIndicator from './ProgressIndicator';

const styles = theme => ({
    
});


class Login extends React.Component {


    constructor(props) {
        super(props);

        this.Auth = new Auth();

        this.state = {
            username: this.Auth.getUserLogin() || '',
            password: '',
            updateLogin: false,
            headline: 'SIGN IN',
            hasError: false,
            confirmationSent: false,
            loginErrorMessage: 'Invalid username or password.',
            emailcode: '',
            newusername: '',
            activeStep: 0,
            confirmError: '',
            confirmIdentityError: '',
            sendingConfirmation: false,
            confirmingCode: false,
            loggingIn: false,
            isSSOElegible: false,
            ssoSelected: false,
            UserAccountInFacility: false,
            schema: 'AAD'
        };
    }

    componentWillMount() {

        window.location = `/api/fedauth?scheme=AAD`;
    }

    render() {
    
        return (<div> <ProgressIndicator showProgressIndicator={true} progressTitle={'Please wait...'} progressText={'Logging in...'} /></div>);
    }

}

export default withStyles(styles)(Login);