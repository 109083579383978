import axios from 'axios';
import Auth from './auth';
import { API_ROOT } from './apiConfig';

export default class ProductivityDashboardDataAccess {

    GetProductivityDashboardSummary() {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetProductivityDashboardSummary", parameters: {}
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    console.log(response.data);
                    const allStatus = response.data.map((status, i) => {

                        const result = {
                            System: status.System,
                            Organization: status.Organization,
                            UserName: status.UserName,
                            ActivityType: status.ActivityType,
                            Facility: status.Facility,                                                
                            Day1: status.Day1,
                            Day2: status.Day2,
                            Day3: status.Day3,
                            Day4: status.Day4,
                            Day5: status.Day5,
                            Day6: status.Day6,
                            Day7: status.Day7,
                            Day8: status.Day8,
                            Day9: status.Day9,
                            Day10: status.Day10,
                            Day11: status.Day11,
                            Day12: status.Day12,
                            Day13: status.Day13,
                            Day14: status.Day14,
                            Day15: status.Day15,
                            Day16: status.Day16,
                            Day17: status.Day17,
                            Day18: status.Day18,
                            Day19: status.Day19,
                            Day20: status.Day20,
                            Day21: status.Day21,
                            Day22: status.Day22,
                            Day23: status.Day23,
                            Day24: status.Day24,
                            Day25: status.Day25,
                            Day26: status.Day26,
                            Day27: status.Day27,
                            Day28: status.Day28,
                            Day29: status.Day29,
                            Day30: status.Day30,
                            expanded: false
                        };
                        return result;
                        // const result = Object.assign({}, status,
                        //     {
                        //         ID: status.Id,
                        //         FacilityId: status.FacilityId,
                        //         CardName: status.CardName,
                        //         CardDescription: status.CardDescription,
                        //         Day1: status.Day1,
                        //         Day2: status.Day2,
                        //         Day3: status.Day3,
                        //         Day4: status.Day4,
                        //         Day5: status.Day5,
                        //         Day6: status.Day6,
                        //         Day7: status.Day7,
                        //         Day8: status.Day8,
                        //         Day9: status.Day9,
                        //         Day10: status.Day10,
                        //         Day11: status.Day11,
                        //         Day12: status.Day12,
                        //         Day13: status.Day13,
                        //         Day14: status.Day14,
                        //         Day15: status.Day15,
                        //         Day16: status.Day16,
                        //         Day17: status.Day17,
                        //         Day18: status.Day18,
                        //         Day19: status.Day19,
                        //         Day20: status.Day20,
                        //         Day21: status.Day21,
                        //         Day22: status.Day22,
                        //         Day23: status.Day23,
                        //         Day24: status.Day24,
                        //         Day25: status.Day25,
                        //         Day26: status.Day26,
                        //         Day27: status.Day27,
                        //         Day28: status.Day28,
                        //         Day29: status.Day29,
                        //         Day30: status.Day30,
                        //         StatusCodeDetail: JSON.parse(status.StatusCodeDetail),
                        //         expanded: false
                        //     });
                        // return result;
                    });

                    resolve(allStatus);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

}