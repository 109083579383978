import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ReactGA from 'react-ga4';
import Auth from '../services/auth';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import { InputLabel, Toolbar, Tooltip } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import SettingsDataAccess from '../services/settingsDataAccess';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import PubSub from 'pubsub-js';
import IntegrationAutosuggest from './IntegrationAutosuggest';
import ProcedureDataAccess from '../services/procedureDataAccess';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chip from '@material-ui/core/Chip';

const dataAccess = new SettingsDataAccess();
const proceduredataAccess = new ProcedureDataAccess();
const auth = new Auth();

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
})

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(2),
    }),
    Cost: {
        background: red,
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

//<Grid
//    container
//    spacing={0}
//    direction="column"
//    alignItems="center"
//    justify="center"
//    style={{ minHeight: '100vh' }}
//>
class Settings extends Component {
    displayName = Settings.name
    constructor(props) {
        super(props);

        this.state = {
            accountProcedures: [],
            selectedProcedures: [],
            filteredProcedures: [],
            updatedProcedures: [],
            data: {
                Id: 0,
                Account: "",
                SettingName: "",
                SettingValue: "",


            },
            user: {
                Id: 0,
                Account: auth.getActiveAccount(),
                AccountCreatedBy: "",
                Accounts: [],
                Active: true,
                SSOIdentity: "",
                Company: "",
                Dictators: [],
                EMail: "",
                FirstName: "",
                IsDeleted: false,
                IsDocumentWatermarked: false,
                IsVerified: false,
                LastName: "",
                Location: "",
                Maint: "",
                ModifyingUser: "",
                Password: "",
                Phone: "",
                Roles: [],
                SecArchive: false,
                SecCoding: 0,
                SecMarkRead: false,
                SecSave: false,
                SecSign: false,
                UserId: ""
            },
            value: "",
            statusFilters: [],
            isLoading: true,
            openSnack: false,
            snackMessage: "",
            displayFilter: false,
        };
        let self = this;
    }

    onClassicView = () => {
        const auth = new Auth();
        ReactGA.event({
            action: 'Classic Portal',
            category: `${auth.getGaUser()}`
        });
    }
    handleDelete = item => () => {

        var procedure = this.state.selectedProcedures;
        var accountProcedures = this.state.accountProcedures;
        var updatedProcedures = this.state.updatedProcedures;
        var index = procedure.findIndex(p => p.ProcedureId === item.ProcedureId);

        if (index > -1) {
            procedure.splice(index, 1);

            var fullProcedure = accountProcedures.find(procedure => procedure.ProcedureId === item.ProcedureId);
            var updateIndex = updatedProcedures.findIndex(procedure => procedure.ProcedureId === item.ProcedureId);
            if (updateIndex > -1) {
                updatedProcedures[updateIndex].IsFiltered = false;
                updatedProcedures.splice(updateIndex, 1, updatedProcedures[updateIndex]);
                accountProcedures.push(updatedProcedures[updateIndex]);
            }
            else {
                fullProcedure.IsFiltered = false;
                updatedProcedures.push(fullProcedure);
                accountProcedures.push(fullProcedure);
            }
            this.setState({ selectedProcedures: procedure, accountProcedures: accountProcedures, updatedProcedures: updatedProcedures });
        }

    }
    onSuggestionSelected = (selectedItem) => {


        //var self = this.state
        var procedure = this.state.selectedProcedures;
        var filteredProcedures = this.state.filteredProcedures;
        var accountProcedures = this.state.accountProcedures;
        var updatedProcedures = this.state.updatedProcedures;

        var indexProc = procedure.findIndex(p => p.ProcedureId === selectedItem.ProcedureId);
        if (indexProc === -1) {
            procedure.push(selectedItem);
            filteredProcedures.push(selectedItem);
        }
        selectedItem.IsFiltered = true;
        var updateIndex = updatedProcedures.findIndex(procedure => procedure.ProcedureId === selectedItem.ProcedureId);
        if (updateIndex > -1) {
            updatedProcedures[updateIndex].IsFiltered = true;
            updatedProcedures.splice(updateIndex, 1, updatedProcedures[updateIndex]);
        }
        else {
            updatedProcedures.push(selectedItem);
        }
        var index = accountProcedures.findIndex(procedure => procedure.ProcedureId === selectedItem.ProcedureId);
        this.setState({ selectedProcedures: procedure, accountProcedures: accountProcedures, updatedProcedures: updatedProcedures });
    }

    handleSnackClose = () => {
        this.setState({
            openSnack: false,
        });
    };
    loadMonetizationData = () => {
        var self = this;
        self.setState({ isLoading: true });
        dataAccess.getAccountSettings().then(function (accountSettings) {
            var data = accountSettings.data;
            self.setState({ data: data, isLoading: false });
        }).catch(error => {
            console.log(error);
            self.setState({ Cost: 0, isLoading: false });
        });
    }

    componentWillMount() {
        this.loadMonetizationData();
        this.setAccountsProcedures();
        this.token = PubSub.subscribe('AccountChanged', this.loadMonetizationData.bind(this));
        this.token = PubSub.subscribe('AccountChanged', this.setAccountsProcedures.bind(this));
    }
    onChangeFunction(component, value) {
        this.setValue({ defaultValue: value });
    }
    setAccountsProcedures = () => {

        var self = this;
        self.setState({ displayFilter: false });
        proceduredataAccess.getAccountProcedures()
            .then(function (response) {

                var procedures = response.data.map((procedure, i) => {
                    const result = Object.assign({}, procedure,
                        {
                            Name: (procedure.Code === 'null' || procedure.Code === null) ? `${procedure.Description}` : `${procedure.Code} - ${procedure.Description}`
                        });
                    return result;
                });

                self.setState({ accountProcedures: procedures, displayFilter: true, selectedProcedures: procedures.filter(p => p.IsFiltered) });
            })
            .catch(error => {

                console.log(`Error: ${error}`);
            })

    }
    setCostPerCase() {
        var self = this;

        let accountSettings = {
            "Id": this.state.data.Id,
            "Account": auth.getActiveAccount(),
            "SettingName": "CostPerCase",
            "SettingValue": Math.ceil(this.state.data.SettingValue),
            "CreateDate": new Date(),
            "CreatedBy": auth.getUserId(),
            "LastUpdateDate": new Date(),
            "LastUpdatedBy": auth.getUserId(),
        }
        dataAccess.setAccountSettings(accountSettings)
            .then(function (results) {
                self.setState({ openSnack: true, snackMessage: 'Reimbursement saved' }, self.loadMonetizationData());

            })
            .catch(error => {
                self.setState({ openSnack: true, snackMessage: 'Unexpected Error Saving Reimbursement' });
                console.log(`Error: ${error}`);
            });
    }

    setProcedureFilter = () => {
        var self = this;
        var procedures = self.state.updatedProcedures;
        for (var i = 0; i < procedures.length; i++) {

            proceduredataAccess.setProcedureFilter(procedures[i])
                .then(function () {
                    console.log("Success");
                    self.setState({ openSnack: true, snackMessage: 'Procedure filters saved' });
                }).catch(error => {
                    console.log("Failed");
                });
        }
    }
    handleCostChange = event => {

        var data = this.state.data;
        data.SettingValue = event.target.value;
        this.setState({ data: data });
    };


    render() {
        const { classes } = this.props;
        const { openSnack, snackMessage, accountProcedures, displayFilter } = this.state;

        return (
            <div style={{ paddingBottom: 250 }}>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMessage}</span>}
                />
                <Typography variant="h5" gutterBottom>
                    Facility Settings
                                </Typography>
                <br />
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container spacing={4} direction="column" justify="center">

                            <Grid item>
                                <Typography variant="body1" gutterBottom>
                                    Case Reimbursement
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Card className="test">
                                    <CardContent>
                                        <TextField value={this.state.data.SettingValue} onChange={this.handleCostChange} helperText={this.state.IsLoading ? <CircularProgress /> : `Enter the blended average reimbursement rate per case in dollars`} label="Blended average reimbursement rate"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            type="number"
                                            variant="filled" />
                                    </CardContent>
                                    <CardActions>
                                        {
                                            <Button disabled={!(auth.isInRole('FacilityAdmin') || auth.isInRole('Admin'))} variant="contained" color="primary" size="small" onClick={() => this.setCostPerCase()}>Update</Button>
                                        }
                                    </CardActions>

                                </Card>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" gutterBottom>
                                    Procedure Filters
                    </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" gutterBottom>
                                </Typography>

                                <Card>
                                    <CardContent>
                                        <Grid container direction="column" spacing={2}>
                                            {this.state.selectedProcedures && this.state.selectedProcedures.map((p, i) => {
                                                return (
                                                    <Tooltip title={p.Name} placement="right-start" key={i}>
                                                        <Grid key={i} item xs={2}>
                                                            <Chip
                                                                key={i}
                                                                label={p.Name.length > 100 ? `${p.Name.substring(0, 99)}...` : p.Name}
                                                                onDelete={this.handleDelete(p)}
                                                                className={classes.chip}
                                                            />
                                                        </Grid>
                                                    </Tooltip>
                                                );
                                            }, this)}

                                        </Grid>
                                        {!displayFilter && <CircularProgress />}
                                        {displayFilter && <IntegrationAutosuggest suggestions={accountProcedures} placeholder={'Exclude Procedure'} onSuggestionSelected={this.onSuggestionSelected} clearAfterSelect={true} />}
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </CardContent>
                                    <CardActions>
                                        {
                                            <Button disabled={!(auth.isInRole('FacilityAdmin') || auth.isInRole('Admin'))} variant="contained" color="primary" size="small" onClick={() => this.setProcedureFilter()}>Update</Button>
                                        }
                                    </CardActions>

                                </Card>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(Settings);
