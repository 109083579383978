import axios from 'axios';
import Auth from '../services/auth';
import moment from 'moment';
import { API_ROOT } from './apiConfig';

export default class UserDataAccess {

    getAccounts() {

        const auth = new Auth();

        return auth.getUserAccounts();
    }

    getAllWorklistUsers(account) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetWorkListUsers", parameters: { account: account }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    console.log(response);

                    const users = response.data.map((user, i) => {
                        const result = Object.assign({}, user,
                            {
                                UserId: user.UserId,
                                FirstName: user.Firstname,
                                LastName: user.LastName,
                                Organization: user.Organization
                                
                            });
                        return result;
                    });

                    resolve(users);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getAllTeamStatus() {

        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetAllTeamStatus", parameters: { }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    console.log(response);

                    const allStatus = response.data.map((status, i) => {
                        const result = Object.assign({}, status,
                            {
                                FacilityId: status.FacilityId,
                                Status: status.Status,
                                AccVst: status.AccVst,
                                CreatedBy: status.CreatedBy,
                                Created: status.Created

                            });
                        return result;
                    });

                    resolve(allStatus);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getTranscriptionDetails(account, caseId)
    {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetTranscriptionDocument", parameters: { account: account, caseId: caseId }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    //console.log(response);

                    const details = response.data.map((details, i) => {
                        const result = Object.assign({}, details,
                            {
                                Id: details.Id,
                                JobId: details.JobId,
                                ProcedureDescription: details.ProcedureDescription,
                                Account: details.Account,
                                WorkType: details.WorkType,
                                WorkTypeDescription: details.WorkTypeDescription,
                                DictEnd: details.DictEnd
                            });
                        return result;
                    });

                    resolve(details);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getUserDetails(UserId) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetWorkListUserDetail", parameters: { UserId: UserId }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    //console.log(response);

                    const details = response.data.map((details, i) => {
                        const result = Object.assign({}, details,
                            {
                                UserId: details.UserId,
                                FirstName: details.Firstname,
                                LastName: details.LastName,
                                Organization: details.Organization
                            });
                        return result;
                    });

                    resolve(details);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getStatusDetails(FacilityId,AccVst) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetTeamStatus", parameters: { FacilityId: FacilityId, AccVst: AccVst}
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    //console.log(response);

                    const details = response.data.map((details, i) => {
                        const result = Object.assign({}, details,
                            {
                                UserId: details.UserId,
                                FirstName: details.Firstname,
                                LastName: details.LastName,
                                Organization: details.Organization
                            });
                        return result;
                    });

                    resolve(details);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getCodingDetails(account, caseId) {
        const auth = new Auth();

        console.log('getCodingDetails', account, caseId);
        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetCodingDetail", parameters: { account: account, caseId: caseId }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    if (response.data != null && response.data.length > 0) {


                        var jsonDetail = JSON.parse(response.data[0].JsonData).CodingJobDetail;
                       
                        const details = jsonDetail.map((detail, i) => {
                           const result = Object.assign({}, detail,
                                {
                                    Comments: typeof detail.Comments == 'undefined' ? {} : detail.Comments,
                                    CptCodes: typeof detail.CptCodes == 'undefined' ? {} : detail.CptCodes,
                                    Icd10Codes: typeof detail.Icd10Codes == 'undefined' ? {} :detail.Icd10Codes
                                });
                            return result;
                        });


                        resolve(details[0]);
                    }
                    else {
                        resolve(false);
                    }

                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    writeAudit(auditEvent) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {


            axios.post(`${API_ROOT}/api/v1/Users/WriteAudit`,
                auditEvent,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                  
                    resolve(true);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getAvailableDictators(accounts) {



        const auth = new Auth();

        return new Promise((resolve, reject) => {

            axios.post(`${API_ROOT}/api/v1/Users/AvailableDictators?isFacilityAdmin=${auth.isInRole('FacilityAdmin')}`,
                accounts,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    const dictators = response.data.map((dictator, i) => {
                        const result = Object.assign({}, dictator,
                            {
                                Name: `${dictator.Physician} - ${dictator.Account}-${dictator.DictatorId}`,
                                //DateOfService: moment(report.DateOfService).format('MM/DD/YYYY'),

                            });

                        return result;
                    });

                    resolve(dictators);


                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    saveUser(user) {
        
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            var serviceUrl = `${API_ROOT}/api/v1/Users/Create`;
            if (user.Id > 0) {
                serviceUrl = `${API_ROOT}/api/v1/Users/Update`;
            }

            axios.post(serviceUrl,
                user,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    //  console.log('inside response');
                    resolve(response.data);

                })
                .catch(error => {

                    //console.log(error.response);
                    reject(error);
                });
        });
    }

    getAccountByUsername(username) {

        return new Promise((resolve, reject) => {


            axios.get(`${API_ROOT}/api/v1/Users/GetAccountByUsername?username=${username}`).then(function (response) {

                    resolve(response.data);

                })
                .catch(error => {
                    reject(error);
                });
        });
    }


    saveSSOIdentity(account,userId,ssoIdentity) {

        const auth = new Auth();


        return new Promise((resolve, reject) => {


            axios.get(`${API_ROOT}/api/v1/Users/SaveSSOIdentity?account=${account}&userId=${userId}&ssoIdentity=${ssoIdentity}`).then(function (response) {

                    resolve(response.data);

                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    getUserById(account, userId) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {


            axios.get(`${API_ROOT}/api/v1/users/getuser?account=${account}&id=${userId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                   // console.log(response);
                    // const users = response.data.map((user, i) => {
                    const result = Object.assign({}, response.data,
                        {
                            id: response.data.Id
                            //DateOfService: moment(report.DateOfService).format('MM/DD/YYYY'),

                        });

                    // return result;
                    // });

                    resolve(result);


                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    changePassword(changeRequest) {

        const auth = new Auth();

        return new Promise((resolve, reject) => {


            axios.post(`${API_ROOT}/api/v1/Users/ChangePassword`,
                changeRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    if (response) {
                        auth.clearPasswordExpExpiresIn();
                    }

                    resolve(response);
                    
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    getRegistration(code) {

        return new Promise((resolve, reject) => {


            axios.get(`${API_ROOT}/api/v1/Users/ValidateResetPasswordRequest?request=${code}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }).then(function (response) {

                    resolve(response.data);


                })
                .catch(error => {
                    console.log(error)
                });
        });
    }

    SendIdentityEmailConfirmation(account, luid) {

        const auth = new Auth();


        return new Promise((resolve, reject) => {


            axios.get(`${API_ROOT}/api/v1/Users/SendIdentityEmailConfirmation?account=${account}&luid=${luid}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    resolve(response.data);

                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    resetConfirmation(account, luid) {

        const auth = new Auth();


        return new Promise((resolve, reject) => {


            axios.get(`${API_ROOT}/api/v1/Users/ResetIdentityMode?account=${account}&luid=${luid}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {

                    resolve(response.data);

                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    resetPassword(request) {

        return new Promise((resolve, reject) => {


            axios.post(`${API_ROOT}/api/v1/Users/ResetPassword`,
                request,
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }).then(function (response) {

                    resolve(response.data);
                    
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    requestPasswordReset(email) {

        return new Promise((resolve, reject) => {

            let request = { Email: email };
            axios.post(`${API_ROOT}/api/v1/Users/RequestPasswordReset`,
                request,
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }).then(function (response) {

                    resolve(true);

                })
                .catch(error => {
                    resolve(true);
                });
        });
    }


    confirmIdentity(userRequest) {
        const auth = new Auth();
        return new Promise((resolve, reject) => {

            axios.post(`${API_ROOT}/api/v1/Users/ConfirmIdentity`,
                userRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    //  console.log('inside response');
                    resolve(response.data);

                })
                .catch(error => {

                    //console.log(error.response);
                    reject(error);
                });
        });
    }

    confirmUserName(userRequest) {
        const auth = new Auth();
        return new Promise((resolve, reject) => {

            axios.post(`${API_ROOT}/api/v1/Users/UpdateIdentity`,
                userRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    //  console.log('inside response');
                    resolve(response.data);

                })
                .catch(error => {

                    //console.log(error.response);
                    reject(error);
                });
        });
    }

    confirmEmail(email, code) {

        return new Promise((resolve, reject) => {

            resolve(true);
        });
    }
}