import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Auth from '../services/auth';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom'
import FormHelperText from '@material-ui/core/FormHelperText';
import UserDataAccess from '../services/userDataAccess';
import CircularProgress from '@material-ui/core/CircularProgress';
const userDataAccess = new UserDataAccess();

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
        minHeight: 300
    }),
    logo: {
        maxWidth: '100%',
        margin: 'auto'
    },
    center: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    },
       SNBrand: {
        color: theme.palette.secondary.main
    },
    ChartBrand: {
        color: theme.palette.primary.main
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});



class ForgotPassword extends React.Component {


    constructor(props) {
        super(props);
        this.requestPasswordReset = this.requestPasswordReset.bind(this);
        this.Auth = new Auth();

        this.state = {
            email: '',
            hasError: false,
            complete: false,
            loginErrorMessage: 'Invalid email.',
            sending: false
        };
    }

  
    submit = (event) => {
        event.preventDefault();
        this.requestPasswordReset();
    };

    render() {
        const { classes } = this.props;
        const { sending } = this.state;
        return <div>
            <Grid container spacing={6} justify="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.root} elevation={4}>

                        {!this.state.complete && <Grid container spacing={3}>
                      
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item>
                                        <Typography variant="h5" gutterBottom>
                                            Forgot your password?
                                        </Typography>
                                        <Typography>
                                            Enter the email address associated with your <span className={classes.SNBrand}>SN</span><span className={classes.ChartBrand}>Chart</span> account to get instructions to reset your password.<br /><br />
                                            If you are a physician user, please call <br /> <a href="tel:800-459-5616">800-459-5616</a> or email <a href="mailto:acctmgrs@surgicalnotes.com?subject=SNChart Customer Request/Issue">acctmgrs@surgicalnotes.com</a> for support.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <form onSubmit={this.submit} id="resetRequest">
                                    <TextField fullWidth
                                        name="txtUserName"
                                        id="txtUserName"
                                        label="Email"
                                        type="email"
                                        onChange={this.handleChange('email')}
                                        error={this.state.hasError}
                                        required

                                    />                      {this.state.hasError && <FormHelperText id="name-error-text" error>{this.state.loginErrorMessage}</FormHelperText>}
                                </form>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item>
                                        <div className={classes.wrapper}>
                                            <Button disabled={sending} type="submit" onClick={this.submit}  variant="contained" form="resetRequest">Send Email</Button>
                                            {sending && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </div>

                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </Grid>}
                        {this.state.complete && <Grid item xs={12}>
                            <Grid container spacing={3} justify="center">
                                <Grid item>
                                    <Typography variant="h5" gutterBottom>
                                       Forgot password confirmation
                                    </Typography>
                                    <Typography>
                                        <br/><br/>
                                        Please check your email to reset your password. If you do not receive this email within 30 minutes, please check your spam or bulk email folder. 
                                        <br /><br />If you are still having difficulties receiving email and resetting your password, please contact support at <a href="tel:8004595616">(800) 459-5616</a> for further assistance.
                                    </Typography>
                                </Grid>
                            </Grid><Grid item xs={12}>
                                <br /><br /><br /><br /><br /><br />
                                </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item>
                                        <Link to='/login' className={classes.link}>
                                            <Typography variant="body2" gutterBottom>
                                                Return to login
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Paper>
                </Grid>
            </Grid>
            <br/>
        </div>;
    }

    handleChange = field => event => {

        const { target: { value } } = event;
        this.setState({ [field]: value });
    }

    requestPasswordReset() {

        var self = this;
        self.setState({ sending: true });
       userDataAccess.requestPasswordReset(this.state.email)
            .then(function (response) {
                self.setState({ complete: true, sending: false});
            }).catch(error => {
                self.setState({ complete: true, sending: false });
            });
    }

}

export default withStyles(styles)(ForgotPassword);