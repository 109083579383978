import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Auth from '../services/auth';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Notifications from '@material-ui/icons/Notifications';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import PubSub from 'pubsub-js';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import { withRouter } from "react-router-dom";
import compose from 'recompose/compose';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import ReactGA from 'react-ga4';
import Alert from './Alert';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HelpIcon from '@material-ui/icons/Help';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ScoreIcon from '@material-ui/icons/Score';
import ViewListOutlined from '@material-ui/icons/ViewList';
import DescriptionIcon from '@material-ui/icons/Description';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GrainIcon from '@material-ui/icons/Grain';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Color } from 'ag-grid-community';
import FacilityDataAccess from '../services/facilityDataAccess';
import WorkListDataAccess from '../services/workListDataAccess';
import BackupIcon from '@material-ui/icons/Backup';

const facilityDataAccess = new FacilityDataAccess();
const workListDataAccess = new WorkListDataAccess();

const drawerWidth = 240;
const auth = new Auth();

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        width: 'calc(100% - 71px)'
    },
    splashContent: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        width: 'calc(100% - 71px)'
    },
    flex: {
        flex: 1,
    }, selectEmpty: {
        maxWidth: '500px',
        marginTop: theme.spacing(2),
        fontSize: '1.3125rem',
        color: '#fff',
        '&::before': {
            borderBottom: '1px solid #fff !important'
        },
        '&::focus': {
            borderBottom: '1px solid #fff !important'
        },
        '& option': {
            color: '#000'
        }
    },
    selectIcon: {
        color: '#fff'
    }, badgeMargin: {
        margin: theme.spacing(1),
    },
    accountWrapper: {
        flex: 1,
        marginTop: (theme.spacing(2)) * -1
    },
    SNBrand: {
        color: theme.palette.secondary.main
    },
    input: {
        color: '#fff',
        background: 'rgba(255,255,255,0.15)',
        padding: theme.spacing(1),
    },
    formControlClients: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    selectInput: {
        color: '#fff',
        marginLeft: 10,
        top: "50%",
        transform: "translate(0,-50%)"
    },
    selectInputLabel: {
        color: '#fff',
        marginLeft: 10,
        marginTop: 1,
        top: "50%",
        transform: "translate(0,-50%)",
        position:"Absolute",
    },
    dropdown: {
        "& .MuiOutlinedInput-input": {
            color: "#fff"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#fff"
        }
    },
    gutters: theme.mixins.gutters()
});

class SingleAccount extends React.Component {

    render() {
        const accountName = this.props.accountName;
        return <Typography variant="h6" color="inherit" style={{ marginTop: 16 }} noWrap>{accountName}</Typography>;
    }
}

class MultiAccount extends React.Component {


    handleChange = (event) => {

        this.props.onAccountChange(event);
    }

    render() {
        const accounts = this.props.userAccounts;
        const classes = this.props.classes;

        return (<NativeSelect name="account" defaultValue={auth.getActiveAccount()} onChange={this.handleChange} className={classes.selectEmpty} classes={{
            icon: classes.selectIcon
        }}
        >
            {
                accounts.map(function (a) {
                    return <option key={a.Account}
                        value={a.Account}>{a.Facility}</option>;
                })
            }
        </NativeSelect>);
    }
}

class AccountSelector extends React.Component {

    render() {
        const canSwitch = this.props.canSwitch;
        const accountName = this.props.accountName;
        const userAccounts = this.props.userAccounts;

        if (canSwitch === "true") {
            return <MultiAccount userAccounts={userAccounts} onAccountChange={this.props.onAccountChange} classes={this.props.classes} />;
        }
        return <SingleAccount accountName={accountName} />;
    }
}

class NotificationBadge extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            //anchorNotificiationEl: null,
            menuOpenNotification: false,
            menuOpen: false,
            notifications: []
        };

        var self = this;
        auth.getNotifications().then(function (data) {
            self.setState({ notifications: data });
        });
    }
    handleNotificationClose = () => {

        var notifications = this.state.notifications;

        notifications = auth.markNotificationsRead(notifications);
        this.setState({ anchorEl: {}, menuOpenNotification: false, notifications: notifications });


    };


    handleNotificationMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget, menuOpenNotification: true });
    };

    render() {

        const { notifications } = this.state;
        const notificationCount = notifications ? notifications.length : 0;
        let unReadCount = 0;

        for (var i = 0; i < notifications.length; i++) {
            if (notifications[i].IsRead !== true) {
                unReadCount++;
            }
        }



        return <div>{notificationCount === 0 && (
            <IconButton disabled
                color="inherit">

                <Notifications />

            </IconButton>)}
            {notificationCount > 0 && (
                <div><IconButton aria-owns={this.state.openNotification ? 'menu-notification' : null}
                    aria-haspopup="true"
                    onClick={this.handleNotificationMenu}
                    color="inherit" style={{ overflow: 'visible' }}>
                    {unReadCount > 0 && <Badge badgeContent={unReadCount} color="secondary">
                        <Notifications />  </Badge>}
                    {unReadCount === 0 &&
                        <Notifications />}

                </IconButton>
                    <Menu id="menu-notification"
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.menuOpenNotification}
                        onClose={this.handleNotificationClose}

                    >
                        {notifications.map(msg => {
                            return (
                                <MenuItem key={msg} href={msg.Url} target='_blank' name={msg.NotificationId} onClick={e => (ReactGA.event({
                                    action: 'NotificationClick',
                                    category: `${auth.getGaUser()}_${msg.NotificationId}`
                                }))} component='a' style={{ whiteSpace: 'normal', height: 'auto', width: 300 }}>
                                    {msg.Title}
                                </MenuItem>
                            )
                        })}
                    </Menu></div>)}
        </div>

    }
}

class GlobalDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            anchorEl: null,
            menuOpen: false,
            search: '',
            openSnack: false,
            canShowPwdAlert: true,
            showMyItems: auth.getShowMyItems() ? auth.getShowMyItems() === true : true,
            clients: [],
            clientName: [],
            clientList: [],
            isAllSelected: false,
            clientsLabel: true,
        };

        this.clientLabelObj = React.createRef();
        var selectedClients = [];
        

        if (auth.getShowMyItems() === true) {
            selectedClients = [];
            workListDataAccess.setSelectedClients(selectedClients);
        }

        this.getClientsOnRefresh();

    }

    componentWillMount() {
        this.token = PubSub.subscribe('AppUpdated', this.appUpdated.bind(this));
        this.token = PubSub.subscribe('getClients', this.getClients.bind(this));
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    appUpdated(msg, data) {
        this.setState({ openSnack: true });
    }

    getClients(msg, data) {
        let self = this;
        facilityDataAccess.getClientsByGroupIds().then(function (rs) {
            self.setState({ clients: rs });
            self.setClientList();
        });
    }

    setClientList() {
        let self = this;
        let clients1 = [];

        for (let i = 0; i < this.state.clients.length; i++) {
            clients1.push(this.state.clients[i].client);
        }

        this.setState({clientList: clients1});
                
    }

    getClientsOnRefresh() {
        let self = this;
        facilityDataAccess.getClientsByGroupIds().then(function (rs) {
            self.setState({ clients: rs });
            self.setClientList();
        });
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    handleClose = () => {

        this.setState({ anchorEl: {}, menuOpen: false });
    };

    handleLogOut = () => {

        const auth = new Auth();
        auth.signOut();
    }

    handleChangePassword = () => {

        this.props.history.push('/changePassword');
    }

    handleMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget, menuOpen: true });
    };

    handleAccountChange = (event) => {

        const auth = new Auth();
        auth.setActiveAccount(event.target.value);
        PubSub.publish('AccountChanged', event.target.value);
        //ReactGA.set({ dimension1: auth.getActiveAccount() });
    };

    handleChangeMultiple = (event) => {
        let self = this;
        var selectedClients = [];
        const clientLabelObj = this.clientLabelObj.current;
        

        const {
            target: { value },
        } = event;

        if (value[value.length - 1] === "all") {
            selectedClients = self.state.clientName.length === self.state.clientList.length ? [] : self.state.clientList;
        }
        else {
            selectedClients = typeof value === 'string' ? value.split(',') : value;
        }

        if(self.state.clientName.length === self.state.clientList.length)
        {
            self.setState({ isAllSelected: false });
        } else {
            self.setState({ isAllSelected: true });
        }

        this.setState({ clientName: selectedClients });

        workListDataAccess.setSelectedClients(selectedClients);

        if (selectedClients.length > 0) {
            this.setState({ clientsLabel: false });
        } else {
            this.setState({ clientsLabel: true });
        }

   
        //console.log('selectedClients: ' + selectedClients);

        PubSub.publish('AccountChanged', event.target.value);
    };

    search = (event) => {
        event.preventDefault();

        if (this.state.search && this.state.search !== '') {
            this.props.history.push(`/worklist/search/${this.state.search}`);
        }
    };

    handleChange = (event) => {

        const { target: { name, value } } = event;
        this.setState({ [name]: value });
    }

    handleSnackClose = () => {
        this.setState({ openSnack: false });
    }

    handleAlertClose = () => {

        this.setState({ canShowPwdAlert: false });
    }

    handleAlertOk = () => {

        this.setState({ canShowPwdAlert: false });
        this.props.history.push(`/changePassword`);
    }

    handleShowMyItems = (event) => {
        
        let self = this;
        var selectedClients = []; 
        var showMyItemsLocal = self.state.showMyItems;

        //Udpate showMyItems variable in session storage:
        auth.setShowMyItems(!showMyItemsLocal);

        self.setState({ showMyItems: !showMyItemsLocal });

        PubSub.publish('AccountChanged', event.target.value);

    }

    render() {
        const { classes, theme } = this.props;
        const auth = new Auth();
        const userName = auth.getUserName();
        const centerName = auth.getCenterName();
        const clientName = this.state.clientName;

        const canSwitchAccounts = auth.canSwitchAccounts();
        const userAccounts = auth.getUserAccounts();
        const isAuthenticated = auth.isAuthenticated() && auth.isValidLogin();
        const passwordExpiresIn = auth.getPasswordExpiresIn();
        const ssoIdentityColumn = auth.getSSOIdentityColumn();
        const showPasswordAlert = this.state.canShowPwdAlert && isAuthenticated && (passwordExpiresIn <= 7 && !auth.isPasswordExpired());
        
        return (
            <div className={classes.root}>
                <Alert showAlert={showPasswordAlert}
                    alertText={`Your password will expire in ${passwordExpiresIn} day(s).`}
                    alertTitle={'Your password is about to expire'}
                    alertOkText={'Change Password'}
                    alertCancelText={'Not now'}
                    onAlertCancel={this.handleAlertClose}
                    onAlertOk={this.handleAlertOk}
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={this.state.openSnack}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">A new version of the app is available</span>}
                    action={<Button color="secondary" onClick={(e) => { window.location.reload(true) }} className={classes.snackButton}>Refresh</Button>}

                />
                <AppBar
                    position="absolute"
                    className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                >
                    <Toolbar disableGutters={!this.state.open && isAuthenticated}>
                        {isAuthenticated && <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(classes.menuButton, this.state.open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>}
                        <Typography variant="h6" color="inherit" noWrap>
                            <span className={classes.SNBrand}>RCM</span>brain
                        </Typography>
                        {isAuthenticated && (<span className={classes.accountWrapper}>
                        </span>)}

                        {isAuthenticated && (

                            <div>

                                <Grid container spacing={2} alignItems="flex-end">
                                    <Hidden xsDown={true}>
                                        <Grid item style={{ display: 'none' }}>
                                            <form onSubmit={this.search}>
                                                <TextField

                                                    name="search"
                                                    id="search"
                                                    placeholder="Go to any visit..."
                                                    autoComplete="off"
                                                    type="search"

                                                    onChange={this.handleChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                                        disableUnderline: true,
                                                        className: classes.input,

                                                    }}
                                                />
                                            </form>
                                        </Grid>
                                    </Hidden>
                                    <Grid item>
                                        <FormControl className={classes.formControlClients} size="small">
                                            {this.state.clientsLabel && (
                                                <div ref={this.clientLabelObj}>
                                                    <label className={classes.selectInputLabel}>Clients</label>
                                                    </div>
                                                )}
                                            
                                            <Select
                                                labelId="clientsSelectInputLabel"
                                                id="clientsSelect"
                                                multiple
                                                input={<OutlinedInput label="Tag" />}
                                                value={clientName}
                                                onChange={this.handleChangeMultiple}
                                                renderValue={(selected) => selected.join(', ')}
                                                className={classes.dropdown}
                                            >
                                                <MenuItem
                                                    value="all"
                                                    classes={{
                                                        root: this.state.isAllSelected ? classes.selectedAll : ""
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            classes={{ indeterminate: classes.indeterminateColor }}
                                                            checked={this.state.isAllSelected}
                                                            indeterminate={
                                                                this.state.clientName.length > 0 && this.state.clientName.length < this.state.clientList.length
                                                            }
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        classes={{ primary: classes.selectAllText }}
                                                        primary="Select All"
                                                    />
                                                </MenuItem>
                                                {this.state.clients.map((client) => (
                                                    <MenuItem key={client.client} value={client.client}>
                                                        <Checkbox checked={clientName.indexOf(client.client) > -1} />
                                                        <ListItemText primary={client.client}/>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ paddingBottom: 16 }}>
                                        <FormControlLabel
                                            label="Show My Items"
                                            value="Show My Items"
                                            labelPlacement="start"
                                            control=
                                            {
                                                <Switch
                                                    checked={this.state.showMyItems}
                                                    onChange={this.handleShowMyItems}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item style={{ display: 'none' }}>
                                        <NotificationBadge />
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            aria-owns={this.state.open ? 'menu-appbar' : null}
                                            aria-haspopup="true"
                                            onClick={this.handleMenu}
                                            color="inherit"
                                        >

                                            <AccountCircle />
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={this.state.anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={this.state.menuOpen}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem>{userName}</MenuItem>
                                            { ssoIdentityColumn === '' && <MenuItem onClick={this.handleChangePassword}>Change Password</MenuItem>}
                                            <MenuItem onClick={this.handleLogOut}>Log out</MenuItem>

                                        </Menu>
                                    </Grid>
                                </Grid>


                            </div>
                        )}
                    </Toolbar>
                </AppBar>
                {isAuthenticated && <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                    }}
                    open={this.state.open}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={this.handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <div>
                            <Tooltip title="Dashboard" placement="right" disableFocusListener>
                                <ListItem button component={Link} to='/' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <DashboardIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Dashboard" />
                                </ListItem>
                            </Tooltip>
                            <Tooltip title="Worklist" placement="right" disableFocusListener>
                                <ListItem button component={Link} to='/Worklist' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <ListAltIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Worklist" />
                                </ListItem>
                            </Tooltip>
                            <Tooltip title="User Management" placement="right" disableFocusListener={true} style={{ display: 'none' }}>
                                <ListItem button component={Link} to='/users' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <PeopleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="User Management" />
                                </ListItem>
                            </Tooltip>
                            <Tooltip title="Team Status" placement="right" disableFocusListener={true} style={{ display: 'none' }} >
                                <ListItem button component={Link} to='/TeamStatus' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <GrainIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Team Status" />
                                </ListItem>
                            </Tooltip>

                            <Tooltip title="Last Import Date" placement="right" disableFocusListener={true}>
                                <ListItem button component={Link} to='/LastImportDate' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <GrainIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Last Import Date" />
                                </ListItem>
                            </Tooltip>

                            <Tooltip title="Priority & Delinquent Dashboard" placement="right" disableFocusListener={true}>
                                <ListItem button component={Link} to='/PriorityDelinquentDashboard' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <ScoreIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Priority & Delinquent Dashboard" />
                                </ListItem>
                            </Tooltip>

                            <Tooltip title="Productivity Dashboard" placement="right" disableFocusListener={true}>
                                <ListItem button component={Link} to='/ProductivityDashboard' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <ScoreIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Productivity Dashboard" />
                                </ListItem>
                            </Tooltip>

                            <Tooltip title="WorkFlow Management" placement="right" disableFocusListener={true} style={{ display: auth.isInADDGroup() || auth.isInEOMWizardGroup() ? '':'none' }}  >
                                <ListItem button component={Link} to='/WorkFlowManagement' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <AccountTreeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="WorkFlow Management" />
                                </ListItem>
                            </Tooltip>

                            <Tooltip title="EOM Wizard" placement="right" disableFocusListener={true} style={{ display: auth.isInADDGroup() || auth.isInEOMWizardGroup() ? '' : 'none' }}>
                                <ListItem button component={Link} to='/EomWizard' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <BackupIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="EOM Wizard" />
                                </ListItem>
                            </Tooltip>

                            <Tooltip title="EOM Wizard Historical" placement="right" disableFocusListener={true} style={{ display: auth.isInEOMWizardHistoricalGroup() ? '' : 'none' }}>
                                <ListItem button component={Link} to='/EomWizardBK' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <BackupIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="EOM Wizard Backup" />
                                </ListItem>
                            </Tooltip>

                            <Tooltip title="Help" placement="right" disableFocusListener={true}>
                                <ListItem button component={Link} to='/help' classes={{ gutters: classNames(classes.gutters) }}>
                                    <ListItemIcon>
                                        <HelpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Help" />
                                </ListItem>
                            </Tooltip>
                            
                            {/*<Tooltip title="Archive Request" placement="right" disableFocusListener={true}>*/}
                            {/*    <ListItem button component="a" target="_blank" href="https://www.surgicalnotes.com/solutions/transcription/document-retrieval/" classes={{ gutters: classNames(classes.gutters) }}>*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <DescriptionIcon />*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Archive Request" />*/}
                            {/*    </ListItem>*/}
                            {/*</Tooltip>*/}

                            

                            {/*<Tooltip title="Facility Settings" placement="right" disableFocusListener={true}>*/}
                            {/*    <ListItem button component={Link} to='/settings' classes={{ gutters: classNames(classes.gutters) }}>*/}
                            {/*    <ListItemIcon>*/}
                            {/*        <SettingsIcon />*/}
                            {/*    </ListItemIcon>*/}
                            {/*        <ListItemText primary="Facility Settings" />*/}
                            {/*</ListItem>*/}
                            {/*</Tooltip>*/}

                        </div>
                    </List>
                </Drawer>}
                <main className={(isAuthenticated && classes.content) || (!isAuthenticated && classes.splashContent)}>
                    <div className={classes.toolbar} />
                    {this.props.children}
                </main>
            </div>
        );
    }
}

GlobalDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles, { withTheme: true }))(GlobalDrawer);